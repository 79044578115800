import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";

function Timer({ sx, variant = "h4" }) {
  const [currentTime, setCurrentTime] = useState(new Date());

  const tick = () => {
    setCurrentTime(new Date());
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <MDTypography variant={variant} fontWeight="medium" textTransform="capitalize" sx={sx}>
       {formatTime(currentTime)}
    </MDTypography>
  );
}

export default Timer;
