import { GET_SEARCH_MACHINE, ADD_SEARCH_MACHINE, DELETE_SEARCH_MACHINE } from "../constants";

const initialState = {
  machineSearch: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_MACHINE:
      return {
        ...state,
        machineSearch: state.machineSearch
      };
    case ADD_SEARCH_MACHINE:
      return {
        ...state,
        machineSearch:
          state.machineSearch.length > 5
            ? [
                ...state.machineSearch
                  ?.slice(state.machineSearch?.length - 5, state?.machineSearch?.length)
                  ?.filter((item) => item?.machine_list !== action.payload.search.machine_list),
                action.payload.search
              ]
            : [
                ...state.machineSearch?.filter(
                  (item) => item?.machine_list !== action.payload.search.machine_list
                ),
                action.payload.search
              ]
      };
    case DELETE_SEARCH_MACHINE:
      return {
        ...state,
        machineSearch: []
      };
    default:
      return state;
  }
};
