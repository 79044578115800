import { combineReducers } from "redux";
import versus from "./versus";
import machine from "./machinePage";
import reports from "./reports";

export default combineReducers({
  versus,
  machine,
  reports
});
