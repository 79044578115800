/* eslint-disable react/jsx-no-useless-fragment */
import { Icon, Tooltip, Chip } from "@mui/material";
import MDTypography from "components/MDTypography";

/* eslint-disable react/prop-types */
function RatingPercentage({
  overallRating,
  title = "",
  currentRating,
  direction = true,
  display = "block"
}) {
  if (!overallRating || !currentRating) return <></>;
  const up = currentRating > overallRating;
  const percentageDifference = (currentRating * 100) / overallRating - 100;

  const getColor = () => {
    if (direction) {
      if (up) {
        if (percentageDifference <= 10) return "linear-gradient(195deg,#A0D468, #7CB342)";
        if (percentageDifference <= 50) return "linear-gradient(195deg,#8BC34A, #689F38)";
        return "linear-gradient(195deg,#459548, #375D38)";
      }
      if (percentageDifference >= -10) return "linear-gradient(195deg,#F5A623, #FF9800)";
      if (percentageDifference >= -50) return "linear-gradient(195deg,#F44336, #D32F2F)";
      return "linear-gradient(195deg,#CF3F32, #68312D)";
    }
    if (up) {
      if (percentageDifference <= 10) return "linear-gradient(195deg,#F5A623, #FF9800)";
      if (percentageDifference <= 50) return "linear-gradient(195deg,#F44336, #D32F2F)";
      return "linear-gradient(195deg,#CF3F32, #68312D)";
    }
    if (percentageDifference >= -10) return "linear-gradient(195deg,#A0D468, #7CB342)";
    if (percentageDifference >= -50) return "linear-gradient(195deg,#8BC34A, #689F38)";
    return "linear-gradient(195deg,#459548, #375D38)";
  };

  return (
    <MDTypography
      variant="caption"
      color="text"
      fontWeight="regular"
      sx={{ display, justifyContent: "center", alignItems: "center" }}
    >
      <Tooltip title={title} placement="top" arrow>
        <Chip
          label={
            <>
              <MDTypography display="inline" variant="caption" verticalAlign="middle">
                {up ? (
                  <Icon
                    sx={{
                      color: "#FFF"
                    }}
                  >
                    arrow_upward
                  </Icon>
                ) : (
                  <Icon
                    sx={{
                      color: "#FFF"
                    }}
                  >
                    arrow_downward
                  </Icon>
                )}
              </MDTypography>
              <MDTypography variant="caption" color="#FFF" fontWeight="medium" ml="3px">
                {Math.abs(percentageDifference)?.toFixed(2)}%
              </MDTypography>
            </>
          }
          sx={{
            background: getColor(),
            color: "white !important",
            borderRadius: "0.375rem",
            padding: "1px",
            cursor: "default"
          }}
        />
      </Tooltip>
    </MDTypography>
  );
}

export default RatingPercentage;
