/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import axios from "api/axios";
import useAuth from "hooks/useAuth";
import Cookies from "js-cookie";
import React from "react";
import { useNavigate } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import translate from "i18n/translate";
import MDButton from "components/MDButton";

function GitHubOAuth({ startIcon }) {
  const isSignUp = !!localStorage.getItem('license');
  const authPath =
    localStorage.getItem("license") === "" ? "v2/oauth/github_login" : "v2/oauth/github_register";
  const githubOAuthURL = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_KEY}&scope=user`;
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const handleLogin = async (code) => {
    const data = await axios.post(
      authPath,
      localStorage.getItem("license") === ""
        ? { code }
        : { code, license: localStorage.getItem("license") }
    );
    if (data?.redirect === "2fa") {
      setAuth({ user: data?.user?.email, password: data?.user?.email });
      Cookies.set("username", data?.user?.email);
      navigate(`/verifyOTP`, { replace: true });
      return;
    }
    const refreshToken = data?.refresh_token;
    const Token = data?.token;
    const roles = data?.user?.role;
    const UserCompanyId = data?.user?.company?.id;
    const Id = data?.user?.id;
    const role = data?.user?.role?.name;
    const setting = data?.user?.setting;
    const name = data?.user?.name;
    setAuth({ user: data?.user?.email, password: data?.user?.email, refreshToken, Token, roles });
    Cookies.set("tok", refreshToken, { expires: 15 });
    Cookies.set("id", Id);
    Cookies.set("companyid", UserCompanyId);
    Cookies.set("role", role);
    Cookies.set("setting", setting);
    Cookies.set("name", name);
    localStorage.clear("license");
    navigate(`dashboard/machines`, { replace: true });
  };

  const handleGitHubCallback = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    if (code) {
      handleLogin(code);
    }
  };

  React.useEffect(() => {
    handleGitHubCallback();
  }, []);

  return (
    <div>
      <MDButton
        onClick={() => window.location.href = githubOAuthURL} // Trigger the redirect
        fullWidth
        variant="gradient"
        color="info"
        startIcon={<GitHubIcon />}  // Icon passed here
      >
        {translate(isSignUp ? "signUpWith" : "signInWith")} GITHUB
      </MDButton>
    </div>
  );
}

export default GitHubOAuth;
