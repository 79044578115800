/* eslint-disable import/prefer-default-export */
export const machineState = {
  STOPPED: 0,
  IDLE: 1,
  PRODUCTION: 2,
  PREPARATION: 3,
  UNKNOWN: 4
};

export const timelineReasonLevel = [
  { label: "Level 1", value: 1 },
  { label: "Level 2", value: 2 },
  { label: "Level 3", value: 3 },
  { label: "Level 4", value: 4 },
  { label: "Level 5", value: 5 }
];

export const notificationColors = {
  1:"primary",
  2:"success",
  3:"warning",
  4:"error",
  5:"secondary",
  6:"info",
}

export const convertHMS = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`; // Return is HH : MM : SS
};

export const getUpdatedKeysObject = (obj1, obj2) => {
  const keys = Object.keys(obj1);
  const updatedKeys = {};
  keys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      updatedKeys[key] = obj1[key];
    }
  });
  return updatedKeys;
};

export const arraysEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const getUpdatedKeysObjectDeeperCompare = (obj1, obj2) => {
  const updatedKeys = {};

  const compare = (val1, val2) => {
    if (typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null) {
      return !shallowEqual(val1, val2);
    }
    return val1 !== val2;
  };

  const shallowEqual = (objA, objB) => {
    if (objA === objB) {
      return true;
    }

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (let i = 0; i < keysA.length; i++) {
      if (!objB.hasOwnProperty(keysA[i]) || !compare(objA[keysA[i]], objB[keysA[i]])) {
        return false;
      }
    }

    return true;
  };

  const keys = Object.keys(obj1);
  keys.forEach((key) => {
    if (compare(obj1[key], obj2[key])) {
      updatedKeys[key] = obj1[key];
    }
  });
  return updatedKeys;
};
