import { quantum } from "ldrs";

function Loader() {
  quantum.register("my-precious");
  return (
    <div className="loader-overlay">
      <my-precious color="white" />
    </div>
  );
}

export default Loader;
