import { GET_SEARCH_VERSUS, ADD_SEARCH_VERSUS, DELETE_SEARCH_VERSUS } from "../constants";

const initialState = {
  versusSearch: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_VERSUS:
      return {
        ...state,
        versusSearch: state.versusSearch
      };
    case ADD_SEARCH_VERSUS:
      return {
        ...state,
        versusSearch: action.payload.search
      };
    case DELETE_SEARCH_VERSUS:
      return {
        ...state,
        versusSearch: {}
      };
    default:
      return state;
  }
};
