/* eslint-disable no-bitwise */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";
import translate from "i18n/translate";
import { formateSecondToDay } from "utils";
import ReactEcharts from "echarts-for-react";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import { quantum } from "ldrs";

function TimelineReasonChart({
  isLoading,
  isFilterData = false,
  isSingleDay = false,
  machineData
}) {
  quantum.register("my-precious");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [timeLineDataValue, setTimeLineDataValue] = useState([]);
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    if (machineData) {
      if (isSingleDay && !isFilterData) {
        const dataTimeLine = [];
        machineData?.timeline_reason?.data?.forEach((x) => {
          const obj = dataTimeLine?.find((o) => o?.[1] === x?.[1]);
          if (obj) {
            obj[2] += x[2];
          } else {
            dataTimeLine.push(x);
          }
        });
        if (dataTimeLine?.[0]?.length > 0) {
          setTimeLineDataValue(dataTimeLine?.[0]?.sort((a, b) => a[2] - b[2]));
        } else {
          setTimeLineDataValue([]);
        }
      } else if (!isFilterData) {
        const timeLineDataValueTemp = machineData
          ?.map((item) => {
            const dateObject = Object.values(item);
            return dateObject?.[0]?.timeline_reason?.data;
          })
          ?.flat();
        if (timeLineDataValueTemp?.length > 0) {
          const dataTimeLine = [];
          timeLineDataValueTemp?.forEach((x) => {
            const obj = dataTimeLine.find((o) => o?.[1] === x?.[1]);
            if (obj) {
              obj[2] += x[2];
            } else {
              dataTimeLine.push(x);
            }
          });
          setTimeLineDataValue(dataTimeLine?.sort((a, b) => a[2] - b[2]));
          const timelineDataTemp = dataTimeLine
            ?.map((item) =>
              item?.[1]?.toString().length <= 12 ? item?.[1] : item?.[1].substring(0, 12)
            )
            ?.filter((item, pos, self) => self.indexOf(item) === pos);
          setTimelineData(timelineDataTemp);
        } else {
          setTimeLineDataValue([]);
        }
      }
      if (isFilterData) {
        const dataTimeLine = [];
        machineData?.forEach((x) => {
          const obj = dataTimeLine.find((o) => o?.[1] === x?.[1]);
          if (obj) {
            obj[2] += x[2];
          } else {
            dataTimeLine.push(x);
          }
        });
        setTimeLineDataValue(dataTimeLine?.sort((a, b) => a[2] - b[2]));
      }
    }
  }, [machineData, isSingleDay, isFilterData]);

  const convertToRangePercentage = (value) => {
    const minValue = Math.min(...timeLineDataValue.map((item) => item[2]));
    const maxValue = Math.max(...timeLineDataValue.map((item) => item[2]));
    const sumOfValue = timeLineDataValue
      .map((item) => item[2])
      .reduce((partialSum, a) => parseInt(partialSum, 10) + parseInt(a, 10), 0);
    if (timeLineDataValue?.length === 1) {
      return 100;
    }
    if (minValue === maxValue) {
      return 99;
    }
    const percentage = (parseInt(value, 10) / sumOfValue) * 100;
    return Math.abs(percentage.toFixed(2)) === 0 ? 1 : Math.abs(percentage.toFixed(2));
  };
  const colorPalette = [
    "#81C784",
    "#81D4FA",
    "#1976D2",
    "#388E3C",
    "#2E7D32",
    "#1B5E20",
    "#43A047",
    "#B9F6CA",
    "#69F0AE",
    "#00E676",
    "#00C853",
    "#8BC34A",
    "#B3E5FC",
    "#82B1FF",
    "#4FC3F7",
    "#039BE5",
    "#0288D1",
    "#29B6F6",
    "#03A9F4",
    "#01579B",
    "#2979FF"
  ];

  const baseHeight = 120; // Base height for the chart
  const barHeight = 50; // Height for each bar including gap

  const calculateChartHeight = (numberOfBars) => {
    return baseHeight + numberOfBars * barHeight;
  };

  const calculatedHeight = calculateChartHeight(timeLineDataValue.length);

  const TimelineReason = {
    tooltip: {
      trigger: "item",
      formatter: (value) =>
        ` ${value?.marker} ${
          value?.componentSubType === "pie" ? value?.data?.fullName : value?.data?.[2]
        } - ${
          value?.componentSubType === "pie"
            ? `${value?.data?.oValue}%`
            : formateSecondToDay(value?.data?.[0])
        } ${
          value?.componentSubType === "pie"
            ? `| Instances: ${value?.data?.count}`
            : value?.value?.[4]
            ? `| Instances: ${value?.value?.[4]}`
            : ""
        }`
    },
    grid: [
      {
        top: 40,
        width: "50%",
        bottom: 20,
        left: 10,
        right: 10,
        containLabel: true
      },
      {
        top: "60%",
        width: "50%",
        maxHeight: "400px",
        bottom: 10,
        left: 10,
        containLabel: true
      }
    ],
    xAxis: [
      {
        type: "value",
        splitLine: {
          show: false
        },
        axisLine: {
          show: true // Hide the axis line
        },
        axisLabel: {
          interval: 0,
          rotate: 30,
          formatter(val) {
            return formateSecondToDay(val);
          },
          color: darkMode ? "#ffffffcc" : "#7B809A"
        }
      }
    ],
    yAxis: [
      {
        type: "category",
        data:
          isSingleDay || isFilterData
            ? timeLineDataValue
                ?.sort((a, b) => a[2] - b[2])
                ?.map((item) =>
                  item?.[1]?.toString().length <= 12 ? item?.[1] : item?.[1].substring(0, 12)
                )
            : timelineData,
        axisLabel: {
          interval: 0,
          rotate: 30,
          color: darkMode ? "#ffffffcc" : "#7B809A"
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        type: "bar",
        stack: "chart",
        barWidth: barHeight - 10,
        barGap: "10%",
        label: {
          position: "right",
          show: true,
          fontWeight: "normal",
          color: "#fff",
          right: "90px",
          formatter: (value) =>
            `${formateSecondToDay(value?.value?.[0])} ${
              value?.value?.[4] ? `(Instances: ${value?.value?.[4]})` : ""
            }`
        },
        itemStyle: {
          color(param) {
            // param.value[0] is the x-axis value
            return (
              param?.value?.[5] ||
              `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
            );
          }
        },
        // graph: {
        //   color: colorPalette
        // },
        data: timeLineDataValue?.map((item, index) => [
          item?.[2],
          item?.[1]?.substring(0, 12),
          item?.[1],
          index,
          item?.[3],
          item?.[5] || `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
        ])
      },
      {
        type: "pie",
        radius: ["30%", "70%"],
        center: ["75%", "50%"],
        padAngle: 0.5,
        // graph: {
        //   color: colorPalette
        // },
        min: 1,
        label: {
          position: "right",
          show: true,
          fontWeight: "normal",
          color: "#fff",
          formatter: (params) => `${params.data.name}`
        },
        itemStyle: {
          borderRadius: 2,
          color(param) {
            // param.value[0] is the x-axis value
            return (
              param?.data?.color ||
              `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
            );
          }
        },
        data: timeLineDataValue?.map((item, index) => ({
          name: item?.[1]?.substring(0, 12),
          value:
            convertToRangePercentage(item?.[2]) < 1
              ? convertToRangePercentage(item?.[2]) + 1.5
              : convertToRangePercentage(item?.[2]),
          oValue: convertToRangePercentage(item?.[2]),
          fullName: item?.[1],
          count: item?.[3],
          index,
          color: item?.[5] || `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`
        }))
      }
    ]
  };

  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {isLoading ? (
        <MDCard sx={{ margin: "10px 0", padding: "10px", overflow: "auto", flexGrow: 1 }}>
          <MDTypography sx={{ textAlign: "center" }}>{translate("Downtime Analysis")}</MDTypography>
          <MDBox
            sx={{
              position: "relative",
              height: "300px",
              itemsAlign: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "150px"
            }}
          >
            <my-precious color="white" />
          </MDBox>
        </MDCard>
      ) : (
        timeLineDataValue.length > 0 && (
          <MDCard sx={{ margin: "10px 0", padding: "10px", overflow: "auto", flexGrow: 1 }}>
            <MDTypography sx={{ textAlign: "center" }}>
              {translate("Downtime Analysis")}
            </MDTypography>
            <ReactEcharts
              option={TimelineReason}
              style={{
                overflow: "auto",
                minWidth: 720,
                height: calculatedHeight,
                width: "100%"
              }}
            />
          </MDCard>
        )
      )}
    </MDBox>
  );
}

export default TimelineReasonChart;
