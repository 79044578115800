/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { quantum } from "ldrs";
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";
import ReactEcharts from "echarts-for-react";
import translate from "i18n/translate";

function GraphView({ isLoading, oeeOption, plantEnergyOption, co2EmissionsOptions }) {
  quantum.register("my-precious");
  return (
    <>
      <MDCard sx={{ margin: "10px 0", padding: "10px" }}>
        <MDTypography sx={{ marginTop: "10px" }} textAlign="center">
          {translate("Plant OEE")}
        </MDTypography>
        {isLoading || !oeeOption ? (
          <MDBox
            sx={{
              position: "relative",
              height: "300px",
              itemsAlign: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "150px"
            }}
          >
            <my-precious color="white" />
          </MDBox>
        ) : (
          <ReactEcharts option={oeeOption} />
        )}
      </MDCard>

      <MDCard sx={{ margin: "10px 0", padding: "10px" }}>
        <MDTypography sx={{ marginTop: "10px" }} textAlign="center">
          {translate("Plant energy")}
        </MDTypography>
        {isLoading || !plantEnergyOption ? (
          <MDBox
            sx={{
              position: "relative",
              height: "300px",
              itemsAlign: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "150px"
            }}
          >
            <my-precious color="white" />
          </MDBox>
        ) : (
          <ReactEcharts option={plantEnergyOption} />
        )}
      </MDCard>
      <MDCard sx={{ margin: "10px 0", padding: "10px" }}>
        <MDTypography sx={{ marginTop: "10px" }} textAlign="center">
          {translate("co2_emissions in tons")}
        </MDTypography>
        {isLoading || !co2EmissionsOptions ? (
          <MDBox
            sx={{
              position: "relative",
              height: "300px",
              itemsAlign: "center",
              display: "flex",
              justifyContent: "center",
              paddingTop: "150px"
            }}
          >
            <my-precious color="white" />
          </MDBox>
        ) : (
          <ReactEcharts option={co2EmissionsOptions} />
        )}
      </MDCard>
    </>
  );
}

export default GraphView;
