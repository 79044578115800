import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "api/axios";
import Cookies from "js-cookie";
import useAuth from "hooks/useAuth";
import translate from "i18n/translate";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { quantum } from "ldrs";

function MicrosoftOAuth({ startIcon }) {
  const clientId = process.env.REACT_APP_MS_CLIENT_ID;
  const clientAccessType = process.env.REACT_APP_MS_ACCESS_TYPE;
  const redirectUri = process.env.REACT_APP_MS_REDIRECT_URI;
  const isSignUp = !!localStorage.getItem('license');

  const authPath = isSignUp // Modify to handle sign-up vs. sign-in
    ? "v2/oauth/microsoft_register"
    : "v2/oauth/microsoft_login";

  const msOAuthURL = `https://login.microsoftonline.com/${clientAccessType}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid+profile+email`;

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  quantum.register("my-precious");

  const handleRedirectToMicrosoft = () => {
    if (isSignUp) {
      const licenseKey = localStorage.getItem("license"); // Retrieve the license key
      if (licenseKey) {
        // Proceed only if licenseKey exists
        window.location.href = msOAuthURL; // Redirect to Microsoft login
      } else {
        // Handle the case where licenseKey is not available
        setStatusMessage(translate("License key is required for sign-up."));
      }
    } else {
      window.location.href = msOAuthURL; // Redirect to Microsoft login for sign-in
    }
  };

  const handleLogin = async (code) => {
    let message = '';

    try {
      setLoading(true); // Show loader when attempting login
      setStatusMessage(translate("Attempting to login..."));
      const storedLicenseKey = localStorage.getItem("license"); // Retrieve stored license key
      const response = await axios.post(authPath, {
        code,
        license: isSignUp ? storedLicenseKey : undefined, // Pass stored license key if signing up
      });

      message = response.data.message || '';

      if (isSignUp) {
        // Redirect to the registration confirmation page with a success message
        window.history.replaceState({}, document.title, window.location.pathname);
        setStatusMessage(message || translate("Registration successful. Please confirm your email."));
      } else {
        const { token: Token, refresh_token: refreshToken, user } = response.data;
        const roles = user?.role;
        const UserCompanyId = user?.company?.id;
        const Id = user?.id;
        const role = user?.role?.name;
        const setting = user?.setting;
        const name = user?.name;
      
        setAuth({ user: user.email, token: Token, refreshToken, roles });
        Cookies.set("tok", refreshToken, { expires: 15 });
        Cookies.set("id", Id);
        Cookies.set("companyid", UserCompanyId);
        Cookies.set("role", role);
        Cookies.set("setting", setting);
        Cookies.set("name", name);
      
        navigate(`/dashboard/machines`, { replace: true });
      }
      
      localStorage.removeItem("license"); // Clear the license key after use

    } catch (error) {
      if (error.response) {
        message = error.response.data.message || '';
        setStatusMessage(message); // Set the error message to be passed

        // Pass the error message as state during navigation
        navigate(`/signup`, { state: { message }, replace: true });
      } else {
        console.error("Unexpected Microsoft Login Error:", error);
      }
    } finally {
      setLoading(false); // Stop loader when done
      // if (message) {
      //   navigate(`/signup`, { state: { message }, replace: true });
      // }
    }
  };

  const handleMicrosoftCallback = () => {
    const queryString = window.location.search;
    const code = new URLSearchParams(queryString).get("code");
    if (code) {
      handleLogin(code); // Pass the code to the login handler
    } else {
      console.error("Authorization code not found.");
    }
  };

  useEffect(() => {
    handleMicrosoftCallback();
  }, []);


  return (
    <div>
      {loading ? (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh" // Full viewport height
        >
          <my-precious color="white" />
          <p style={{ color: "white", marginLeft: "20px" }}>{translate(isSignUp ? "Attempting to register..." : "Attempting to login...")}</p>
        </MDBox>
      ) : (
        <>
          {statusMessage ? ( // If there's a status message, show it
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100vh" // Full viewport height
              width="100vw"
            >
              <p style={{ color: "white", marginLeft: "20px", textAlign: "center" }}>{statusMessage}</p>
              <MDTypography
                component={Link}
                to="/signin"
                variant="button"
                color="info"
                fontWeight="medium"
                style={{ marginTop: "20px" }} // Adds some space above the link
              >
                Redirect to WatchMen signIn..
              </MDTypography>
            </MDBox>
          ) : ( // Otherwise, show the Microsoft login button
            <MDButton
              onClick={handleRedirectToMicrosoft} // Trigger the redirect function
              fullWidth
              variant="gradient"
              color="info"
              startIcon={<MicrosoftIcon />}
            >
              {translate(isSignUp ? "signUpWith" : "signInWith")} MICROSOFT
            </MDButton>
          )}
        </>
      )}
    </div>
  );
}

export default MicrosoftOAuth;
