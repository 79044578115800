/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Grid, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Counts from "components/counts";
import translate from "i18n/translate";

function RealTimeOverview({ isLoading, dayCummulative }) {
  return (
    <>
      <MDBox mt={3}>
        <MDTypography variant="h5" fontWeight="medium">
          {translate("Real time overview")}
        </MDTypography>
      </MDBox>
      {!isLoading ? (
        <Grid container spacing={3} mt={0}>
          <Counts
            name="Production"
            tooltip="Number of machines currently MACHINING on the production floor"
            count={dayCummulative?.overview?.production}
            xs={12}
            sm={6}
            md={3}
          />
          <Counts
            name="Idle"
            tooltip="Number of machines currently IDLE on the production floor"
            count={dayCummulative?.overview?.idle}
            xs={12}
            sm={6}
            md={3}
          />
          <Counts
            name="Off"
            tooltip="Number of machines currently OFF on the production floor"
            count={dayCummulative?.overview?.stopped}
            xs={12}
            sm={6}
            md={3}
          />
          <Counts
            name="Preparation"
            tooltip="Number of machines currently in PREPARATION on the production floor"
            count={dayCummulative?.overview?.preparation}
            xs={12}
            sm={6}
            md={3}
          />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default RealTimeOverview;
