/* eslint-disable react/prop-types */
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Checkbox,
  Skeleton
} from "@mui/material";
import { invalidateQuery } from "api/customReactQueryClient";
import { enumQueryNames } from "api/reactQueryConstant";
import {
  updateShiftGroupApi,
  createShiftGroupApi,
  getMachineListApi,
  getShiftListApi
} from "api/watchmenApi";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenNewShiftGroupForm, useMaterialUIController, setSuccessMsg } from "context";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import translate from "i18n/translate";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getUpdatedKeysObject, arraysEqual } from "utils/constants";
import { dotStream } from 'ldrs'

dotStream.register()

export const useStyle = () => ({
  skeleton: {
    transform: "unset",
    margin: "1% 0"
  }
});

function NewShiftGroup({ updateShiftGroup, setUpdateShiftGroup, refetch }) {
  const { auth } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openNewShiftGroupForm } = controller;
  const { axiosPrivate } = useAxiosPrivate();
  const [shift, setShift] = useState([]);
  const [shiftGroupName, setShiftGroupName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [machines, setMachines] = useState([]);
  const [shiftDays, setShiftDays] = useState({});
  const classes = useStyle();

  useEffect(() => {
    if (updateShiftGroup) {
      setShiftGroupName(updateShiftGroup.name);
      setShift(updateShiftGroup.shift_list.map((curShift) => curShift.id));
      setSelectedMachines(updateShiftGroup.machine_list.map((machine) => machine.id));

      // Initialize shiftDays state
      const initialShiftDays = updateShiftGroup.shift_list.reduce((acc, curShift) => {
        const shiftId = curShift.id;
        acc[shiftId] = [];
        Object.keys(updateShiftGroup.meta_backend.shift_days).forEach(dayKey => {
          if (updateShiftGroup.meta_backend.shift_days[dayKey].includes(shiftId)) {
            const dayName = dayKey.replace('_shift', '');
            acc[shiftId].push(dayName.charAt(0).toUpperCase() + dayName.slice(1));
          }
        });
        return acc;
      }, {});
      setShiftDays(initialShiftDays);
    }
  }, [updateShiftGroup]);

  const handleCloseShiftGroupForm = () => {
    setOpenNewShiftGroupForm(dispatch, !openNewShiftGroupForm);
    setShiftGroupName("");
    setShift([]);
    setSelectedMachines([]);
    setErrMsg("");
    setUpdateShiftGroup(null);
    setShiftDays({});
  };

  const setShiftHandler = (value) => {
    setErrMsg("");
    setShift(value);
    const newShiftDays = value.reduce((acc, shiftId) => {
      acc[shiftId] = shiftDays[shiftId] || [];
      return acc;
    }, {});
    setShiftDays(newShiftDays);
  };

  const { isFetching: shiftFetching, data: shifts = [] } = useQuery(
    [enumQueryNames.SHIFT_LIST],
    () => getShiftListApi(axiosPrivate),
    {
      enabled: openNewShiftGroupForm
    }
  );

  const { isLoading: machineFetching, mutate: fetchMachineListApi } = useMutation(
    [enumQueryNames.MACHINE_LIST],
    () => getMachineListApi(axiosPrivate),
    {
      enabled: openNewShiftGroupForm,
      onSuccess: (data) => {
        setMachines(data);
      }
    }
  );
  useEffect(() => {
    if (auth?.Token) {
      fetchMachineListApi();
    }
  }, [auth?.Token]);

  const { mutate: createShiftGroup, isLoading: createShiftGroupLoading } = useMutation(
    (data) => createShiftGroupApi(axiosPrivate, data),
    {
      onSuccess: ({ message }) => {
        refetch();
        setSuccessMsg(dispatch, message);
        handleCloseShiftGroupForm();
        invalidateQuery([enumQueryNames.SHIFT_GROUP_LIST]);
      }
    }
  );

  const { mutate: updateShiftGroupDetails, isLoading: updateShiftGroupLoading } = useMutation(
    ({ shiftGroupId, data }) => updateShiftGroupApi(axiosPrivate, shiftGroupId, data),
    {
      onSuccess: ({ message }) => {
        setSuccessMsg(dispatch, message);
        handleCloseShiftGroupForm();
        invalidateQuery([enumQueryNames.SHIFT_GROUP_LIST]);
        refetch();
      }
    }
  );

  const handleSubmit = async () => {
    if (shiftGroupName === "" || !shift.length) {
      setErrMsg("Please fill all the fields");
    } else {
      try {
        const allDays = ["monday_shift", "tuesday_shift", "wednesday_shift", "thursday_shift", "friday_shift", "saturday_shift", "sunday_shift"];
        const initialShiftDays = allDays.reduce((acc, day) => {
          acc[day] = [];
          return acc;
        }, {});

        const shiftGroup = {
          name: shiftGroupName,
          meta_backend: {
            shift_days: Object.keys(shiftDays).reduce((acc, shiftId) => {
              shiftDays[shiftId].forEach((day) => {
                const dayKey = `${day.toLowerCase()}_shift`;
                if (!acc[dayKey]) {
                  acc[dayKey] = [];
                }
                acc[dayKey].push(Number(shiftId));
              });
              return acc;
            }, initialShiftDays) // Ensure all days are included with initialShiftDays
          },
          shifts: shift
        };

        // Check and compare before updating the data
        if (updateShiftGroup) {
          const originalMachineIds = updateShiftGroup?.machine_list.map(machine => machine.id);
  
          // Check if arraysEqual function works correctly
          const areArraysEqual = arraysEqual(originalMachineIds, selectedMachines);
  
          if (!areArraysEqual) {
            shiftGroup.machines = selectedMachines;
          }
  
          // Normalize shift_days in updateShiftGroup
          const normalizedUpdateShiftGroup = {
            ...updateShiftGroup,
            meta_backend: {
              ...updateShiftGroup.meta_backend,
              shift_days: {
                ...initialShiftDays,
                ...updateShiftGroup.meta_backend.shift_days
              }
            }
          };
  
          const compareObjectsTemp = getUpdatedKeysObject(shiftGroup, normalizedUpdateShiftGroup);
  
          if (Object.keys(compareObjectsTemp).length !== 0) {
            updateShiftGroupDetails({
              shiftGroupId: updateShiftGroup.id,
              data: compareObjectsTemp
            });
          }
        } else {
          if (selectedMachines.length) {
            shiftGroup.machines = selectedMachines;
          }
          createShiftGroup(shiftGroup);
        }
      } catch (err) {
        setErrMsg("Unable to create a shift. Please try again later!");
      }
    }
  };

  return (
    <Modal
      open={openNewShiftGroupForm}
      onClose={handleCloseShiftGroupForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={({ palette: { dark, white } }) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: darkMode ? "#0F141F" : "#eeeeee",
          border: "1px solid #000",
          borderRadius: "3%",
          boxShadow: 24,
          p: 4,
          color: darkMode ? white.main : dark.main,
          maxHeight: "90vh",
          overflow: "auto"
        })}
        className="customScroll"
      >
        {shiftFetching || machineFetching ? (
          <Skeleton height={500} width="100%" sx={classes.skeleton} />
        ) : (
          <MDBox pt={0.5} pb={3} px={1}>
            <MDTypography
              variant="button"
              color="light"
              fontWeight="medium"
              textGradient
              textAlign="center"
              px={2}
              pb={4}
              fontSize="1.25rem"
            >
              {updateShiftGroup ? translate("updateShiftGroup") : translate("addShiftGroup")}
            </MDTypography>
            {errMsg && (
              <MDBox mb={2}>
                <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                  {errMsg}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="outlined"
                value={shiftGroupName}
                fullWidth
                onChange={(e) => {
                  setErrMsg("");
                  setShiftGroupName(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-company-label">Select Machines</InputLabel>
                <Select
                  labelId="select-company-label"
                  id="select-company"
                  value={selectedMachines}
                  label="Select Machines"
                  sx={{
                    minHeight: "45px"
                  }}
                  multiple
                  onChange={(e) => {
                    setErrMsg("");
                    setSelectedMachines(e.target.value);
                  }}
                  renderValue={(selected) =>
                    selected
                      .map((selId) => machines?.find((machine) => machine.id === selId)?.name)
                      .join(", ")
                  }
                >
                  {machines?.map((machine) => (
                    <MenuItem value={machine.id} key={machine.id}>
                      <Checkbox checked={selectedMachines.indexOf(machine.id) > -1} />
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "14px" }}
                        primary={machine.name}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-shifts-label">{translate("Select Shifts")}</InputLabel>
                <Select
                  labelId="select-shifts-label"
                  id="select-shifts"
                  value={shift}
                  multiple
                  onChange={(e) => setShiftHandler(e.target.value)}
                  renderValue={(selected) =>
                    selected
                      .map((selId) => shifts?.find((curShift) => curShift.id === selId)?.name)
                      .join(", ")
                  }
                  label={translate("Select Shifts")}
                  sx={{
                    minHeight: "45px"
                  }}
                >
                  {shifts?.map((curshift) => (
                    <MenuItem value={curshift.id} key={curshift.id}>
                      <Checkbox checked={shift.includes(curshift.id)} />
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "14px" }}
                        primary={curshift.name}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>

            {shift.map((shiftId) => {
              const shiftName = shifts.find((curShift) => curShift.id === shiftId)?.name;
              return (
                <MDBox mb={3} key={shiftId}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id={`select-days-label-${shiftId}`}>
                      {translate(`Select weekdays for ${shiftName}`)}
                    </InputLabel>
                    <Select
                      labelId={`select-days-label-${shiftId}`}
                      id={`select-days-${shiftId}`}
                      value={shiftDays[shiftId] || []}
                      multiple
                      onChange={(e) => {
                        setShiftDays((prevState) => ({
                          ...prevState,
                          [shiftId]: e.target.value
                        }));
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      label={translate(`Select weekdays for ${shiftName}`)}
                      sx={{
                        minHeight: "45px"
                      }}
                    >
                      {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                        (day) => (
                          <MenuItem key={day} value={day}>
                            <Checkbox checked={(shiftDays[shiftId] || []).includes(day)} />
                            <ListItemText
                              primaryTypographyProps={{ fontSize: "14px" }}
                              primary={translate(day)}
                            />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </MDBox>
              );
            })}
            <MDBox mb={2} display="flex" flexDirection="column">
              <MDButton
                color="dark"
                size="medium"
                variant={darkMode ? "contained" : "outlined"}
                onClick={handleSubmit}
                disabled={updateShiftGroupLoading || createShiftGroupLoading} // Add loading state check
              >
                {updateShiftGroupLoading || createShiftGroupLoading ? (
                  <l-dot-stream
                    size="60"
                    speed="2.5"
                    color={darkMode ? "white" : "black"} // Adjust color based on theme
                  ></l-dot-stream>
                ) : (
                  translate(
                    updateShiftGroup
                      ? "update"
                      : "create"
                  )
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </Box>
    </Modal>
  );
}

export default NewShiftGroup;
