/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import * as React from 'react';
// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';
import { useEffect, useState, useRef } from "react";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "components/Sidenav/styles/sidenavCollapse";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import translate from "i18n/translate";
import { Tooltip } from "@mui/material";

function SidenavCollapse({ icon, name, children, active, noCollapse, open, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  const [anchor, setAnchor] = React.useState(null);
  const PopupBody = styled('div')(
    ({ theme, itemCount }) => `
    width: max-content;
    margin-left:60px;
    margin-top:-30px;
    margin-bottom:50px;
    overflow-x: hidden;
    overflow-y: auto;
    height: ${Math.min((itemCount * 43) + 10, 280)}px;
    border-radius: 8px;
    scroll-behavior: smooth;
    background-color: #1c1a1a;
    box-shadow: ${theme.palette.mode === 'dark'
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
      };
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.975rem;
    position:absolute;
     color: (transparentSidenav && !darkMode) || whiteSidenav ? dark.main : dark.main;
      "&::before": {
          content: "";
          position: absolute;
          top: 60px;
          left:-30px;
          border: solid 15px transparent;
          border-right-color: #FFF;
        }
        //   "&::hover": {
        //   z-index:-1;
        // }
  `,

  );
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const blue = {
    200: '#99CCFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
  };

  const handleClickCollapse = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  }
  const btnRef = useRef();
const btnRef1 = useRef();

useEffect(() => {
    const closeDropdown = (e) => {
      if (
        (miniSidenav && !btnRef1.current) ||
        (miniSidenav && !btnRef1.current.contains(e.target))
      ) {
        if (
          (miniSidenav && !btnRef.current) ||
          (miniSidenav && !btnRef.current.contains(e.target))
        ) {
          setAnchor(null);
        }
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const openpopup = Boolean(anchor);
  const idpopup = openpopup ? 'simple-popper' : undefined;

  return (
    <div ref={btnRef}>
      <ListItem component="li" >
        <MDBox 
          {...rest}
          sx={(theme) =>
            collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
          }
        >
          <Tooltip title={translate(name)} placement="right-start" disableInteractive>
            <ListItemIcon 
              sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
              onClick={handleClickCollapse} style={{ cursor: 'pointer' }}
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active }) }>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={translate(name)}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                open,
                active,
                darkMode,
                anchor
              })
            }
            // onClick={handleClickCollapse}
          >
            expand_less
          </Icon>
        </MDBox>
      </ListItem>

      {miniSidenav && children ?
        (<BasePopup ref={btnRef1} id={idpopup} open={openpopup} anchor={anchor}>
          <PopupBody itemCount={React.Children.count(children)}>
            {children}
          </PopupBody>
        </BasePopup>) :
        (children && (
          <Collapse in={open} unmountOnExit ref={btnRef1}>
            {children}
          </Collapse>
        )
        )}
    </div>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
  name: ''
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
