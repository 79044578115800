/* eslint-disable react/prop-types */
import {
  Box,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from "@mui/material";
import { invalidateQuery } from "api/customReactQueryClient";
import { enumQueryNames } from "api/reactQueryConstant";
import { createOperatorsApi, updateOperatorsApi } from "api/watchmenApi";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenOperatorForm, setSuccessMsg, useMaterialUIController } from "context";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getUpdatedKeysObject } from "utils/constants";
import { dotStream } from 'ldrs'
import translate from "i18n/translate";

dotStream.register()

function NewOperator({ machineList, updateOperator, setUpdateOperator, refetch }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openOperatorForm } = controller;
  const { axiosPrivate } = useAxiosPrivate();
  const [name, setName] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedMachines, setSelectedMachines] = useState([]);

  useEffect(() => {
    if (updateOperator) {
      setName(updateOperator.name);
      setFirstName(updateOperator.first_name);
      setLastName(updateOperator.last_name);
      setEmail(updateOperator.email);
      const accessibleMachineIds = updateOperator.accessible_machines.map(machine => machine.id);
      setSelectedMachines(accessibleMachineIds);
    };
  }, [updateOperator, machineList]);

  const handleCloseOperatorForm = () => {
    setOpenOperatorForm(dispatch, !openOperatorForm);
    setName("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setSelectedMachines("");
    setErrMsg("");
    setUpdateOperator(null);
  };

  const { mutate: createOperatorsApi1, isLoading: createOperatorLoading } = useMutation(
    (data) => createOperatorsApi(axiosPrivate, data),
    {
      onSuccess: ({ message }) => {
        refetch();
        handleCloseOperatorForm();
        invalidateQuery([enumQueryNames.OPERATOR_LIST]);
        setSuccessMsg(dispatch, message);
      }
    }
  );

  const { mutate: updateOperatorsApi1, isLoading: updateOperatorLoading } = useMutation(
    ({ id, data }) => updateOperatorsApi(axiosPrivate, id, data),
    {
      onSuccess: ({ message }) => {
        refetch();
        handleCloseOperatorForm();
        invalidateQuery([enumQueryNames.OPERATOR_LIST]);
        setSuccessMsg(dispatch, message);
      }
    }
  );

  const handleSubmit = async () => {
    if (
      name === "" ||
      firstName === "" ||
      lastName === "" ||
      email === ""
      // selectedMachines.length === 0
    ) {
      setErrMsg("Please fill all the fields");
    } else if (updateOperator) {
      const operator = {
        name,
        first_name: firstName,
        last_name: lastName,
        accessible_machines: selectedMachines,
        email
      };
      const compareObjectsTemp = getUpdatedKeysObject(operator, updateOperator);
      if (Object.keys(compareObjectsTemp).length !== 0) {
        updateOperatorsApi1({ id: updateOperator.id, data: compareObjectsTemp });
      }
    } else {
      const operator = {
        name,
        first_name: firstName,
        last_name: lastName,
        email,
        accessible_machines: selectedMachines
      };
      createOperatorsApi1(operator);
    }
  };

  return (
    <Modal
      open={openOperatorForm}
      onClose={handleCloseOperatorForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={({ palette: { dark, white } }) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: darkMode ? "#0F141F" : "#eeeeee",
          border: "1px solid #000",
          borderRadius: "3%",
          boxShadow: 24,
          p: 4,
          color: darkMode ? white.main : dark.main,
          maxHeight: "90vh",
          overflow: "auto"
        })}
        className="customScroll"
      >
        <MDBox pt={0.5} pb={3} px={3} display="flex" flexDirection="column">
          <MDTypography
            variant="button"
            color="light"
            fontWeight="medium"
            textGradient
            textAlign="center"
            px={2}
            pb={2}
            fontSize="1.25rem"
          >
            {updateOperator ? translate("updateOperator") : translate("addOperator")}
          </MDTypography>
          {errMsg && (
            <MDBox mb={2}>
              <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                {errMsg}
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2} mt={3}>
            <MDInput
              type="text"
              label={translate("name")}
              variant="outlined"
              value={name}
              fullWidth
              onChange={(e) => {
                setErrMsg("");
                setName(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label={translate("firstName")}
              variant="outlined"
              value={firstName}
              fullWidth
              onChange={(e) => {
                setErrMsg("");
                setFirstName(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label={translate("lastName")}
              variant="outlined"
              value={lastName}
              fullWidth
              onChange={(e) => {
                setErrMsg("");
                setLastName(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label={translate("email")}
              variant="outlined"
              value={email}
              fullWidth
              onChange={(e) => {
                setErrMsg("");
                setEmail(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-company-label">{translate("selectAccessibleMachine")}</InputLabel>
              <Select
                labelId="select-company-label"
                id="select-company"
                value={selectedMachines}
                label={translate("selectAccessibleMachine")}
                sx={{
                  minHeight: "45px"
                }}
                multiple
                onChange={(e) => {
                  setErrMsg("");
                  setSelectedMachines(e.target.value);
                }}
                renderValue={(selected) =>
                  selected
                    .map((selId) => machineList?.find((machine) => machine.id === selId)?.name)
                    .join(", ")
                }
              >
                {machineList?.map((machine) => (
                  <MenuItem value={machine.id} key={machine.id}>
                    <Checkbox checked={selectedMachines.indexOf(machine.id) > -1} />
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={machine.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2} mt={2} display="flex" flexDirection="column">
            <MDButton
              color="dark"
              size="medium"
              variant={darkMode ? "contained" : "outlined"}
              onClick={handleSubmit}
              disabled={createOperatorLoading || updateOperatorLoading} // Disable button while loading
            >
              {(createOperatorLoading || updateOperatorLoading) ? (
                <l-dot-stream
                  size="60"
                  speed="2.5"
                  color={darkMode ? "white" : "black"} // Adjust color based on theme
                ></l-dot-stream>
              ) : (
                updateOperator ? translate("update") : translate("create")
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
}

export default NewOperator;
