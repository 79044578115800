/* eslint-disable react/prop-types */
import { Box, Modal, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, deDE, enUS, frFR } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setOpenNewTokenForm, setSuccessMsg } from "context";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import colors from "assets/theme-dark/base/colors";
import moment from "moment";
import { createTokenApi, updateTokenApi } from "api/watchmenApi";
import { enumQueryNames } from "api/reactQueryConstant";
import { useMutation } from "react-query";
import { invalidateQuery } from "api/customReactQueryClient";
import translate from "i18n/translate";
import { calenderDarkTheme } from "layouts/dashboards/machineShifts/rangepicker";
import { ThemeProvider } from "@mui/system";
import { LOCALES } from "i18n";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getUpdatedKeysObject } from "utils/constants";
import { dotStream } from 'ldrs'

dotStream.register()

function NewToken({ updateToken, setUpdateToken, refetch }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openNewTokenForm, language } = controller;
  const { axiosPrivate } = useAxiosPrivate();
  const [tokenName, setTokenName] = useState("");
  const [errMsg, setErrMsg] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [createdToken, setCreatedToken] = useState("");
  const locale = language === LOCALES.GERMAN ? deDE : language === LOCALES.FRENCH ? frFR : enUS;

  // Define the minimum selectable date and default date
  const minSelectableDate = moment().add(1, "days");
  const defaultDate = moment().add(7, "days");

  useEffect(() => {
    if (updateToken) {
      setTokenName(updateToken.name);
      setExpirationDate(moment(updateToken.valid_till));
    }
  }, [updateToken]);

  const handleCloseTokenForm = () => {
    setOpenNewTokenForm(dispatch, !openNewTokenForm);
    setTokenName("");
    setErrMsg("");
    setCreatedToken("");
    setUpdateToken(null);
  };

  // Copy to clipboard function
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const { mutate: createToken, isLoading: createTokenLoading } = useMutation(
    (data) => createTokenApi(axiosPrivate, data),
    {
      onSuccess: (data) => {
        setCreatedToken(data["x-api-key"]);
        setSuccessMsg(dispatch, data?.message);
        invalidateQuery([enumQueryNames.TOKEN_LIST]);
        refetch();
      }
    }
  );
  const { mutate: updateTokenDetail, isLoading: updateTokenLoading } = useMutation(
    (data) => updateTokenApi(axiosPrivate, data),
    {
      onSuccess: (data) => {
        handleCloseTokenForm();
        refetch();
      }
    }
  );
  const handleSubmit = async () => {
    if (!tokenName || !expirationDate) {
      setErrMsg("Please fill all the fields");
      return;
    }
    if (updateToken) {
      const tag = {
        name: tokenName,
        valid_till: moment(expirationDate).format("DD-MM-YYYY")
      };
      const compareObjectsTemp = getUpdatedKeysObject(tag, updateToken);
      if (Object.keys(compareObjectsTemp).length !== 0) {
        updateTokenDetail({ id: updateToken.id, data: tag });
      }
    } else {
      const token = {
        name: tokenName,
        valid_till: moment(expirationDate).format("DD-MM-YYYY")
      };
      createToken(token);
    }
  };

  return (
    <Modal
      open={openNewTokenForm}
      onClose={handleCloseTokenForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={({ palette: { dark, white } }) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: darkMode ? "#0F141F" : "#eeeeee",
          border: "1px solid #000",
          borderRadius: "3%",
          boxShadow: 24,
          p: 4,
          color: darkMode ? white.main : dark.main,
          maxHeight: "90vh",
          overflow: "auto"
        })}
        className="customScroll"
      >
        <MDBox pt={0.5} pb={3} px={3} display="flex" flexDirection="column">
          <MDTypography
            variant="button"
            color="light"
            fontWeight="medium"
            textGradient
            textAlign="center"
            px={2}
            pb={4}
            fontSize="1.25rem"
          >
            {updateToken ? translate("updateAccessKeys") : translate("addAccessKeys")}
          </MDTypography>
          {errMsg && (
            <MDBox mb={2}>
              <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                {errMsg}
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2}>
            <MDInput
              type="text"
              label={translate("name")}
              variant="outlined"
              value={tokenName}
              fullWidth
              onChange={(e) => {
                setErrMsg("");
                setTokenName(e.target.value);
              }}
            />
          </MDBox>
          <ThemeProvider theme={calenderDarkTheme}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              localeText={locale.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <DesktopDatePicker
                label={translate("Valid till")}
                format="DD/MM/YYYY"
                value={expirationDate || defaultDate}
                onChange={(date) => {
                  setErrMsg("");
                  setExpirationDate(date);
                }}
                sx={{
                  svg: { color: "#ffffff" }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      svg: { color: "#ffffff" }
                    }}
                  />
                )}
                maxDate={moment().add(1, "year")}
                minDate={minSelectableDate}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"]
                  }
                }}
                PaperProps={{
                  sx: {
                    "& .Mui-selected": {
                      backgroundColor: `${colors.info.main} !important`
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
          <MDBox my={2} textAlign="center" display="flex" flexDirection="column">
            <MDButton
              color="dark"
              size="medium"
              variant={darkMode ? "contained" : "outlined"}
              onClick={handleSubmit}
              disabled={updateTokenLoading || createTokenLoading} // Add loading state check
            >
              {updateTokenLoading || createTokenLoading ? (
                <l-dot-stream
                  size="60"
                  speed="2.5"
                  color={darkMode ? "white" : "black"} // Adjust color based on theme
                ></l-dot-stream>
              ) : (
                translate(
                  updateToken
                    ? "update"
                    : "create"
                )
              )}
            </MDButton>
          </MDBox>
          {createdToken && (
            <MDBox mb={2}>
              <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                {translate("copyAccessKeyMessage")}
              </MDTypography>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  padding: "8px 16px",
                  backgroundColor: "#1f1f1f",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  mt: 2
                }}
              >
                <MDTypography
                  variant="button"
                  color="light"
                  fontWeight="medium"
                  textGradient
                  sx={{
                    wordBreak: "break-word",
                    color: "#c3c7c7",
                    flexGrow: 1
                  }}
                >
                  {createdToken}
                </MDTypography>
                <ContentCopyIcon
                  onClick={() => {
                    copyToClipboard(createdToken);
                    setSuccessMsg(dispatch, "Successfully copied!");
                    // handleCloseTokenForm();
                  }}
                  sx={{ marginLeft: 1, cursor: "pointer", color: "white" }}
                  fontSize="small"
                  color="white"
                />
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Box>
    </Modal>
  );
}

export default NewToken;
