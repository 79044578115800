import React, { useState } from 'react';
import { Popover, TextField, FormControlLabel, Switch, Grid } from '@mui/material';
import MDButton from "components/MDButton";

function FilterPopover({ setHighlightCriteria, highlightCriteria, setHighlightToggles, highlightToggles }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <MDButton aria-describedby={id} variant="contained" color="info" variant="gradient" onClick={handleClick}>
        Filter
      </MDButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { backgroundColor: '#222324', zIndex: 1300 }, // Set the background color and ensure the popover is above other elements
        }}
      >
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <TextField
              label="Production Time < (mins)"
              type="number"
              fullWidth
              value={highlightCriteria.productionTime}
              onChange={(e) => setHighlightCriteria({ ...highlightCriteria, productionTime: e.target.value })}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={highlightToggles.productionTime}
                  onChange={(e) => setHighlightToggles({ ...highlightToggles, productionTime: e.target.checked })}
                />
              }
              label="Enable"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Idle Time > (mins)"
              type="number"
              fullWidth
              value={highlightCriteria.idleTime}
              onChange={(e) => setHighlightCriteria({ ...highlightCriteria, idleTime: e.target.value })}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={highlightToggles.idleTime}
                  onChange={(e) => setHighlightToggles({ ...highlightToggles, idleTime: e.target.checked })}
                />
              }
              label="Enable"
            />
          </Grid>
          <Grid item xs={12}>
            <MDButton variant="contained" color="info" variant="gradient" onClick={handleClose}>
              Apply
            </MDButton>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}

export default FilterPopover;
