import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    running: "fonctionnement",
    idle: "Inactif",
    stopped: "Arrêté",
    preparation: "Préparation",
    alerts: "Alertes",
    "No New Notifications": "Pas de nouvelles notifications",
    user: "Utilisateur",
    "floor plan": `Plan d'étage`,
    admin: "administrateur",
    Machines: "Machines",
    machines: "Machines",
    dashboard: "Tableaux de bord",
    Dashboards: "Tableaux de bord",
    floorplan: `Plan d'étage`,
    Floorplan: `Plan d'étage`,
    machinehistory: "historique de la machine",
    profile: "le profil",
    "basic info": "Informations de base",
    username: `Nom d'utilisateur`,
    "firstName": "prénom",
    "lastName": "nom de famille",
    role: "rôle",
    "change password": "changer le mot de passe",
    "current password": "Mot de passe actuel",
    "new password": "nouveau mot de passe",
    "confirm new password": "confirmer le nouveau mot de passe",
    "Password requirements": "Exigences relatives au mot de passe",
    "Please follow this guide for a strong password":
      "Veuillez suivre ce guide pour un mot de passe fort",
    "One special characters": "Un caractère spécial",
    "Min 8 characters": "8 caractères minimum",
    "One number (2 are recommended)": "Un numéro (2 sont recommandés)",
    "Change it often": "Changez-le souvent",
    "One uppercase letter": "Une lettre majuscule",
    "One lowercase letter": "Une lettre minuscule",
    "updating password": "mise à jour du mot de passe",
    "update password": "mettre à jour le mot de passe",
    "Download a supported authentication app to your device (such as Google Authenticator, Microsoft Authenticator etc).":
      "Télécharger une application de connexion supportée sur votre appareil (tel que Google Authenticator, Microsoft Authenticator etc.).",
    "Generate a QR code.": "Générer un code QR.",
    "Generating QR code": "Génération du code QR",
    "Scan the QR code with your authentication app.":
      "Scannez le code QR avec votre application de connexion.",
    "Use the authentication code provided by your authentication app from your next login.":
      "Utilisez le code d'authentification fourni par votre application de connexion depuis votre prochain login.",
    users: "Utilisateurs",
    name: "Nom",
    active: "actif",
    super_admin: "super administrateur",
    company: "compagnie",
    commander: "le commandant",
    "Add Company": "Ajouter une entreprise",
    "Add Ava": "Ajouter Ava",
    "Add Tess": "Ajouter Tess",
    "Add User": "Ajouter utilisateur",
    "MAC address": "MAC address",
    "machine name": "nom de la machine",
    "machine id": "ID de la machine",
    identifier: "identifiant",
    Availability: "Disponibilité",
    Performance: "Productivité",
    Quality: "Qualité",
    "Machine Runtime": "Autonomie de la machine",
    anomalies: "Anomalies",
    cycles: "Cycles",
    Temperature: "Température",
    Overview: "Aperçu",
    Filter: "filtre",
    "Machine Health": "État de la machine",
    "lastHeartbeat": "Dernier signal",
    Runtime: "Durée",
    "Machine History": "Historique des machines",
    "Live Data": "Données en direct",
    "Stop Live Data": "Arrêter les données en direct",
    "Product Count": "Nombre de produits",
    "Good Count": "Bon compte",
    "Bad Count": "Mauvais compte",
    FeatureResponse: "Mauvais compte",
    "Total Processed": "Total traité",
    Measure: "Mesure",
    Feature: "Caractéristique",
    Image: "Image",
    Configurator: "Configurateur",
    "See our dashboard options.": "Voir nos options de tableau de bord.",
    "Sidenav Colors": "Couleurs Sidenav",
    "Sidenav Type": "Type de navigation latérale",
    "Choose between different sidenav types.": "Choisissez entre différents types de sidenav..",
    Dark: "Foncé",
    White: "Blanc",
    "Navbar Fixed": "Barre de navigation fixe",
    "Sidenav Mini": "Sidenav Mini",
    "Light / Dark": "Clair / Foncé",
    "sign in": "S'inscrire",
    signInWith: "S'inscrire avec",
    signUpWith: "s'inscrire auprès de",
    or: "OU",
    "Remember me": "Souviens-toi de moi",
    "signing in": "Connectez-vous",
    "sign up": `S'inscrire`,
    logout: "Déconnexion",
    "Don't have an account?": `Vous n'avez pas de compte ?`,
    "Enter your email and password to register":
      "Entrez votre email et votre mot de passe pour vous inscrire",
    "I agree the": `je suis d'accord le`,
    "Terms and Conditions": "Termes et conditions",
    acceptTermsConditions: "Veuillez accepter les termes et conditions",
    "signing up": `s'enregistrer`,
    "Already have an account?": "Vous avez déjà un compte?",
    "Number of machines currently MACHINING on the production floor": `Nombre de machines actuellement en USINAGE dans l'atelier de production`,
    "Number of machines currently OFF on the production floor": `Nombre de machines actuellement INACTIVES dans l'atelier de production`,
    "Number of machines currently in PREPARATION on the production floor":
      "Nombre de machines actuellement en PRÉPARATION sur le plancher de production",
    "Overall Equipment Effectiveness of the machine. Calculated as an equal factor of Availability, Productivity and Quality of the machine production": `Efficacité globale de l'équipement de la machine. Calculé comme un facteur égal de disponibilité, de productivité et de qualité de la production de la machine`,
    "Availability is the production performance of the machine over a set period of time":
      "La disponibilité est la performance de production de la machine sur une période donnée.",
    "Performance is a cumulative of production and preparation performance of the machine":
      "Les performances sont un cumul des performances de production et de préparation de la machine.",
    "Set at 95% - contact Admin to edit": `Fixé à 95 % - contactez l'administrateur pour modifier`,
    "Number of anomalous behaviors observed in the machine (Coming Soon)":
      "Nombre de comportements anormaux observés dans la machine (Coming Soon)",
    "Number of cycles performed by the machine (Coming Soon)":
      "Nombre de cycles effectués par la machine (Coming Soon)",
    "Current temperature within the machine": "Température actuelle dans la machine",
    "Machine Idletime": `Temps d'inactivité de la machine`,
    Idletime: `temps d'inactivité`,
    "Total ON time of the machine": "Durée totale de fonctionnement de la machine",
    "Total IDLE time of the machine": "Temps total d`inactivité de la machine",
    Timeline: "Chronologie",
    "Shift group": "Groupe de travail",
    "shift group ": "Groupe de travail",
    Shifts: "Changements",
    shifts: "changements",
    start: "commencer",
    end: "fin",
    "addShiftGroup": `Ajouter un groupe d'équipes`,
    "addShift": "Ajouter un quart de travail",
    Languages: "Langages",
    fullname: "Nom et prénom",
    mobile: "Mobile",
    email: "E-mail",
    Timezone: "Fuseau horaire",
    Logo: "Logo",
    "Real time overview": "Aperçu en temps réel",
    "Energy consumption": "Consommation d'énergie",
    "last day": "Dernier jour",
    "last week": "La semaine dernière",
    "last month": "Le mois dernier",
    "last 3 month": "3 derniers mois",
    "Company information": "Informations sur la société",
    "Sort by": "Trier par",
    "Remove Filter": "Supprimer le filtre",
    Save: "Sauvegarder",
    off: "désactivé",
    "Power consumption": "Consommation d'énergie",
    production: "production",
    Zipcode: "Code postal",
    Country: "Pays",
    State: "État",
    City: "Ville",
    "Address line 2": "Adresse Ligne 2",
    "Address line 1": "Adresse 1",
    store: "magasin",
    compare: "comparer",
    addchart: "ajouter un graphique",
    ballot: "bulletin de vote",
    "Authors Table": "Tableau des auteurs",
    "Projects Table": "Tableau des projets",
    Id: "Identifiant",
    Edit: "Modifier",
    Developer: "Développeuse",
    Programator: "Programmateur",
    Manager: "Directrice",
    Executive: "Exécutive",
    Projects: "Projets",
    Organization: "Organisation",
    lightbulb: "ampoule",
    "New York City": "La ville de New York",
    Task: "Tâche",
    "Belong Interactive": "Appartenir interactif",
    "Freelance Payment": "Paiement indépendant",
    Apr: "Avr",
    May: "Peut",
    Jun: "juin",
    Jul: "Juillet",
    Aug: "Août",
    Sep: "Sep",
    Oct: "Octobre",
    Nov: "Nov",
    Dec: "Déc",
    Devices: "Dispositifs",
    "in stock": "en stock",
    sold: "vendue",
    Tickets: "Des billets",
    closed: "fermée",
    open: "ouvrir",
    "is active": "cest actif",
    "Happy users": "Utilisateurs satisfaits",
    Calories: "Calories",
    "All day conference": "Conférence toute la journée",
    "Meeting with Mary": "Rencontre avec Marie",
    "Cyber Week": "Cyber-semaine",
    "Winter Hackaton": "Hackathon dhiver",
    "Digital event": "Événement numérique",
    "Marketing event": "Événement marketing",
    "Dinner with Family": "Dîner avec la famille",
    "Black Friday": "Vendredi noir",
    "Upcoming events": "Évènements à venir",
    Joined: "Rejointe",
    "Tasks planification": "Planification des tâches",
    Steps: "Pas",
    Step: "Étape",
    "Orders overview": "Aperçu des commandes",
    "this month": "ce mois-ci",
    "Design changes": "Changement de design",
    "New order": "nouvel ordre",
    "Server payments for April": "Paiements du serveur pour avril",
    "New card added for order": "Nouvelle carte ajoutée pour la commande",
    "Some Kind Of Blue": "Une sorte de blues",
    Deftones: "Deftons",
    skip_previous: "sauter_précédent",
    play_arrow: "passez au suivant",
    "Full Body": "Tout le corps",
    "What matters is the people who are sparked by it. And the people who are liked.":
      "Ce qui compte, ce sont les gens qui en sont inspirés. Et les gens qui sont appréciés.",
    back: "dos",
    next: "suivante",
    send: "envoyer",
    "Email address": "Adresse e-mail",
    Password: "Mot de passe",
    "Repeat Password": "Répéter le mot de passe",
    Address: "Adresse",
    Zip: "Postal",
    "Twitter Handle": "Poignée Twitter",
    "Facebook Account": "Compte Facebook",
    "Instagram Account": "Compte Instagram",
    "Public Email": "Courriel public",
    Bio: "Biographie",
    "Twitter profile is required": "Un profil Twitter est requis.",
    "Zipcode is not valie (e.g. 70000)": "Le code postal n'est pas valide (par exemple 70000).",
    "City is required": "La ville est obligatoire.",
    "Address is required": "L'adresse est requise.",
    "Your password doesn't match.": "Votre mot de passe ne correspond pas.",
    "Password is required": "Mot de passe requis.",
    "Your password should be more than 6 characters":
      "Votre mot de passe doit comporter plus de 6 caractères.",
    "Email address is required": "Adresse e-mail est nécessaire.",
    "Your email address is invalid": "Votre adresse email est invalide",
    "Last name is required": "Le nom de famille est obligatoire.",
    "First name is required": "Le prénom est requis.",
    "Zip is required": "Le zip est requis.",
    "Mandatory information": "Informations obligatoires",
    "About me": "Sur moi",
    skip_next: "passez au suivant",
    "Unlock packages for development": "Débloquez des packages pour le développement",
    "New message unread": "Nouveau message non lu",
    "Notifications unread": "Notifications non lues",
    "New request": "Nouvelle requête",
    "Controller issues": "Problèmes de contrôleur",
    "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of":
      "Les gens se soucient de la façon dont vous voyez le monde, de votre façon de penser, de ce qui vous motive, de ce avec quoi vous luttez ou dont vous avez peur.",
    "Timeline with dotted line": "Chronologie avec ligne pointillée",
    "Architects design houses": "Les architectes conçoivent des maisons",
    "Music is something that everyone has their own specific opinion about":
      "La musique est quelque chose sur lequel chacun a sa propre opinion.",
    "Different people have different taste, and various types of music":
      "Différentes personnes ont des goûts différents et différents types de musique.",
    "Why would anyone pick blue over pink? Pink is obviously a better color":
      "Pourquoi préférerait-on le bleu au rose ? Le rose est évidemment une meilleure couleur",
    "Track order": "Suivi de commande",
    "Order received": "Ordre reçu",
    "Generate order id": "Générer un identifiant de commande",
    "Order transmited to courier": "Commande transmise au transporteur",
    "Order delivered": "Commande transmise au transporteur",
    "Payment details": "Détails de paiement",
    "We do not store card details": "Nous ne stockons pas les détails de la carte",
    priority_high: "priorité_haute",
    "Order Summary": "Récapitulatif de la commande",
    "Product Price": "Prix ​​du produit",
    Delivery: "Livraison",
    Taxes: "Impôts",
    "Gold Glasses": "Lunettes dorées",
    "Order was delivered 2 days ago.": "La commande a été livrée il y a 2 jours.",
    "contact us": "Contactez-nous",
    "Do you like the product? Leave us a review": "Aimez-vous le produit? Laissez-nous un avis",
    here: "ici",
    "Order Details": "détails de la commande",
    "Order no": "N ° de commande",
    Code: "Code",
    invoice: "facture",
    "Billing Information": "Informations de facturation",
    "Oliver Liam": "Olivier Liam",
    "Company Name": "Nom de lentreprise",
    "Viking Burrito": "Burrito viking",
    "VAT Number": "Numéro de TVA",
    "Please fill all fields": "Merci de compléter tous les champs",
    "Confirm password does not match": "Confirmer que le mot de passe ne correspond pas",
    "Last 7 days": "Les 7 derniers jours",
    "Last 30 days": "Les 30 derniers jours",
    "Top Selling Products": "Produits les plus vendus",
    "Sales by age": "Ventes par âge",
    "Facebook Ads": "Publicités Facebook",
    "Google Ads": "Annonces Google",
    Channels: "Canaux",
    "See traffic channels": "Voir les canaux de trafic",
    "More than": "Plus que",
    "sales are made using referral marketing, and":
      "les ventes sont réalisées grâce au marketing de référence, et",
    "are from social media": "viennent des réseaux sociaux",
    "read more": "En savoir plus",
    Facebook: "Facebook",
    Direct: "Direct",
    Organic: "Organique",
    Referral: "Référence",
    Clear: "Claire",
    Add: "Ajouter",
    "Machine name": "Nom de la machine",
    "Run time": "Durée",
    "Production time": "Temps de production",
    "Off time": "Temps d'arrêt",
    OEE: "OEE",
    Success: "Succès",
    "Select date": "Sélectionner une date",
    "energy wastage": "Gaspillage dénergie",
    "energy consumption costs": "Coûts de consommation dénergie",
    "energy waste costs": "Coûts du gaspillage d’énergie",
    Vibration: "Vibration",
    "Last Campaign Performance": "Performances de la dernière campagne",
    "increase in today sales": "augmentation des ventes aujourdhui",
    'The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona':
      'Lendroit est proche de la plage de la Barceloneta et de larrêt de bus à seulement 2 minutes à pied et à proximité de "Naviglio" où vous pourrez profiter de la principale vie nocturne de Barcelone',
    'he place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK':
      'Le point final est situé à proximité de la station de métro et larrêt de bus à 2 minutes de la gare et à proximité du "Naviglio" ou vous bénéficierez de la nuit de Londres, au Royaume-Uni.',
    'The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan':
      'Lendroit est proche de la station de métro et de larrêt de bus à seulement 2 minutes à pied et à proximité de "Naviglio" où vous pourrez profiter de la principale vie nocturne de Milan.',
    "OEE, Energy consumption and energy wastage vs days":
      "OEE, Consommation d'énergie et gaspillage d'énergie par rapport aux jours",
    "energy consumption": "Consommation d'énergie",
    Total: "Totale",
    "Invalid value for the prop number, the value for the number prop shouldn't be greater than or less than 16 digits":
      "Valeur non valide pour le numéro d'accessoire. La valeur du numéro d'accessoire ne doit pas être supérieure ou inférieure à 16 chiffres.",
    "Card Holder": "Titulaire de la carte",
    Expires: "Expire",
    "expired": "expiré",
    schedule: "calendrier",
    "All Rights Reserved": "Tous droits réservés",
    "WatchMen AI Platform v1.1 by Novo AI": "Plateforme WatchMen AI v1.1 par Novo AI",
    circle: "cercle",
    close: "fermer",
    expand_less: "expand_less",
    arrow_drop_up: "arrow_drop_up",
    arrow_drop_down: "arrow_drop_down",
    "entries per page": "entrées par page",
    Showing: "Affichage",
    entries: "entrées",
    "Select company": "Sélectionnez une entreprise",
    "Select Device": "Choisir un appareil",
    "Select Command": "Sélectionnez la commande",
    "Select Directory": "Sélectionnez le répertoire",
    "No response from server": "Aucune réponse du serveur",
    "missing machine name or email": "nom de machine ou e-mail manquant",
    Unauthorized: "Non autorisé",
    "Unable to create a Company. Please try again in sometime":
      "Impossible de créer une société. Veuillez réessayer dans quelques temps",
    "Company Name cannot be empty": "Le nom de l'entreprise ne peut pas être vide",
    "Creating Company": "Création d'entreprise",
    "Create Company": "Créer une entreprise",
    "Admin Email": "E-mail de l'administrateur",
    License: "Licence",
    "Fields cannot be empty": "Les champs ne peuvent pas être vides",
    enterLicenseKeyError: "Veuillez saisir une clé de licence valide",
    "missing Tess name or email": "il manque le nom ou l'e-mail de Tess",
    "Creating Tess": "Créer Tess",
    "Create Tess": "Créer Tess",
    "Update Tess": "Mettre à jour Tess",
    "Updating Tess": "Mise à jour de Tess",
    "Select role": "Sélectionnez un rôle",
    "Updating User": "Mise à jour de l'utilisateur",
    "Update User": "Mettre à jour l'utilisateur",
    "Create User": "Créer un utilisateur",
    "Creating User": "Création d'un utilisateur",
    "Are you sure you want to delete this": "Etes-vous sûr de vouloir supprimer ceci",
    "Are you sure you want to delete this token": "Êtes-vous sûr de vouloir supprimer ce jeton ?",
    "Are you sure you want to delete this tag": "Êtes-vous sûr de vouloir supprimer cette balise ?",
    "Are you sure you want to delete this hall": "Êtes-vous sûr de vouloir supprimer cette salle ?",
    "Are you sure you want to delete this shift":
      "Êtes-vous sûr de vouloir supprimer cette équipe?",
    "Are you sure you want to delete this shift group":
      "Êtes-vous sûr de vouloir supprimer ce groupe d'équipes ?",
    "Team members": "Membres de l'équipe",
    "Next events": "Prochains événements",
    "Meeting with Marry": "Rencontre avec Marie",
    "Book Deposit Hall": "Salle de dépôt de livres",
    "Shipment Deal UK": "Offre d'expédition au Royaume-Uni",
    "Verify Dashboard Color Palette": "Vérifier la palette de couleurs du tableau de bord",
    Visitors: "Visiteuses",
    Action: "Action",
    "Anoter action": "Une autre action",
    "Something else here": "Quelque chose d'autre ici",
    favorite: "préférée",
    "Creative Tim": "Tim créatif",
    "for a better web": "pour un meilleur Web.",
    "About Us": "À propos de nous",
    Blog: "Blog",
    "Logging out": "Déconnecter",
    "Reset Password": "réinitialiser le mot de passe",
    "You will receive an e-mail in maximum 60 seconds":
      "Vous recevrez un e-mail dans 60 secondes maximum",
    reset: "réinitialiser",
    "name and password cannot be empty":
      "Le nom d'utilisateur ou le mot de passe ne peut être vide",
    "Enter your email and password to Sign In":
      "Entrez votre email et votre mot de passe pour vous connecter",
    "Select range": "Sélectionner une plage",
    Energy: "Énergie",
    "Idle time": "Temps d'inactivité",
    "Stopped time": "Temps arrêté",
    "Production time, idle time and stopped time vs days":
      "Temps de production, temps d'inactivité et temps d'arrêt par rapport aux jours",
    "Avg. OEE per machine": "Moy. OEE par machine",
    "Average OEE": "TRS moyen",
    "Total energy consumption and wastage of machines":
      "Consommation totale d'énergie et gaspillage des machines",
    "Generate report": "Générer un rapport",
    "Sales by Country": "Ventes par pays",
    refresh: "rafraîchir",
    Followers: "Suiveuses",
    Revenue: "Revenu",
    "Today's Users": "Les utilisateurs d'aujourd'hui",
    Bookings: "Réservations",
    upload: "télécharger",
    "Do you want to carry forward this choice for entire week?":
      "Souhaitez-vous reporter ce choix sur toute la semaine ?",
    "Week picker": "Sélecteur de semaine",
    Reports: "Rapports",
    "Plant Energy": "Énergie végétale",
    "AVA Details": "Détails AVA",
    Status: "Statut",
    Hostname: "Hostname",
    "Edge id": "Identifiant du bord",
    Pulse: "Impulsion",
    "Mobile apps": "Application mobile",
    "Desktop apps": "Applications de bureau",
    "1 V 1": "1 V 1",
    machineShifts: "Changements de machine",
    "All Shifts": "Tous les quarts de travail",
    "Regular Shift": "Quart régulier",
    "Early Shift": "Quart de travail anticipé",
    "Evening Shift": "Quart de soir",
    "Load More": "Charger plus",
    "live machine": "Machine vivante",
    "Regular Shift Group ": "Groupe de quarts réguliers ",
    "Dual Shift Group": "Groupe à double équipe",
    "Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).":
      "Bonjour, je m'appelle Alec Thompson, Décisions : si vous n'arrivez pas à décider, la réponse est non. S’il s’agit de deux chemins également difficiles, choisissez celui qui est le plus douloureux à court terme (éviter la douleur crée une illusion d’égalité).",
    Camera: "Caméra",
    settings: "paramètres",
    "loadRating": "Capacité de charge",
    "Update rating": "Mettre à jour la note",
    "Select monday shifts": "Sélectionnez les quarts de travail du lundi",
    "Select tuesday shifts": "Sélectionnez les quarts de travail du mardi",
    "Select wednesday shifts": "Sélectionnez les quarts de travail du mercredi",
    "Select thursday shifts": "Sélectionnez les quarts de travail du jeudi",
    "Select saturday shifts": "Sélectionnez les quarts de travail du samedi",
    "Select sunday shifts": "Sélectionnez les quarts de travail du dimanche",
    "Select friday shifts": "Sélectionnez les quarts de travail du vendredi",
    "machineRating": "Évaluation de la machine",
    "Updating shift group": "Mise à jour du groupe d'équipe",
    "updateShiftGroup": "Mettre à jour le groupe d'équipe",
    "Creating shift group": "Création d'un groupe d'équipe",
    "Create shift group": "Créer un groupe de travail",
    "Updating shift": "Mise à jour du quart de travail",
    "Update shift": "Mettre à jour le changement",
    "Creating shift": "Créer un changement",
    "Create shift": "Créer un quart de travail",
    "Break End time": "Heure de fin de pause",
    "Break Start time": "Heure de début de pause",
    "Shift End time": "Heure de fin de quart de travail",
    "Shift Start time": "Heure de début du quart de travail",
    description: "Description",
    "copyAccessKeyMessage":
      "Veuillez copier ce jeton. je ne pourrai plus le copier.",
    "Creating Access Key": "Création d'une clé d'accès",
    "Create Access Key": "Créer une clé d'accès",
    "Select Senser": "Sélectionnez le capteur",
    Sensors: "Capteurs",
    "Machine Page": "Page Machine",
    Details: "Détails",
    "Complete Step": "Étape terminée",
    Finish: "Finition",
    "Select Tess": "Sélectionnez Tess",
    "All steps completed - you're finished": "Toutes les étapes terminées - vous avez terminé",
    "selectMachine": "Sélectionnez un appareil",
    "selectAccessibleMachine": "Sélectionner les machines accessibles",
    "selectNotificationTypes": "Sélectionner les notifications",
    Cancel: "Annuler",
    "Select Mode": "Sélectionnez le mode",
    Hallplan: "Plan d'entrée",
    "Leader Board": "Tableau des leaders",
    Scheduler: "Planificateur",
    "Org Stats": "Statistiques de l'organisation",
    Dashboard: "Tableau de bord",
    Versus: "Contre",
    "Peak Optimizer": "Optimiseur de pic",
    create: "Créer",
    "Selected Pulse": "Impulsion sélectionnée",
    Yes: "Oui",
    No: "Non",
    "Energy wastage": "Gaspillage d'énergie",
    "consumption costs": "Coûts de consommation",
    energyPrice: "Prix de l'énergie",
    "wastage costs": "Coûts du gaspillage",
    "The OTP you entered is incorrect. Please try again.":
      "Le OTP que vous avez entré est incorrect. Veuillez réessayer.",
    "Trust this device for next 30 days":
      "Faites confiance à cet appareil pour les 30 prochains jours",
    "Authenticator Verification": "Authenticator Verification",
    "Enter the 6-Digit Verification Code": "Entrez le code de vérification à 6 chiffres",
    "Is Your Authenticator Not Configured?": "Votre authentificateur n'est-il pas configuré?",
    "Send Email": "Envoyer un E-mail",
    "Resend Email": "Renvoyer l'E-mail",
    "Back to Authenticator Verification": "Retour à Authenticator Verification",
    "Verify Account": "vérifier le compte",
    "addMachine": "Ajouter une machine",
    editMachine: "éditer la machine",
    "addHall": "Ajouter une salle",
    "updateHall": "salle de mise à jour",
    Halls: "Salles",
    "addTags": "Ajouter des tags",
    "updateTags": "Mettre à jour les tags",
    "addOperator": "Ajouter un opérateur",
    "updateOperator": "Mettre à jour l'opérateur",
    "addEnergyPrice": "Ajouter un prix de l'énergie",
    "updateEnergyPrice": "Mettre à jour le prix de l'énergie",
    "addTimelineReason": "Ajouter une raison de la chronologie",
    "updateTimelineReason": "Mettre à jour la raison de la chronologie",
    "addAccessKeys": "Ajouter des clés d'accès",
    "updateAccessKeys": "Mettre à jour les clés d'accès",
    "apiAccessKeys": "Clés d'accès API",
    "accessibleMachines": "Machines accessibles",
    "allMachineAccess": "Tous les accès aux machines",
    Tags: "Balises",
    "addOperator": "Ajouter un opérateur",
    Operators: "Opérateurs",
    "Energy Price": "Prix de l'énergie",
    "Timeline Reasons": "Motif de chronologies",
    Tokens: "Jetons",
    selectedTabs:
      "électionnez un onglet pour afficher des données détaillées. Chaque onglet présente des informations spécifiques relatives aux équipes de machines ou aux analyses journalières.",
    SelectResolution:
      "Sélectionnez la résolution pour ajuster la granularité des points de données d'impulsion affichés. Les options incluent des intervalles de 1 minute, 30 secondes, 5 secondes et 1 seconde.",
    SelectTabsOrg:
      "Sélectionnez un onglet pour afficher les statistiques organisationnelles sur différentes périodes. 1D présente les données du dernier jour, 1W de la dernière semaine et 1M du dernier mois.",
    chooseDay:
      "Sélectionnez 'Jour', 'Semaine' ou 'Mois' pour visualiser les données cumulées sur la période choisie dans des graphiques.",
    "Avg. OEE": "Moy. OEE",
    "Avg. Performance": "Moy. Performance",
    "Production duration": "Durée de production",
    "Idle Duration": "Durée d'inactivité",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
    "Average OEE and Performance for the Week": "OEE moyen et performance pour la semaine",
    "Total Production Time and Total Idle Time for the Week":
      "Temps de production total et temps d'inactivité total pour la semaine",
    "username / email": "Nom d'utilisateur ou Email",
    "Top OEE Machines": "Top machines OEE",
    "Top Performance Machines": "Top machines performance",
    "Top Energy Consumption": "Top consommation d'énergie",
    "Top Energy Wastage": "Top gaspillage d'énergie",
    "Top OEE Operators": "Top opérateurs OEE",
    "Top Performance Operators": "Top opérateurs performance",
    "OEE Rank": "Classement OEE",
    "Performance Rank": "Classement performance",
    "Load Less": "Charger moins",
    "Compared to last month": "Comparé au mois dernier {{dynamicValue}}",
    "All Day": "Toute la journée",
    Planned: "Planifié",
    "Toggle between 'All Day' for 24-hour data and 'Planned' for data during scheduled shifts":
      "Basculez entre 'Toute la journée' pour les données sur 24 heures et 'Planifié' pour les données pendant les quarts planifiés",
    "Total energy consumption and wastage": "Consommation et gaspillage d'énergie totale",
    "Total OEE, Availability and performance Analysis":
      "OEE total, disponibilité et analyse des performances",
    "Total production, total idle and total stopped per machine":
      "Production totale, total inactif et total arrêté par machine",
    "Production, total idle and total stopped": "Production, total inactif et total arrêté",
    "OEE, Availability and performance Analysis": "OEE, Disponibilité et Analyse des performances",
    "Energy Consumption and energy wastage Analysis":
      "Analyse de la consommation d'énergie et du gaspillage d'énergie",
    "OEE, Availability and performance Analysis vs days":
      "OEE, Disponibilité et Analyse des performances vs jours",
    "Energy consumption and energy wastage vs days":
      "Consommation d'énergie et gaspillage d'énergie par rapport aux jours",
    Week: "Semaine",
    Day: "Jour",
    "Daily OEE, Performance & Availability": "OEE, Performance et disponibilité quotidienne",
    "Time Analysis (Prod/Idle/Stop)": "Analyse du temps (Prod/Inactif/Arrêt)",
    "Daily Energy Consumption & Waste": "Consommation & Gaspi énergétique quotidien",
    "Avg. OEE, Performance & Availability by Machine":
      "OEE, Perf. et disponibilité moyenne par machine",
    "Production Times by Machine": "Temps de production par machine",
    "Energy Consumption & Waste by Machine": "Énergie utilisée & gaspillée par machine",
    "Co2_emissions In Tons Overtime": "Émissions de Co2 en tonnes au fil du temps",
    "Factory Area": "Zone d'usine",
    "Co2 emissions": "Émissions de Co2",
    "Output Waste Percentage": "Pourcentage de déchets en sortie",
    "Coolant Output (In Lts.)": "Sortie du liquide de refroidissement (en Lts.)",
    "Material Efficiency": "Efficacité matérielle",
    "Transport Efficiency": "Efficacité des transports",
    "Renewable Energy Percentage": "Pourcentage d'énergie renouvelable",
    "Sustainability Overview": "Aperçu de la durabilité",
    "Energy Overview": "Vue d'ensemble de l'énergie",
    "Time Overview": "Vue d'ensemble du temps",
    "Sustainability Check": "Vérification de la durabilité",
    "Total Idle time": "Temps d'inactivité total",
    "Total Production time": "Temps de production total",
    "Total Off time": "Temps d'arrêt total",
    "OEE, Availability, and Performance Analysis by Day/Shift":"Analyse de l'OEE, disponibilité et performance par jour/quart",
    "Total Production, Idle, and Stop Time by Day/Shift":"Temps total de production, d'inactivité et d'arrêt par jour/quart",
    "Energy Consumption and Waste Analysis by Day/Shift":"Analyse de la consommation et du gaspillage d'énergie par jour/quart",
    "timelineRules":"Règles de chronologie",
    "globalTimelineRules":"Règles de chronologie globale",
    "between":"entre",
    "and":"et",
    "Add new rule":"Ajouter une nouvelle règle",
    "offStateTimelineRules":"Règles de chronologie hors état",
    "idleStateTimelineRules":"Règles de chronologie d'inactivité",
    "preparationStateTimelineRules":"Règles de chronologie d'arrêt",
    "Update rules":"Mettre à jour les régles",
    "Please select timeline rule.":"Veuillez sélectionner une rôle de chronologie.",
    "Start time is required":"Le temps de démarrage est requis",
    "Start time should be greater then or equal 0":"Le temps de début doit être supérieur ou égal à 0",
    "End time is required":"L'heure de fin est requise",
    "End time should be greater then or equal 0":"L'heure de fin doit être supérieur ou égal à 0",
    "Start time can not overlap with other start time and end time":"Le temps de début ne peut pas être superposé avec un autre temps de demarrage et de fin",
    "noDataMessage": "Aucune donnée trouvée pour la semaine sélectionnée. Sélectionnez une autre semaine pour voir les analyses.",
    "Previous Week": "Semaine précédente",
    "Next Week": "Semaine suivante",
    cantChooseFutureWeek: "Impossible de choisir une semaine future",
    cantChooseFutureDate: "Impossible de choisir une date future",
    greenIcon: "Applique les règles à la chronologie de l'état de production.",
    yellowIcon: "Applique les règles à la chronologie de l'état d'inactivité.",
    redIcon: "Applique les règles à la chronologie de l'état hors fonction.",
    blueIcon: "Applique les règles à la chronologie de l'état de préparation.",
    greyIcon: "Applique les règles à la chronologie générale.",
    timelineRulesInfo:
      "Hiérarchie des règles : D'abord, les règles internes spécifiques à chaque raison de la chronologie sont vérifiées. Si celles-ci ne s'appliquent pas, la règle PAR DÉFAUT pour cette raison est utilisée. Si aucun défaut n'est disponible, les règles globales s'appliquent. Il n'est pas nécessaire de définir les Défauts et Globales sauf si nécessaire; ne configurez que les règles nécessaires.",
    defaultReason: "Raison par défaut",
    selectReason: "Choisir une raison",
    reason: "raison",
    fromDate: "Date de début",
    toDate: "Date de fin",
    timelineReasons: "Raisons de la chronologie",
    machineOperators: "Opérateurs de machines",
    validTill: "Valable jusqu'au",
    previousDay: "Jour précédent",
    nextDay: "Le jour suivant",
    selectOperator: "Sélectionner l'opérateur",
    machineModel: "Modèle de machine",
    Notifications: "Notifications",
    "All Notification": "Toutes les notifications",
    "Save Subscription": "Sauvegarder l'abonnement",
    Subscribing: "Souscription",
    Authenticator: "Authentificateur",
    "Please follow this guide for Two-factor authentication":
      "Veuillez suivre ce guide pour l'authentification a deux facteurs",
    "Subscription List": "Liste de souscription",
    "Subscribe to all": "Souscrire à tous",
    "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character":
      "Le mot de passe doit contenir au moins 8 caractères, dont une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial",
    "save user": "Sauvegarder l'utilisateur",
    "Edit Profile": "Modifier le profil",
    "Upload Logo": "Télécharger le logo",
    "Delete Logo": "Supprimer le logo",
    "co2 emissions": "émissions de CO2",
    SustainabilityCheckInfo:
      "Explorez comment divers facteurs de durabilité influencent votre impact environnemental. Chaque facteur, allant de la taille de l'installation à la source d'énergie, joue un rôle crucial dans la détermination de votre profil global de durabilité. Entrez des données précises dans les unités spécifiées pour évaluer les améliorations potentielles.",
    PlantAreaInfo:
      "Indique la superficie totale occupée par l'installation. Les grandes surfaces peuvent augmenter la consommation d'énergie et les émissions de CO2 en raison de demandes de maintenance et d'opération plus élevées. Exemple : Une zone de 5 000 mètres carrés pourrait consommer plus d'énergie pour le chauffage et la climatisation par rapport à un site compact de 1 000 mètres carrés.",
    WastePercentageInfo:
      "Représente la proportion de matières premières qui deviennent des déchets par rapport à la totalité des matières premières utilisées dans la production. Un pourcentage plus élevé indique une utilisation moins efficace des matériaux, contribuant davantage à la dégradation environnementale. Exemple : Si vous utilisez 1000 kg de matière première et que 100 kg deviennent des déchets, votre pourcentage de déchets est de 10%.",
    RenewablePercentageInfo:
      "Le pourcentage de l'énergie totale provenant de sources renouvelables. Augmenter ce pourcentage peut réduire considérablement vos émissions de carbone et améliorer la durabilité. Exemple : Si 50% de votre énergie provient de panneaux solaires, votre utilisation d'énergie renouvelable est de 50%.",
    CoolantOutputInfo:
      "Mesure la quantité totale de liquide de refroidissement utilisée par les systèmes. Des sorties plus élevées peuvent indiquer des inefficacités et une empreinte environnementale plus grande. Exemple : L'utilisation de 5 000 litres de liquide de refroidissement par mois suggère un potentiel d'optimisation des systèmes de refroidissement pour réduire l'impact environnemental.",
    TransportEfficiencyInfo:
      "Évalue l'efficacité avec laquelle les ressources de transport sont utilisées au sein de l'entreprise. Une plus grande efficacité signifie une consommation de carburant réduite et des émissions plus faibles. Exemple : Si votre logistique de transport vous permet de livrer deux fois plus de marchandises avec la même consommation de carburant, votre efficacité de transport est élevée.",
    MaterialEfficiencyInfo:
      "Reflète l'efficacité avec laquelle différents matériaux sont utilisés dans vos processus de production, en tenant compte des types de matériaux tels que l'acier de construction, l'acier inoxydable, l'aluminium ou le plastique. L'efficacité de chaque matériau influence l'utilisation globale des ressources et la durabilité. Exemple : Si l'utilisation de l'acier inoxydable permet de réduire les déchets et de fabriquer des produits plus durables que le plastique, vos pratiques en matière d'acier inoxydable sont plus efficaces sur le plan des matériaux.",
    "Plant OEE": "Usine de production OEE",
    "Plant energy": "Usine de production Énergie",
    "co2_emissions in tons": "Émissions de CO2 en tonnes",
    "Compared to machine rating": "Par rapport à la notation des machines",
    "Compared to machine rating value": "Par rapport à la notation des machines {value} kWh",
    "OEE compared to machine rating": "L'OEE comparé à la notation des machines",
    "OEE compared to machine rating value": "L'OEE comparé à la notation des machines {value} %",
    "Performance compared to machine rating": "Performances comparé à la notation des machines",
    "Performance compared to machine rating value":
      "Performances comparé à la notation des machines {value} %",
    "Configure Factory": "Configurer l'usine",
    "Configure Shifts and Scheduling": "Configurer les équipes et la planification",
    "Configure Analyzers": "Configurer les analyseurs",
    "Configure Products & Orders": "Configurer les produits et les Ordres",
    "Configure Security and API": "Configurer la sécurité et l'API",
    "forgotPasswordPrompt": "Veuillez entrer l'adresse e-mail associée à votre compte, et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    "Products": "Produits",
    "Orders": "Ordres",
    "Product": "Produit",
    "Order": "Ordre",
    "addProduct": "Ajouter un produit",
    "addOrder": "Ajouter un Ordre",
    "startTime": "Début prévu",
    "endTime": "Fin prévue",
    "External Product ID": "External Product ID",
    "External Order ID": "External Order ID",
    cyclesPerProduct: "Cycles par produit",
    "Updating Product": "Mise à jour du produit",
    "updateProduct": "Mise à jour du produit",
    "Creating Product": "Créer un produit ",
    "Create Product": "Créer un produit ",
    "Updating Order": "Mise à jour de l'ordre",
    "updateOrder": "Mise à jour de l'ordre",
    "Creating Order": "Créer un ordre",
    "Create Order": "Créer un ordre",
    SelectCyclesTooltip: "Vérifier les tendances des cycles vs les cycles identifiés",
    timeline: "Chronologie-Analyse",
    trend: "Trend-Analyse",
    trendAnalysisGraph: "Trend-Analyse",
    "Downtime Analysis": "Analyse des temps d'arrêt",
    "Toggle Focus Mode": "Basculer le « Focus Mode »",
    criticalLevels: "Niveaux critiques",
    critical: "Critique",
    high: "Élevé",
    medium: "Moyen",
    low: "Faible",
    "reduced downtime": "Temps d'arrêt réduits",
    "cost saving": "Réduction des coûts",
  }
};
