/* eslint-disable react/prop-types */
import {
  Box,
  FormControl,
  ListItemText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Checkbox,
  TextField
} from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { axiosPrivate } from "api/axios";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { createOrderApi, updateOrderApi, getMachineListApi } from "api/watchmenApi";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenOrderForm, setSuccessMsg, useMaterialUIController } from "context";
import translate from "i18n/translate";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getUpdatedKeysObject } from "utils/constants";
import { calenderDarkTheme } from "layouts/dashboards/machineShifts/rangepicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import colors from "assets/theme-dark/base/colors";
import { enumQueryNames } from "api/reactQueryConstant";
import { dotStream } from 'ldrs'

dotStream.register()

function AddOrder({ productList, machineList, setUpdateOrder, orderList, refetch }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openNewOrderForm } = controller;
  const [orderName, setOrderName] = useState("");
  const [extOrderId, setExtOrderId] = useState("");
  const [productID, setProductID] = useState("");
  const [machineId, setMachineId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedMachines, setSelectedMachines] = useState();
  const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [errMsg, setErrMsg] = useState();

  useEffect(() => {
    if (orderList) {
      setOrderName(orderList.name);
      setExtOrderId(orderList.ext_order_id);
      setProductID(orderList.product?.id || "");
      setMachineId(orderList.machine?.id || "");
      setEndDate(moment(orderList.planned_end));
      setStartDate(moment(orderList.planned_start));
      // Ensure the selected product and machine are set correctly
      setSelectedProduct(productList.find(product => product.id === orderList.product?.id));
      setSelectedMachines(machineList.find(machine => machine.id === orderList.machine?.id));
    }
  }, [orderList, productList, machineList]);

  const handleCloseProductForm = () => {
    setOpenOrderForm(dispatch, !openNewOrderForm);
    setOrderName("");
    setExtOrderId("");
    setProductID("");
    setMachineId("");
    setStartDate("");
    setEndDate("");
    setUpdateOrder(null);
  };

  const { mutate: createOrder, isLoading: createOrderLoading } = useMutation(
    (data) => createOrderApi(axiosPrivate, data),
    {
      onSuccess: ({ message }) => {
        refetch();
        setSuccessMsg(dispatch, message);
        handleCloseProductForm();
      }
    }
  );

  const { mutate: updateOrderDetails, isLoading: updateOrderLoading } = useMutation(
    ({ data }) => updateOrderApi(axiosPrivate, orderList?.id, data),
    {
      onSuccess: ({ message }) => {
        refetch();
        setSuccessMsg(dispatch, message);
        handleCloseProductForm();
      }
    }
  );
  const handleSubmit = async () => {
    if (
      // !selectedMachines ||
      // !selectedProduct ||
      startDate === "" ||
      selectedProduct === "" ||
      selectedMachines === "" ||
      endDate === "" ||
      orderName === ""
    ) {
      setErrMsg("Please fill all the required fields");
    } else if (orderList) {
      const order = {
        name: orderName,
        ext_order_id: extOrderId,
        product_id: selectedProduct?.id,
        machine_id: selectedMachines?.id,
        planned_end: moment(endDate).seconds(0).format("YYYY-MM-DD HH:mm:ss"),
        planned_start: moment(startDate).seconds(0).format("YYYY-MM-DD HH:mm:ss")
      };
      const compareObjectsTemp = getUpdatedKeysObject(
        { ...order, planned_start: undefined, planned_end: undefined },
        { ...orderList, planned_start: undefined, planned_end: undefined }
      );

      // Manually check if planned_start or planned_end have changed
      const originalStartFormatted = moment(orderList.start).seconds(0).format("YYYY-MM-DD HH:mm:ss");
      const newStartFormatted = moment(startDate).seconds(0).format("YYYY-MM-DD HH:mm:ss");
      const originalEndFormatted = moment(orderList.end).seconds(0).format("YYYY-MM-DD HH:mm:ss");
      const newEndFormatted = moment(endDate).seconds(0).format("YYYY-MM-DD HH:mm:ss");

      if (originalStartFormatted !== newStartFormatted) {
        compareObjectsTemp.planned_start = order.planned_start;
      }
      if (originalEndFormatted !== newEndFormatted) {
        compareObjectsTemp.planned_end = order.planned_end;
      }

      if (Object.keys(compareObjectsTemp).length !== 0) {
        updateOrderDetails({
          id: orderList.id,
          data: { ...compareObjectsTemp }
        });
      }
    } else {
      const order = {
        name: orderName,
        ext_order_id: extOrderId,
        product_id: selectedProduct?.id,
        machine_id: selectedMachines?.id,
        planned_end: moment(endDate).seconds(0).format("YYYY-MM-DD HH:mm:ss"),
        planned_start: moment(startDate).seconds(0).format("YYYY-MM-DD HH:mm:ss")
      };
      createOrder(order);
    }
  };
  return (
    <Modal
      open={openNewOrderForm}
      onClose={handleCloseProductForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={({ palette: { dark, white } }) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: darkMode ? "#0F141F" : "#eeeeee",
          border: "1px solid #000",
          borderRadius: "3%",
          boxShadow: 24,
          p: 4,
          color: darkMode ? white.main : dark.main,
          maxHeight: "90vh",
          overflow: "auto"
        })}
        className="customScroll"
      >
        <MDBox pt={0.5} pb={3} px={3} display="flex" flexDirection="column">
          <MDTypography
            variant="button"
            color="light"
            fontWeight="medium"
            textGradient
            textAlign="center"
            px={2}
            pb={4}
            fontSize="1.25rem"
          >
            {translate(orderList ? "updateOrder" : "addOrder")}
          </MDTypography>
          {errMsg && (
            <MDBox mb={2}>
              <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                {errMsg}
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2}>
            <FormControl
              sx={({ palette: { dark, white } }) => ({
                width: "100%",
                color: darkMode ? white.main : dark.main
              })}
            >
              <MDInput
                type="text"
                label={translate("Name")}
                variant="outlined"
                value={orderName}
                fullWidth
                onChange={(e) => {
                  setErrMsg("");
                  setOrderName(e.target.value);
                }}
                // onChange={(e) => {}}
              />
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <FormControl
              sx={({ palette: { dark, white } }) => ({
                width: "100%",
                color: darkMode ? white.main : dark.main
              })}
            >
              <MDInput
                type="text"
                label={translate("External Order ID")}
                variant="outlined"
                value={extOrderId}
                fullWidth
                onChange={(e) => {
                  setErrMsg("");
                  setExtOrderId(e.target.value);
                }}
                // onChange={(e) => {}}
              />
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <ThemeProvider theme={calenderDarkTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText="en">
                <DateTimePicker
                  label={translate("startTime")}
                  format="DD/MM/YYYY HH:mm"
                  value={startDate}
                  onChange={(date) => {
                    setErrMsg("");
                    setStartDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        svg: { color: "#ffffff" }
                      }}
                    />
                  )}
                  sx={{
                    width: "-webkit-fill-available"
                  }}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: `${colors.info.main} !important`
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </MDBox>
          <MDBox mb={2}>
            <ThemeProvider theme={calenderDarkTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText="en">
                <DateTimePicker
                  label={translate("endTime")}
                  format="DD/MM/YYYY HH:mm"
                  value={endDate}
                  onChange={(date) => {
                    setErrMsg("");
                    setEndDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        svg: { color: "#ffffff" }
                      }}
                    />
                  )}
                  sx={{
                    width: "-webkit-fill-available"
                  }}
                  PaperProps={{
                    sx: {
                      "& .Mui-selected": {
                        backgroundColor: `${colors.info.main} !important`
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </MDBox>

          <MDBox mb={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-product-label">Select Product</InputLabel>
              <Select
                labelId="select-product-label"
                id="select-product"
                value={selectedProduct?.id || ""}
                label="Select Product"
                sx={{
                  minHeight: "45px"
                }}
                onChange={(e) => {
                  setErrMsg("");
                  setSelectedProduct(productList.find(product => product.id === e.target.value));
                }}
              >
                {productList?.map((product) => (
                  <MenuItem value={product.id} key={product.id}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={product.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>

          <MDBox mb={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-machine-label">Select Machines</InputLabel>
              <Select
                labelId="select-machine-label"
                id="select-machine"
                value={selectedMachines?.id || ""}
                label="Select Machines"
                sx={{
                  minHeight: "45px"
                }}
                onChange={(e) => {
                  setErrMsg("");
                  setSelectedMachines(machineList.find(machine => machine.id === e.target.value));
                }}
              >
                {machineList?.map((machine) => (
                  <MenuItem value={machine.id} key={machine.id}>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={machine.name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2} display="flex" flexDirection="column">
            <MDButton
              color="dark"
              size="medium"
              variant={darkMode ? "contained" : "outlined"}
              onClick={handleSubmit}
              disabled={createOrderLoading || updateOrderLoading} // Disable button while loading
            >
              {(createOrderLoading || updateOrderLoading) ? (
                <l-dot-stream
                  size="60"
                  speed="2.5"
                  color={darkMode ? "white" : "black"} // Adjust color based on theme
                ></l-dot-stream>
              ) : (
                translate(orderList ? "update" : "create")
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
}

export default AddOrder;
