
import moment from "moment";
import DatasetIcon from "@mui/icons-material/Dataset";
import {
  useMediaQuery,
  useTheme
} from "@mui/material";
import { apiUrls } from "api/reactQueryConstant";
import DefaultCell from "components/DefaultCell";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import AddAVACompany from "components/modal/addAVACompany";
import {
  setOpenNewUserForm,
  useMaterialUIController
} from "context";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import translate from "i18n/translate";
import { useEffect, useState } from "react";
import NewAva from "../company/modals/NewAva";
import NewCompany from "../company/modals/NewCompany";
import NewTess from "../company/modals/NewTess";
import NewUser from "../company/modals/NewUser";
import { getAvaTelemetryApi, getMachineTelemetryApi } from "api/watchmenApi";

function Monitor() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
    openNewCompanyForm,
    openNewAvaForm,
    openNewTessForm,
    openNewUserForm,
    openAddCompanyForm
  } = controller;

  const { axiosPrivate } = useAxiosPrivate();
  const [companyData, setCompanyData] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [AVACompany, setAVACompany] = useState(null);
  const [updateUser, setUpdateUser] = useState(null);
  const [updateAva, setUpdateAva] = useState(null);
  const [updateTess, setUpdateTess] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [userDeleteConfirm, setUserDeleteConfirm] = useState(null);
  const [avaUnassignedDeleteConfirm, setAvaUnassignedDeleteConfirm] = useState(null);
  const [avaDeleteConfirm, setAvaDeleteConfirm] = useState(null);
  const [avaCompanyDeleteConfirm, setAvaCompanyDeleteConfirm] = useState(null);
  const [successSB, setSuccessSB] = useState(null);
  const [isLicenseShow, setLicenseShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [machineTelemetry, setMachineTelemetry] = useState([]);
  const [avaTelemetry, setAvaTelemetry] = useState(null);
  const [avaDetails, setAvaDetails] = useState(null);
  const [avaId, setAvaId] = useState(null);
  const [errMsg, setErrMsg] = useState("");


  useEffect(() => {
    let isMounted = true;
    const contro = new AbortController();
    const data = async () => {
      try {
        const response = await axiosPrivate.get(apiUrls.getSuperAdminDetails, {
          signal: contro.signal
        });
        if (isMounted) {
          setIsLoading(false);
          setCompanyData(response.data);
          console.log(response.data);
          if (currentCompany) {
            setCurrentCompany(
              response.data.company_list.find((company) => company.id === currentCompany.id)
            );
          } else {
            setCurrentCompany(response.data.company_list[0]);
          }
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
      try {
        const responseAva = await axiosPrivate.get(apiUrls.getSuperAdminAVADetails);
        const payload1 = {
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().add(1, "d").format("YYYY-MM-DD")
        };
        const responseMachineTelemetry = await getMachineTelemetryApi(axiosPrivate);
        setMachineTelemetry(responseMachineTelemetry.machine_list);
        setAvaDetails(responseMachineTelemetry.machine_list)
        if (responseAva.data.status === 1) {
          setIsLoading(false);
          setAVACompany(responseAva.data.ava_list);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    data();
    return () => {
      isMounted = false;
      contro.abort();
    };
  }, [refetch]); 

  const getAvaTelemetryHandler = async (id) => {
    const payload = {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().add(1, "d").format("YYYY-MM-DD")
    };
    try {
      const responseTelemetry = await getAvaTelemetryApi(axiosPrivate, id, payload);
      setAvaTelemetry(responseTelemetry.telemetry);
      setAvaId(responseTelemetry.ava_id);
      
  } catch (err) {
    console.log("Ava Details Not Found");
  }
    
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MDBox mb={1} mt={3} ml={2}>
            <MDTypography variant="h5" fontWeight="medium">
            {translate("Machine Details")}
            </MDTypography>
          </MDBox>
          {machineTelemetry && (
            <DataTable
              table={{
                columns: [
                  {
                    Header: "name",
                    accessor: "name",
                    canSearch:true,
                    Cell: ({ value }) => <DefaultCell value={value} />
                  },
                  {
                    Header: "last_pulse",
                    accessor: "last_pulse",                    
                    Cell: ({ value }) => <DefaultCell  sx={{ border: 1 }} value={value} />
                  },
                  {
                    Header: "last_timeline_data",
                    accessor: "last_timeline_data",
                    Cell: ({ value }) => <DefaultCell value={value} />
                  },
                  {
                    Header: "",
                    id: "delete_icon",
                    accessor: "ava.id",
                    align: "right",
                    width: "50px",
                    isSortedColumn: false,
                    Cell: ({ value }) => (
                      <>
                        <MDBox sx={{ marginRight: "10px"}} textAlign="start">                       
                          {avaTelemetry != null && avaId == value && (
                            <MDTypography
                              variant="button"
                              color="white"
                              fontWeight="medium"
                            >
                              {translate("Ava Details")} 
                            </MDTypography>
                          )}
                          {avaId != value &&  (
                            <DatasetIcon
                              sx={{ marginRight: "10px !important" }}
                              onClick={() => getAvaTelemetryHandler(value)}
                            />
                          )}
                          <MDBox>
                            {avaTelemetry != null && avaId == value && (
                              <MDTypography
                                variant="button"
                                color="white"
                                fontWeight="medium"
                              >
                              {translate("System Load :")}{avaTelemetry != null ? avaTelemetry.system_load : ""}
                              </MDTypography>
                            )}
                          </MDBox>
                          <MDBox>
                            {avaTelemetry != null && avaId == value && (
                              <MDTypography
                                variant="button"
                                color="white"
                                fontWeight="medium"
                              >
                                {translate("System Uptime :")}
                                {avaTelemetry != null ? avaTelemetry.system_uptime : ""}
                              </MDTypography>
                            )}
                          </MDBox>
                          <MDBox>
                            {avaTelemetry != null && avaId == value && (
                              <MDTypography
                                variant="button"
                                color="white"
                                fontWeight="medium"
                              >
                                {translate("Total Memory :")} 
                                {avaTelemetry != null ? avaTelemetry.total_memory : ""}
                              </MDTypography>
                            )}
                          </MDBox>
                          <MDBox>
                            {avaTelemetry != null && avaId == value && (
                              <MDTypography
                                variant="button"
                                color="white"
                                fontWeight="medium"
                              >
                               {translate("N Cpus")}  {avaTelemetry != null ? avaTelemetry.n_cpus : ""}
                              </MDTypography>
                            )}
                          </MDBox>
                          <MDBox>
                            {avaTelemetry != null && avaId == value && (
                              <MDBox>
                                  <MDTypography
                                    variant="button"
                                    color="white"
                                    fontWeight="medium"
                                  >
                                 {translate("Last Heartbeat :")} 
                                {avaTelemetry != null ? avaTelemetry.last_heartbeat : ""}
                                </MDTypography>
                              </MDBox>
                            )}
                          </MDBox>
                        </MDBox>
                      </>
                    )
                  }
                ],
                rows: machineTelemetry.sort((a, b) => a.id - b.id)
              }}
              entriesPerPage={false}
              canSearch={true}
              showTotalEntries={false}
            />
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default Monitor;
