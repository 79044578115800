/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import MDCard from "components/MDCard";
import MDTypography from "components/MDTypography";
import ReactEcharts from "echarts-for-react";
import translate from "i18n/translate";
import colors from "assets/theme-dark/base/colors";
import { formateSecondToMins } from "utils";

function CandleStickReasonChart({ machineData }) {
  const { StoppedTime, IdleTime, RunTime } = colors;

  const combineArrays = (arrays, convertToMins = false) => {
    if (!arrays || arrays.length === 0) return [];
  
    return arrays[0].map((_, i) => {
      return arrays[0][i].map((_, j) => {
        const sum = arrays.reduce((acc, arr) => acc + parseInt(arr[i][j], 10), 0);
        return convertToMins ? formateSecondToMins(sum / arrays.length) : sum / arrays.length;
      });
    });
  };

  const areAllValuesZero = (data) => {
    return data.every(row => row.every(value => value === 0));
  };

  const dataCycles = combineArrays(
    Object.values(machineData?.data)?.map((item) =>
      Object.values(item)?.map((item1) => item1?.cycles)
    )
  );
  // console.log('dataCycles:', dataCycles);
  
  const isDataCyclesAllZero = areAllValuesZero(dataCycles);
  
  const dataIdle = combineArrays(
    Object.values(machineData?.data)?.map((item) =>
      Object.values(item)?.map((item1) => item1?.idle)
    ),
    true
  );
  // console.log('dataIdle:', dataIdle);

  const dataProduction = combineArrays(
    Object.values(machineData?.data)?.map((item) =>
      Object.values(item)?.map((item1) => item1?.production)
    ),
    true
  );
  // console.log('dataProduction:', dataProduction);

  const dataOff = combineArrays(
    Object.values(machineData?.data)?.map((item) =>
      Object.values(item)?.map((item1) => item1?.off)
    ),
    true
  );
  // console.log('dataOff:', dataOff);

  const prepareBoxplotData = (data) => {
    return data.map(row => row.slice(0, 5)); // Remove the last element (average value)
  };

  const options = {
    dataset: [
      {
        source: prepareBoxplotData(dataIdle)
      },
      {
        source: prepareBoxplotData(dataOff)
      },
      {
        source: prepareBoxplotData(dataProduction)
      },
      {
        source: prepareBoxplotData(dataCycles)
      },
      // {
      //   fromDatasetIndex: 0,
      //   transform: { type: 'boxplot' }
      // },
      // {
      //   fromDatasetIndex: 1,
      //   transform: { type: 'boxplot' }
      // },
      // {
      //   fromDatasetIndex: 2,
      //   transform: { type: 'boxplot' }
      // },
      // {
      //   fromDatasetIndex: 3,
      //   transform: { type: 'boxplot' }
      // },
    ],
    legend: [
      {
        data: ["Production", "Idle", "Stopped"],
        selected: {
          Stopped: false,
          Idle: false,
          Production: true
        },
        top: "1%",
        textStyle: {
          color: "#FFF"
        }
      },
      ...(isDataCyclesAllZero ? [] : [{
        data: ["Cycles"],
        top: "53%",
        textStyle: {
          color: "#FFF"
        }
      }])
    ],
    tooltip: {
      trigger: "item"
    },
    grid: [
      {
        height: 400,
        show: false,
        left: "7%",
        right: "3%",
        bottom: 10,
        top: "10%",
        tooltip: {
          show: true,
          trigger: "item"
        }
      },
      ...(isDataCyclesAllZero ? [] : [{
        height: 300,
        show: false,
        top: "60%",
        left: "4%",
        right: "3%",
        bottom: 80,
        tooltip: {
          show: true,
          trigger: "axis"
        },
        position(pt) {
          return [pt[0], "50%"];
        }
      }])
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        axisLabel: {
          color: "#ffffffcc",
          formatter: function (value) {
            return value + ":00";
          }
        },
        gridIndex: 0,
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      ...(isDataCyclesAllZero ? [] : [{
        type: "category",
        boundaryGap: true,
        axisLabel: {
          color: "#ffffffcc",
          formatter: function (value) {
            return value + ":00";
          }
        },
        gridIndex: 1,
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        }
      }])
    ],
    yAxis: [
      {
        type: "value",
        // name: "mins",
        splitArea: {
          show: false
        },
        gridIndex: 0,
        axisLabel: {
          formatter(val) {
            return `${val} mins`;
          },
          color: "#ffffffcc"
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#CCC"],
            width: 0.2
          }
        }
      },
      ...(isDataCyclesAllZero ? [] : [{
        type: "value",
        // name: "value",
        splitArea: {
          show: false
        },
        axisLabel: {
          formatter(val) {
            return `${val}`;
          },
          color: "#ffffffcc"
        },
        gridIndex: 1,
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#CCC"],
            width: 0.2
          }
        }
      }])
    ],
    dataZoom: [
      {
        xAxisIndex: [0, 1],
        type: "slider",
        top: "95%",
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: "Production",
        type: "boxplot",
        // datasetIndex: 2,
        data: prepareBoxplotData(dataProduction),
        colorByPoint: true,
        itemStyle: {
          color: "rgb(76, 175, 80, 0.2)",
          borderColor: RunTime.main // change this to the color you want
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        tooltip: {
          formatter(param) {
            return `${param?.seriesName}</br>
            ${param?.marker} min - ${param?.data?.[1]} mins</br>${
              param?.marker
            } Q1 - ${param?.data?.[2]} mins
           </br>${param?.marker} median - ${param?.data?.[3]} mins
            </br>${param?.marker} Q3 - ${param?.data?.[4]} mins
            </br>${param?.marker} max - ${param?.data?.[5]} mins`;
          }
        }
      },
      {
        name: "Idle",
        type: "boxplot",
        // datasetIndex: 0,
        data: prepareBoxplotData(dataIdle),
        colorByPoint: true,
        itemStyle: {
          color: "rgb(252, 219, 3, 0.2)",
          borderColor: IdleTime.main // change this to the color you want
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        tooltip: {
          formatter(param) {
            return `${param?.seriesName}</br>
            ${param?.marker} min - ${param?.data?.[1]} mins</br>${
              param?.marker
            } Q1 - ${param?.data?.[2]} mins
           </br>${param?.marker} median - ${param?.data?.[3]} mins
            </br>${param?.marker} Q3 - ${param?.data?.[4]} mins
            </br>${param?.marker} max - ${param?.data?.[5]} mins`;
          }
        }
      },
      {
        name: "Stopped",
        type: "boxplot",
        // datasetIndex: 1,
        data: prepareBoxplotData(dataOff),
        colorByPoint: true,
        itemStyle: {
          color: "rgb(244, 67, 53, 0.2)",
          borderColor: StoppedTime.main // change this to the color you want
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        tooltip: {
          formatter(param) {
            return `${param?.seriesName}</br>
            ${param?.marker} min - ${param?.data?.[1]} mins</br>${
              param?.marker
            } Q1 - ${param?.data?.[2]} mins
           </br>${param?.marker} median - ${param?.data?.[3]} mins
            </br>${param?.marker} Q3 - ${param?.data?.[4]} mins
            </br>${param?.marker} max - ${param?.data?.[5]} mins`;
          }
        }
      },
      ...(isDataCyclesAllZero ? [] : [{
        name: "Cycles",
        type: "boxplot",
        // datasetIndex: 3,
        data: prepareBoxplotData(dataCycles),
        xAxisIndex: 1,
        yAxisIndex: 1,
        colorByPoint: true,
        itemStyle: {
          color: "rgb(255, 167, 38, 0.2)",
          borderColor: "#FFA726"
        },
        tooltip: {
          formatter(param) {
            return `${param?.seriesName}</br>
            ${param?.marker} Cycles - ${param?.data?.[1]}</br>`;
          }
        }
      }])
    ]
  };
  return (
    <MDBox>
      <MDCard sx={{ margin: "10px 0", padding: "10px", overflow: "auto" }}>
        <MDTypography sx={{ textAlign: "center" }}>{translate("trendAnalysisGraph")}</MDTypography>
        <ReactEcharts
          option={options}
          style={{
            overflow: "auto",
            minHeight: isDataCyclesAllZero ? "525px" : "1000px",
            minWidth: "676px",
          }}
        />
      </MDCard>
    </MDBox>
  );
}
export default CandleStickReasonChart;
