import { GET_SEARCH_REPORTS, ADD_SEARCH_REPORTS, DELETE_SEARCH_REPORTS } from "../constants";

const initialState = {
  reportsSearch: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_REPORTS:
      return {
        ...state,
        reportsSearch: state.reportsSearch
      };
    case ADD_SEARCH_REPORTS:
      return {
        ...state,
        reportsSearch: action.payload.search
      };
    case DELETE_SEARCH_REPORTS:
      return {
        ...state,
        reportsSearch: {}
      };
    default:
      return state;
  }
};
