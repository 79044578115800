export const Timezones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu"
]

export const CurrencyOptions = [
  { label: "Afghan Afghani - ؋", value: "AFN", symbol: "؋" },
  { label: "Albanian Lek - Lek", value: "ALL", symbol: "Lek" },
  { label: "Algerian Dinar - دج", value: "DZD", symbol: "دج" },
  { label: "Angolan Kwanza - Kz", value: "AOA", symbol: "Kz" },
  { label: "Argentine Peso - $", value: "ARS", symbol: "$" },
  { label: "Armenian Dram - ֏", value: "AMD", symbol: "֏" },
  { label: "Aruban Florin - ƒ", value: "AWG", symbol: "ƒ" },
  { label: "Australian Dollar - $", value: "AUD", symbol: "$" },
  { label: "Azerbaijani Manat - m", value: "AZN", symbol: "m" },
  { label: "Bahamian Dollar - B$", value: "BSD", symbol: "B$" },
  { label: "Bahraini Dinar - .د.ب", value: "BHD", symbol: ".د.ب" },
  { label: "Bangladeshi Taka - ৳", value: "BDT", symbol: "৳" },
  { label: "Barbadian Dollar - Bds$", value: "BBD", symbol: "Bds$" },
  { label: "Belarusian Ruble - Br", value: "BYR", symbol: "Br" },
  { label: "Belize Dollar - BZ$", value: "BZD", symbol: "BZ$" },
  { label: "Bermudian Dollar - BMD$", value: "BMD", symbol: "BMD$" },
  { label: "Bhutanese Ngultrum - Nu.", value: "BTN", symbol: "Nu." },
  { label: "Botswana Pula - P", value: "BWP", symbol: "P" },
  { label: "Brazilian Real - R$", value: "BRL", symbol: "R$" },
  { label: "Brunei Dollar - BND$", value: "BND", symbol: "BND$" },
  { label: "Bulgarian Lev - лв", value: "BGN", symbol: "лв" },
  { label: "Burundi Franc - FRN", value: "BIF", symbol: "FRN" },
  { label: "Cambodian Riel - ៛", value: "KHR", symbol: "៛" },
  { label: "Canadian Dollar - C$", value: "CAD", symbol: "C$" },
  { label: "Cape Verde Escudo - Esc", value: "CVE", symbol: "Esc" },
  { label: "Cayman Islands Dollar - KY$", value: "KYD", symbol: "KY$" },
  { label: "Chilean Peso - $", value: "CLP", symbol: "$" },
  { label: "Colombian Peso - $", value: "COP", symbol: "$" },
  { label: "Costa Rican Colon - $", value: "CRC", symbol: "$" },
  { label: "Croatian Kuna - kn", value: "HRK", symbol: "kn" },
  { label: "Cuban Peso - $", value: "CUP", symbol: "$" },
  { label: "Czech Koruna - Kc", value: "CZK", symbol: "Kc" },
  { label: "Danish Krone - kr", value: "DKK", symbol: "kr" },
  { label: "Djiboutian Franc - FR", value: "DJF", symbol: "FR" },
  { label: "Dominican Peso - $", value: "DOP", symbol: "$" },
  { label: "East Caribbean Dollar - EC$", value: "XCD", symbol: "EC$" },
  { label: "Egyptian Pound - ج.م", value: "EGP", symbol: "ج.م" },
  { label: "Eritrean Nakfa - Nfk", value: "ERN", symbol: "Nfk" },
  { label: "Ethiopian Birr - ₣", value: "ETB", symbol: "₣" },
  { label: "Euro - €", value: "EUR", symbol: "€" },
  { label: "Fijian Dollar - FJ$", value: "FJD", symbol: "FJ$" },
  { label: "Gambian Dalasi - GMD", value: "GMD", symbol: "GMD" },
  { label: "Georgian Lari - ლ", value: "GEL", symbol: "ლ" },
  { label: "Ghanaian Cedi - GH₵", value: "GHS", symbol: "GH₵" },
  { label: "Guatemalan Quetzal - Q", value: "GTQ", symbol: "Q" },
  { label: "Guyanese Dollar - GY$", value: "GYD", symbol: "GY$" },
  { label: "Haitian Gourde - G", value: "HTG", symbol: "G" },
  { label: "Honduran Lempira - L", value: "HNL", symbol: "L" },
  { label: "Hong Kong Dollar - HK$", value: "HKD", symbol: "HK$" },
  { label: "Hungarian Forint - Ft", value: "HUF", symbol: "Ft" },
  { label: "Icelandic Krona - kr", value: "ISK", symbol: "kr" },
  { label: "Indian Rupee - ₹", value: "INR", symbol: "₹" },
  { label: "Indonesian Rupiah - Rp", value: "IDR", symbol: "Rp" },
  { label: "Iranian Rial - ﷼", value: "IRR", symbol: "﷼" },
  { label: "Iraqi Dinar - د.ع", value: "IQD", symbol: "د.ع" },
  { label: "Israeli New Sheqel - ₪", value: "ILS", symbol: "₪" },
  { label: "Italian Lira - L,£", value: "ITL", symbol: "L,£" },
  { label: "Jamaican Dollar - J$", value: "JMD", symbol: "J$" },
  { label: "Japanese Yen - ¥", value: "JPY", symbol: "¥" },
  { label: "Jordanian Dinar - ا.د", value: "JOD", symbol: "ا.د" },
  { label: "Kazakhstani Tenge - лв", value: "KZT", symbol: "лв" },
  { label: "Kenyan Shilling - KSh", value: "KES", symbol: "KSh" },
  { label: "Kuwaiti Dinar - ك.د", value: "KWD", symbol: "ك.د" },
  { label: "Kyrgyzstani Som - с", value: "KGS", symbol: "с" },
  { label: "Lao Kip - ₭", value: "LAK", symbol: "₭" },
  { label: "Latvian Lats - Ls", value: "LVL", symbol: "Ls" },
  { label: "Lebanese Pound - £", value: "LBP", symbol: "£" },
  { label: "Lesotho Loti - L", value: "LSL", symbol: "L" },
  { label: "Liberian Dollar - $", value: "LRD", symbol: "$" },
  { label: "Libyan Dinar - د.ل", value: "LYD", symbol: "د.ل" },
  { label: "Lithuanian Litas - Lt", value: "LTL", symbol: "Lt" },
  { label: "Macanese Pataca - $", value: "MOP", symbol: "$" },
  { label: "Macedonian Denar - ден", value: "MKD", symbol: "ден" },
  { label: "Malagasy Ariary - Ar", value: "MGA", symbol: "Ar" },
  { label: "Malawian Kwacha - MK", value: "MWK", symbol: "MK" },
  { label: "Malaysian Ringgit - RM", value: "MYR", symbol: "RM" },
  { label: "Mauritanian Ouguiya - MRU", value: "MRO", symbol: "MRU" },
  { label: "Mauritian Rupee - ₮", value: "MUR", symbol: "₮" },
  { label: "Mexican Peso - $", value: "MXN", symbol: "$" },
  { label: "Moldovan Leu - L", value: "MDL", symbol: "L" },
  { label: "Mongolian Tugrik - ₮", value: "MNT", symbol: "₮" },
  { label: "Moroccan Dirham - MAD", value: "MAD", symbol: "MAD" },
  { label: "Mozambican Metical - MT", value: "MZM", symbol: "MT" },
  { label: "Myanmar Kyat - K", value: "MMK", symbol: "K" },
  { label: "Namibian Dollar - $", value: "NAD", symbol: "$" },
  { label: "Nepalese Rupee - ₮", value: "NPR", symbol: "₮" },
  { label: "New Zealand Dollar - NZ$", value: "NZD", symbol: "NZ$" },
  { label: "Nicaraguan Cordoba - C$", value: "NIO", symbol: "C$" },
  { label: "Nigerian Naira - ₦", value: "NGN", symbol: "₦" },
  { label: "North Korean Won - ₩", value: "KPW", symbol: "₩" },
  { label: "Norwegian Krone - kr", value: "NOK", symbol: "kr" },
  { label: "Omani Rial - ر.ع", value: "OMR", symbol: "ر.ع" },
  { label: "Pakistani Rupee - Rs", value: "PKR", symbol: "Rs" },
  { label: "Panamanian Balboa - B/.", value: "PAB", symbol: "B/." },
  { label: "Papua New Guinean Kina - K", value: "PGK", symbol: "K" },
  { label: "Paraguayan Guarani - ₭", value: "PYG", symbol: "₲" },
  { label: "Peruvian Nuevo Sol - S/.", value: "PEN", symbol: "S/." },
  { label: "Philippine Peso - ₱", value: "PHP", symbol: "₱" },
  { label: "Polish Zloty - zł", value: "PLN", symbol: "zł" },
  { label: "Qatari Rial - ر.ق", value: "QAR", symbol: "ر.ق" },
  { label: "Romanian Leu - lei", value: "RON", symbol: "lei" },
  { label: "Russian Ruble - ₽", value: "RUB", symbol: "₽" },
  { label: "Rwandan Franc - FRw", value: "RWF", symbol: "FRw" },
  { label: "Salvadoran Colon - $", value: "SVC", symbol: "$" },
  { label: "Samoan Tala - T", value: "WST", symbol: "T" },
  { label: "Saudi Riyal - ر.س", value: "SAR", symbol: "ر.س" },
  { label: "Serbian Dinar - дин", value: "RSD", symbol: "дин" },
  { label: "Seychellois Rupee - ₮", value: "SCR", symbol: "₮" },
  { label: "Sierra Leonean Leone - Le", value: "SLL", symbol: "Le" },
  { label: "Singapore Dollar - $", value: "SGD", symbol: "$" },
  { label: "Slovak Koruna - Kc", value: "SKK", symbol: "Kc" },
  { label: "Solomon Islands Dollar - $", value: "SBD", symbol: "$" },
  { label: "Somali Shilling - Sh.so.", value: "SOS", symbol: "Sh.so." },
  { label: "South African Rand - R", value: "ZAR", symbol: "R" },
  { label: "South Korean Won - ₩", value: "KRW", symbol: "₩" },
  { label: "South Sudanese Pound - £", value: "SSP", symbol: "£" },
  { label: "Sri Lankan Rupee - රු", value: "LKR", symbol: "රු" },
  { label: "Sudanese Pound - £", value: "SDG", symbol: "£" },
  { label: "Surinamese Dollar - SR$", value: "SRD", symbol: "SR$" },
  { label: "Swazi Lilangeni - L", value: "SZL", symbol: "L" },
  { label: "Swedish Krona - kr", value: "SEK", symbol: "kr" },
  { label: "Swiss Franc - CHF", value: "CHF", symbol: "CHF" },
  { label: "Syrian Pound - SR", value: "SYP", symbol: "SR" },
  { label: "Tajikistani Somoni - TJS", value: "TJS", symbol: "TJS" },
  { label: "Tanzanian Shilling - TZS", value: "TZS", symbol: "TZS" },
  { label: "Thai Baht - ฿", value: "THB", symbol: "฿" },
  { label: "Tongan Paʻanga - T$", value: "TOP", symbol: "T$" },
  { label: "Trinidad and Tobago Dollar - $", value: "TTD", symbol: "$" },
  { label: "Tunisian Dinar - TND", value: "TND", symbol: "TND" },
  { label: "Turkish Lira - ₺", value: "TRY", symbol: "₺" },
  { label: "Turkmenistan Manat - ₺", value: "TMT", symbol: "₺" },
  { label: "Ukrainian Hryvnia - ₴", value: "UAH", symbol: "₴" },
  { label: "UAE Dirham - د.إ", value: "AED", symbol: "د.إ" },
  { label: "US Dollar - $", value: "USD", symbol: "$" },
  { label: "Vanuatu Vatu - VT", value: "VUV", symbol: "VT" },
  { label: "Venezuelan BolÃ­var - Bs", value: "VEF", symbol: "Bs" },
  { label: "Vietnamese Dong - ₫", value: "VND", symbol: "₫" },
  { label: "Yemeni Rial - ﷼", value: "YER", symbol: "﷼" },
  { label: "Zambian Kwacha - ZK", value: "ZMK", symbol: "ZK" }
]
