/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Chip, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { enumQueryNames } from "api/reactQueryConstant";
import { getMachineListApi, getTrendAnalysis } from "api/watchmenApi";
import CandleStickReasonChart from "components/CandleStickReasonChart/CandleStickReasonChart";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import translate from "i18n/translate";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import RangePicker from "../machineShifts/rangepicker";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

export const useStyle = () => ({
  skeleton: {
    transform: "unset",
    margin: "1% 0"
  },
  leftArror: { marginRight: "12px", cursor: "pointer", color: "#FFFFFF" },
  rightArror: { marginLeft: "12px", cursor: "pointer", color: "#FFFFFF" }
});

function TrendAnalysis() {
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [trendAnalysisData, setTrendAnalysisData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { axiosPrivate } = useAxiosPrivate();
  const [successSB, setSuccessSB] = useState(null);
  const closeSuccessSB = () => setSuccessSB(null);
  const [popup, setPopup] = useState(null);

  const classes = useStyle();
  const { isFetching: treadMachinesDataFetching, refetch: refetchTrendAnalysis } = useQuery(
    [enumQueryNames.DASHBOARD_TREAD],
    () =>
      getTrendAnalysis(axiosPrivate, {
        machine_list: selectedMachines.map((machine) => +machine.id),
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        cycle_hourly: true,
        timeline_hourly: true
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        setTrendAnalysisData(data);
      }
    }
  );

  const applyFilterHandler = () => {
    if (selectedMachines.length > 0 && startDate !== null && endDate !== null) {
      refetchTrendAnalysis();
    } else if (selectedMachines.length === 0 && startDate === null && endDate === null) {
      setSuccessSB("Please select start date , end date and machine");
    } else if (selectedMachines.length === 0) {
      setSuccessSB("Please select machine.");
    } else if (startDate === null && endDate === null) {
      setSuccessSB("Please select start date and end date.");
    }
  };

  const { data: machines } = useQuery([enumQueryNames.MACHINE_LIST], () =>
    getMachineListApi(axiosPrivate)
  );

  const groupedMachines = machines?.reduce((acc, item) => {
    const hallName = item?.hall?.name || "--";
    if (!acc[hallName]) {
      acc[hallName] = [];
    }
    acc[hallName].push({
      hallId: item?.hall?.id || null,
      id: item?.id,
      name: item?.name
    });
    return acc;
  }, {});

  // Create the options array with unassigned machines first, then the rest grouped by hall
  const options = groupedMachines
    ? [
        ...(groupedMachines["--"]?.map((machine) => ({ ...machine, firstLetter: "--" })) || []),
        ...Object.keys(groupedMachines)
          .filter((hall) => hall !== "--")
          .flatMap((hall) =>
            groupedMachines[hall].map((machine) => ({ ...machine, firstLetter: hall }))
          )
      ]
    : [];

  const selectAllOption = { id: "selectAll", name: "Alle auswählen", firstLetter: "" };

  const handleSelectAllChange = (newValue) => {
    if (newValue.find((item) => item.id === "selectAll")) {
      if (selectedMachines.length !== options.length) {
        setSelectedMachines(options); // Select all machines
      } else {
        setSelectedMachines([]); // Deselect all machines
      }
    } else {
      const multipleItems = newValue.filter((item) => item.id === newValue[newValue.length - 1].id);
      if (multipleItems.length > 1) {
        setSelectedMachines(newValue.filter((item) => item.id !== multipleItems[0].id));
      } else {
        setSelectedMachines(newValue);
      }
    }
  };

  const isSelectAllChecked = selectedMachines.length === options.length;
  const isSelectAllIndeterminate =
    selectedMachines.length > 0 && selectedMachines.length < options.length;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container mb={3} spacing={2} alignItems="center">
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          pr={2}
          onMouseEnter={() => setPopup(1)}
          onMouseLeave={() => setPopup(null)}
        >
          <Autocomplete
            value={selectedMachines}
            multiple
            id="grouped-demo"
            options={[selectAllOption, ...(options || [])]}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.name}
            disableCloseOnSelect
            limitTags={2}
            renderTags={(value, getTagProps) =>
              selectedMachines.map((option, index) => (
                <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate("selectMachine")}
                style={{ zIndex: popup == 1 ? 0 : -1 }}
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <Typography style={{ color: "#FFFFFF" }}>{params.group}</Typography>
                {params.children}
              </li>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    option.id === "selectAll"
                      ? isSelectAllChecked
                      : selectedMachines.some((selOpt) => selOpt.id === option.id)
                  }
                />
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.stopPropagation();
                  }}
                >
                  {option.name}
                </span>
              </li>
            )}
            onChange={(event, newValue) => handleSelectAllChange(newValue)}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12} pr={2}>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <RangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              // rangeChange={(newValue) => {
              //   setStartDate(newValue?.startDate ?? null)
              //   setEndDate(newValue?.endDate ?? null)
              // }}
            />
          </MDBox>
        </Grid>
        {/* <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          pr={2}
          justifyContent="center"
          display="flex"
          alignItems="center"
          height="fit-content"
        >
          <MDTypography color="text" fontWeight="medium" fontSize="1rem">
            Per-shift
          </MDTypography>
          <Switch checked={isCumulative} onChange={() => setIsCumulative(!isCumulative)} />
          <MDTypography color="text" fontWeight="medium" fontSize="1rem">
            Per-day
          </MDTypography>
        </Grid> */}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          display="flex"
          height="fit-content"
        >
          <MDButton
            variant="gradient"
            color="info"
            onClick={applyFilterHandler}
            sx={{
              mx: 1
            }}
          >
            {translate("Generate report")}
          </MDButton>
        </Grid>
      </Grid>
      {treadMachinesDataFetching ? (
        <>
          <Skeleton height={200} width="100%" sx={classes.skeleton} />
          <Skeleton height={200} width="100%" sx={classes.skeleton} />
        </>
      ) : (
        trendAnalysisData && <CandleStickReasonChart machineData={trendAnalysisData} />
      )}
      <MDSnackbar
        color="error"
        icon="check"
        title="Error"
        content={successSB}
        open={!!successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    </DashboardLayout>
  );
}

export default TrendAnalysis;
