/* eslint-disable react/prop-types */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import { CardMedia, Grid, useMediaQuery, useTheme, Skeleton, Chip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDCard from "components/MDCard";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import LabeledProgress from "../labeledProgress";

export const useStyle = () => {
  const theme = useTheme();
  return {
    progressBox: {
      [theme.breakpoints.up("xl")]: {
        padding: "10px 0"
      }
    }
  };
};

function MachineInfo({
  name,
  model,
  description,
  tags,
  hall,
  health,
  onClick,
  state,
  machineStatusData
}) {
  const theme = useTheme();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedMachineStatusValue, setSelectedMachineStatusValue] = useState([]);
  
  const [loading, setLoading] = useState(true);

  // Set loading to false once data is loaded
  useEffect(() => {
    if (machineStatusData?.shift_data || machineStatusData?.day_data) {
      setLoading(false);
    }
  }, [machineStatusData]);

  const handleMouseOver = (item) => {
    setSelectedMachineStatusValue(item);
  };

  const handleMouseOut = () => {
    if (Object.keys(machineStatusData?.shift_data).length > 0) {
      let currentShiftIndex = Object.keys(machineStatusData?.shift_data || {}).length - 1;

      Object.values(machineStatusData?.shift_data || {}).map((data, index) => {
        const currentShift = Object.keys(machineStatusData.shift_data)[index];
        if (currentShift === machineStatusData?.live_shift) {
          currentShiftIndex = index;
        }
        return null;
      });
      setSelectedMachineStatusValue({
        data: Object.values(machineStatusData?.shift_data)?.[currentShiftIndex],
        index: currentShiftIndex
      });
    } else if (machineStatusData?.day_data) {
      setSelectedMachineStatusValue({
        data: machineStatusData?.day_data,
        index: 0
      });
    }
  };
  useEffect(() => {
    if (machineStatusData?.shift_data) {
      handleMouseOut();
    }
  }, [machineStatusData]);
  // eslint-disable-next-line no-unused-vars

  const machineShiftKey =
    Object.keys(machineStatusData?.shift_data || {}).length > 0
      ? Object.keys(machineStatusData?.shift_data || {})
      : ["day"];
  const machineStatusValue =
    Object.keys(machineStatusData?.shift_data || {}).length > 0
      ? Object.values(machineStatusData?.shift_data)
      : { day: { ...machineStatusData?.day_data } };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} width="100%" minHeight="250px">
      <MDBox mb={2} lineHeight={1} height="-webkit-fill-available">
        <Grid container spacing={2}>
          {machineStatusData?.meta_frontend?.uploads && !smDown ? (
            <Grid item xs={3} md={3} lg={3} flexDirection="row" display="flex">
              <MDBox
                component="img"
                src={`${process.env.REACT_APP_BASE_URL}v2/uploads${machineStatusData.meta_frontend.uploads}`}
                sx={{ width: "100%", objectFit: "cover", borderRadius: 1, height: 255 }}
              />
            </Grid>
          ) : null}
          <Grid item xs={smDown ? 12 : 9} md={smDown ? 12 : 9} lg={smDown ? 12 : 9}>
            <MDCard
              xs={12}
              sm={12}
              md={12}
              lg={12}
              onClick={onClick}
              sx={{
                pb: 2,
                pt: 2,
                border: 2,
                borderRadius: 1,
                borderColor:
                  state === 1
                    ? "yellow"
                    : state === 2
                    ? "green"
                    : state === 3
                    ? "blue"
                    : state === 4
                    ? "gray"
                    : "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "-webkit-fill-available"
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height="220px" />
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="start"
                  py={0}
                  style={{ cursor: "pointer" }}
                >
                  {smDown ? (
                    <>
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        xs={12}
                        md={4}
                        lg={4}
                        position="relative"
                      >
                        <MDBox lineHeight={1} className="MachineInfo">
                          {selectedMachineStatusValue && (
                            <LabeledProgress
                              value={health * 100}
                              count={health * 100}
                              machineStatusData={selectedMachineStatusValue.data}
                              height={240}
                              width={240}
                              fontSize="18px"
                              hollowSize="35%"
                              // label={selectedMachineStatusValue.index !== 0}
                            />
                          )}
                          {machineStatusData.meta_frontend.uploads ? (
                            <MDBox
                              component="img"
                              src={`${process.env.REACT_APP_BASE_URL}v2/uploads${machineStatusData.meta_frontend.uploads}`}
                              width="80%"
                              sx={{
                                position: "absolute",
                                height: "100%",
                                objectFit: "cover",
                                height: mdDown ? "90%" : "100%",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                opacity: "0.2",
                                // zIndex: "-1",
                                filter: "blur(1px)",
                                borderRadius: 1
                              }}
                            />
                          ) : null}
                        </MDBox>
                      </Grid>
                      <Grid display="flex" sx={{ width: "100%" }} xs={12}>
                        <Grid item xs={8} sx={{ px: "1rem" }} md={5} lg={5}>
                          <MDBox mb={0.5} lineHeight={1}>
                            <MDTypography
                              variant="h5"
                              fontWeight="medium"
                              color="text"
                              textTransform="capitalize"
                            >
                              {name}
                            </MDTypography>
                          </MDBox>
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mt={3}
                          >
                            <Grid item xs={10}>
                              {machineShiftKey?.map((item, index) => (
                                <MDBox
                                  variant="gradient"
                                  sx={({ palette: { background } }) => ({
                                    background: darkMode && background.card,
                                    p: "10px 15px",
                                    borderRadius: "10px",
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                  })}
                                >
                                  <MDTypography
                                    onMouseOver={() => {
                                      handleMouseOver(
                                        machineShiftKey[0] === "day"
                                          ? {
                                              data: machineStatusData?.day_data,
                                              index
                                            }
                                          : { data: machineStatusValue[index], index }
                                      );
                                    }}
                                    onMouseOut={handleMouseOut}
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {machineShiftKey?.length > 2 ? `${item.substring(0, 5)}...` : item}
                                  </MDTypography>
                                </MDBox>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={2} lg={2} sx={{ marginBottom: '1rem' }}>
                          {hall.id && (
                            <MDBox mb={0.5} lineHeight={1} key={hall.id}>
                              <Chip
                                label={hall.name}
                                sx={{
                                  background: "linear-gradient(90deg, rgb(31 50 59), rgb(40, 40, 40))",
                                  color: "white !important",
                                  borderRadius: "0.5rem",
                                  padding: "2px 5px",
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              />
                            </MDBox>
                          )}
                          {tags.map((tag) => (
                            <MDBox mb={0.5} lineHeight={1} key={tag.id}>
                              <Chip
                                label={tag.name}
                                sx={{
                                  background: `linear-gradient(195deg, #640101, rgb(51, 51, 51))`,
                                  color: "white !important",
                                  borderRadius: "0.5rem",
                                  padding: "2px 5px",
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              />
                            </MDBox>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        lg={5}
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                      >
                        {selectedMachineStatusValue && (
                          <LabeledProgress
                            value={health * 100}
                            count={health * 100}
                            machineStatusData={selectedMachineStatusValue.data}
                            height={240}
                            width={240}
                            fontSize="18px"
                            hollowSize="35%"
                            // label={selectedMachineStatusValue.index !== 0}
                          />
                        )}
                      </Grid>

                      <Grid container xs={7} ms={7} lg={7}>
                        <Grid item xs={6} md={6} lg={7}>
                          <MDBox mb={0.5} lineHeight={1}>
                            <MDTypography
                              fontWeight="medium"
                              variant="h5"
                              color="text"
                              textTransform="capitalize"
                            >
                              {name}
                            </MDTypography>
                          </MDBox>
                          
                          <Grid container justifyContent="flex-start" spacing={3}>
                            <Grid item display="flex" mt={2}>
                              {machineShiftKey.map((item, index) => (
                                <MDBox
                                  variant="gradient"
                                  sx={({ palette: { background } }) => ({
                                    background: background.card,
                                    border: `1px solid ${
                                      selectedMachineStatusValue.index === index
                                        ? state === 1
                                          ? "yellow"
                                          : state === 2
                                          ? "green"
                                          : state === 3
                                          ? "blue"
                                          : state === 4
                                          ? "gray"
                                          : "red"
                                        : "transparent"
                                    }`,
                                    p: "10px 15px",
                                    borderRadius: "10px",
                                    marginRight: "5px"
                                  })}
                                >
                                  <MDTypography
                                    onMouseOver={() =>
                                      handleMouseOver(
                                        machineShiftKey[0] === "day"
                                          ? {
                                              data: machineStatusData?.day_data,
                                              index
                                            }
                                          : { data: machineStatusValue[index], index }
                                      )
                                    }
                                    onMouseOut={handleMouseOut}
                                    variant="button"
                                    fontWeight="medium"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    {machineShiftKey.length > 2 ? `${item.substring(0, 5)}...` : item}
                                  </MDTypography>
                                </MDBox>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} lg={5}>
                          {hall.id && (
                            <MDBox mb={0.5} lineHeight={1} key={hall.id}>
                              <Chip
                                label={hall.name}
                                sx={{
                                  background: "linear-gradient(90deg, rgb(31 50 59), rgb(40, 40, 40))",
                                  color: "white !important",
                                  borderRadius: "0.5rem",
                                  padding: "2px 5px",
                                }}
                              />
                            </MDBox>
                          )}

                          {tags.map((tag) => (
                            <MDBox mb={0.5} lineHeight={1} key={tag.id}>
                              <Chip
                                label={tag.name}
                                sx={{
                                  background: `linear-gradient(195deg, #640101, rgb(51, 51, 51))`,
                                  color: "white !important",
                                  borderRadius: "0.5rem",
                                  padding: "2px 5px",
                                }}
                              />
                            </MDBox>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </MDCard>
          </Grid>
        </Grid>
      </MDBox>
    </Grid>
  );
}

// Declaring default props for DefaultNavbar
MachineInfo.defaultProps = {
  // id: "",
  onClick: "",
  health: 0,
  name: "",
  model: "",
  description: "",
  tags: [],
  state: ""
};

// Typechecking props for the ComplexStatisticsCard
MachineInfo.propTypes = {
  // id: PropTypes.number,
  onClick: PropTypes.func,
  health: PropTypes.number,
  name: PropTypes.string,
  model: PropTypes.string,
  description: PropTypes.string,
  state: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string)
};

export default MachineInfo;
