/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import List from "@mui/material/List";
import { styled } from "@mui/system";
import { enumQueryNames } from "api/reactQueryConstant";
import {
  assignTimelineReasonPdaApi,
  getMachineDetailsApi,
  getMachineOrderListApi,
  getOeeCalculationApi,
  getOrderDetailsApi,
  getPdaLiveApi,
  getTimelineReasonApi,
  intervalOrderApi,
  updateMachineDetailsApi,
  getFlexibleShiftApi,
  updateFlexibleShiftApi,
  getFlexibleTimeLineShiftApi,
  updateFlexibleTimeLineShiftApi
} from "api/watchmenApi";
import colors from "assets/theme-dark/base/colors";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import AddTimelinereasonTwoClicks from "components/modal/addTimelinereasonTwoClicks";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import translate from "i18n/translate";
import { quantum } from "ldrs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import PlotlyChart from "../graph";

moment.updateLocale("en", {
  week: {
    dow: 1
  }
});

export const useStyle = () => {
  const theme = useTheme();
  return {
    headerText: {
      color: "green",
      [theme.breakpoints.down("sm")]: {
        color: "orange"
      }
    },
    addButton: {
      marginTop: "2.5%",
      marginLeft: "2%",
      paddingLeft: "12%",
      paddingRight: "12%",
      marginBottom: "1.5%",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "4%",
        marginTop: "3.2%",
        marginBottom: "2%"
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "4%",
        marginTop: "4.2%",
        marginBottom: "3%"
      }
    },
    cardIdleTime: {
      color: theme.palette.common.white,
      padding: "0 12px"
    },
    cardOffTime: {
      color: theme.palette.common.white,
      padding: "0 12px"
    },
    leftArrow: { marginRight: "12px", cursor: "pointer", color: "#FFFFFF" },
    rightArrow: { marginLeft: "12px", cursor: "pointer", color: "#FFFFFF" }
  };
};

function DataAcquisition() {
  quantum.register("my-precious");
  const { machineId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const { axiosPrivate } = useAxiosPrivate();
  const [machineStatusV4Data, setMachineStatusV4Data] = useState();
  const [machineShiftData, setMachineShiftData] = useState();
  const [startDate, setStartDate] = useState(moment());
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [timeDetailPopup, setTimeDetailPopup] = useState(false);
  const [refreshToggler, setRefreshToggler] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [pdaLiveResponse, setPdaLiveResponse] = useState();
  const [initialShiftSet, setInitialShiftSet] = useState(false);
  const [isDraggableView, setDraggableView] = useState(false);
  const [timelineReasonList, setTimelineReasonList] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const classes = useStyle();
  const [searchParams] = useSearchParams();
  const [successSB, setSuccessSB] = useState(null);
  const [glowTimelineReasons, setGlowTimelineReasons] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [orderAllList, setOrderAllList] = useState([]);
  const [isDisplayReasons, setDisplayReasons] = useState(false);
  const closeSuccessSB = () => setSuccessSB(null);
  useEffect(() => {
    if (searchParams.get("date")) {
      setStartDate(moment(searchParams.get("date")));
    } else {
      setStartDate(moment());
    }
  }, []);

  const setMachineShiftDataHandler = (id, data) => {
    // eslint-disable-next-line no-shadow
    let machineData = machineStatusV4Data;
    if (data) {
      machineData = data;
    }
    if (!id) {
      setMachineShiftData(machineData);
    } else if (machineData?.shift_data) {
      const shifts = Object?.values(machineData?.shift_data || null);
      const shiftMachineData = shifts?.find((shift, index) => index === id - 1);

      setMachineShiftData({
        ...machineData,
        day_data_original: machineData?.day_data,
        day_data: {
          ...shiftMachineData
        }
      });
    } else {
      const shifts = Object?.values(machineData?.day_data || null);
      const shiftMachineData = shifts?.find((shift, index) => index === id - 1);
      setTabValue(-1);
      setMachineShiftData({
        ...machineData,
        day_data_original: machineData?.day_data,
        day_data: {
          ...shiftMachineData
        }
      });
    }
  };
  const { mutate: updateMachineArrangementReason } = useMutation(
    (payload) => updateMachineDetailsApi(axiosPrivate, machineId, payload),
    {
      onSuccess: () => {}
    }
  );

  const updateArrangementReasonHandler = () => {
    const payload = {
      meta_frontend: {
        ...machineStatusV4Data?.meta_frontend,
        reason_list_order: timelineReasonList.map((item) => item.id)
      }
    };
    updateMachineArrangementReason(payload);
  };
  const { mutate: refetch } = useMutation(
    [enumQueryNames.CALCULATION_DETAILS],
    () =>
      getOeeCalculationApi(axiosPrivate, {
        machine_list: [+machineId],
        date: moment(startDate).format("YYYY-MM-DD"),
        // pulse: false,
        timeline: true
        // anomalies: false,
        // cycles: false,
        // energy_data: false,
        // extern: false,
        // window_period: windowPeriod || "1m"
      }),
    {
      enabled: !!machineId,
      onSuccess: (dayDetails) => {
        setLoading(false);
        setMachineStatusV4Data(dayDetails[0]);
        setRefreshToggler(false);
        const currentShifts = Object.keys(dayDetails[0]?.shift_data || {});
        const currentShiftIndex = currentShifts.findIndex(
          (shift) => shift === dayDetails[0]?.live_shift
        );
        setMachineShiftDataHandler(currentShiftIndex + 1, dayDetails[0]);
        // setTabValue(() => {

        //   return currentShiftIndex + 1;
        // });
        getMachineLiveData();
      }
    }
  );

  const { data: flexibleShiftData, refetch: refetchFlexibleShiftData } = useQuery(
    [enumQueryNames.FLEXIBLE_SHIFT],
    () =>
      getFlexibleShiftApi(axiosPrivate, {
        machine_id: machineId,
        date: moment(new Date()).format("YYYY-MM-DD")
      })
  );
  const { data: flexibleTimeLineShiftData, refetch: refetchFlexibleTimeLineShiftData } = useQuery(
    [enumQueryNames.FLEXIBLE_TIMELINE_SHIFT],
    () =>
      getFlexibleTimeLineShiftApi(axiosPrivate, {
        machine_id: machineId,
        date: moment(new Date()).format("YYYY-MM-DD")
      })
  );

  const { mutate: updateFlexibleTimeLineShift, isLoading: isLoadingFlexibleShiftData } =
    useMutation(
      [enumQueryNames.UPDATE_FLEXIBLE_SHIFT],
      (payload) => updateFlexibleTimeLineShiftApi(axiosPrivate, payload),
      {
        onSuccess: () => {
          refetchFlexibleTimeLineShiftData();
        }
      }
    );

  const { mutate: updateFlexibleShift, isLoading: isLoadingFlexibleTimeLineShiftData } =
    useMutation(
      [enumQueryNames.UPDATE_FLEXIBLE_TIMELINE_SHIFT],
      (payload) => updateFlexibleShiftApi(axiosPrivate, payload),
      {
        onSuccess: () => {
          refetchFlexibleShiftData();
        }
      }
    );

  const { data: machineData, refetch: refetchMachine } = useQuery(
    [enumQueryNames.MACHINE_DETAILS],
    () => getMachineDetailsApi(axiosPrivate, machineId),
    {
      enabled: !!machineId,
      onSuccess: (machineDetail) => {
        location.state = {
          ...location.state,
          name: machineDetail.name
        };
      }
    }
  );
  const { refetch: refetchPdaLive } = useQuery(
    [enumQueryNames.GET_PDA_LIVE],
    () =>
      getPdaLiveApi(axiosPrivate, {
        machine_list: [+machineId]
      }),
    {
      onSuccess: (response) => {
        if (response[0]?.can_set) {
          setButtonDisable(false);
          if (response[0]?.is_set) {
            setGlowTimelineReasons(false);
          } else {
            setGlowTimelineReasons(true);
          }
        } else {
          setButtonDisable(true);
          setGlowTimelineReasons(false);
        }
        setPdaLiveResponse(response);
      }
    }
  );

  const { mutate: getMachineLiveData, isLoading: isLoadingReason } = useMutation(
    [enumQueryNames.TIMELINE_REASON_LIST],
    () => getTimelineReasonApi(axiosPrivate),
    {
      onSuccess: (data) => {
        setTimelineReasonList(
          machineStatusV4Data?.meta_frontend?.reason_list_order?.length > 0
            ? machineStatusV4Data?.meta_frontend?.reason_list_order?.map((order, index) => {
                const temp = data?.find((item) => item?.id === order);
                return {
                  ...temp,
                  dragId: "box-" + index,
                  type: "box"
                };
              })
            : data?.map((order, index) => ({
                ...order,
                dragId: "box-" + index,
                type: "box"
              }))
        );
      }
    }
  );
  useEffect(() => {
    if (refreshToggler) refetch();
  }, [refreshToggler]);

  const applyDateFilterHandler = () => {
    if (startDate) {
      setLoading(true);
      setTimeDetailPopup(false);
    }
  };
  useEffect(() => {
    if (location?.pathname && startDate) {
      refetchMachine();
      refetch();
    }
  }, [location]);

  useEffect(() => {
    applyDateFilterHandler();
  }, [startDate]);
  useEffect(() => {
    if (!initialShiftSet && machineShiftData) {
      const currentTime = moment().format("HH:mm:ss");
      // Iterate through the shifts and check if the current time falls within the start and end times
      let currentShiftIndex = null;
      // eslint-disable-next-line no-plusplus
      for (
        let index = 0;
        index < Object.values(machineShiftData?.shift_data || {}).length;
        index++
      ) {
        const shift = Object.values(machineShiftData?.shift_data || {})[index];
        const startTime = shift.shift_start_time;
        const endTime = shift.shift_end_time;
        if (currentTime >= startTime && currentTime <= endTime) {
          currentShiftIndex = index;
          break;
        }
      }
      if (currentShiftIndex !== null) {
        setMachineShiftDataHandler(currentShiftIndex + 1);
        setTabValue(currentShiftIndex + 1);
      }
      setInitialShiftSet(true);
    }
  }, [initialShiftSet, machineShiftData]);

  // useEffect(() => {
  //   console.log(socket.rooms, "socket rooms before");
  //   socket.emit("enter_room", { room_name: "8183114f-4890-4249-a01f-508c94c00c81.novoai" });
  //   console.log(socket.rooms, "socket rooms after");
  //   socket.on("notification_message", (message) => {
  //     console.log(message, "message");
  //   });
  // }, []);

  const assignTimelineReasonHandler = async (event, index) => {
    setSelectedIndex(index);
    try {
      const payload = {
        machine_id: parseInt(machineId),
        reason_id: index,
        idx: pdaLiveResponse?.[0]?.idx
      };

      const responseTimeline = await assignTimelineReasonPdaApi(axiosPrivate, payload);
      setSuccessSB(responseTimeline);
      refetch();
      refetchPdaLive();
    } catch (error) {
      setSuccessSB(error.response);
      setTimeDetailPopup(false);
    }
  };
  // const { data: allOrders = [] } = useQuery([enumQueryNames.TIMELINE_REASON_LIST], () =>
  //   getTimelineReasonApi(axiosPrivate)
  // );
  //code for all order list
  useEffect(async () => {
    var rightNow = new Date();
    var date = rightNow.toISOString().slice(0, 10);
    try {
      const response = await getMachineOrderListApi(axiosPrivate, date, machineId);
      setOrderAllList(response.order_list);

      if (response.status === 1) {
        setSuccessSB({
          message: response.message
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [machineId]);

  const assignOrderApiHandler = async (event, id, start) => {
    var rightNow = new Date();
    var date = rightNow.toISOString().slice(0, 10);
    try {
      const payload1 = {
        start_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
      };

      const payload2 = {
        end_datetime: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      const response = await intervalOrderApi(axiosPrivate, id, start == 1 ? payload1 : payload2);

      if (response.status == 1) {
        const response2 = await getOrderDetailsApi(axiosPrivate, id);

        // Update the specific order's intervals in the state
        setOrderAllList((prevOrders) =>
          prevOrders.map((order) =>
            order.id === id ? { ...order, intervals: response2.order.intervals } : order
          )
        );
        setOrderIntervalsId(response2.order.id);
        setOrderTimeIntervals(response2.order.intervals);
      }
      setSuccessSB(response);
      setTimeDetailPopup(false);
    } catch (error) {
      setSuccessSB(error.response);
      setTimeDetailPopup(false);
    }
  };

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    background: "linear-gradient(195deg, #131313, #282828)",
    borderRadius: "0.75rem",
    width: "100%",
    color: "white"
  }));

  const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }));

  // const GlowOngoingText = styled('span')`
  //   color: #00FF00;
  //   animation: glow 1s ease-in-out infinite;
  //   @keyframes glow {
  //     0%, 100% {
  //       text-shadow: 0 0 10px #00FF00;
  //     }
  //     50% {
  //       text-shadow: 0 0 20px #00FF00;
  //     }
  //   }
  // `;

  const OrderTimer = ({ start }) => {
    const [elapsed, setElapsed] = useState("");

    useEffect(() => {
      const calculateElapsed = () => {
        const startTime = new Date(start);
        const now = new Date();
        const diff = now - startTime;

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        setElapsed(`${hours}h ${minutes}m ${seconds}s`);
      };

      calculateElapsed();
      const intervalId = setInterval(calculateElapsed, 1000);

      return () => clearInterval(intervalId);
    }, [start]);

    return (
      <Typography
        variant="body2"
        sx={{ fontSize: "0.8rem", marginLeft: "8px", color: "white !important" }}
      >
        {elapsed}
      </Typography>
    );
  };

  const [selectedOrder, setSelectedOrder] = useState(null);
  const hasOngoingInterval = (order) => order.intervals.some((interval) => interval.end === null);
  const handleSetTabValue = (event, newValue) => {
    setMachineShiftDataHandler(newValue);
    setTabValue(newValue);
  };

  const startFlexibleShift = () => {
    const payload = {
      machine_id: parseInt(machineId),
      start: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      shift_id:
        machineShiftData?.shift_data &&
        flexibleShiftData?.flexible_shift_list?.find(
          (item) =>
            item?.shift?.shift_name === Object.keys(machineShiftData?.shift_data)?.[tabValue - 1]
        )?.shift?.id
    };
    if (tabValue === 0) {
      delete payload["shift_id"];
    }
    updateFlexibleShift(payload);
  };

  const endFlexibleShift = () => {
    const payload = {
      machine_id: parseInt(machineId),
      end: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      shift_id:
        machineShiftData?.shift_data &&
        flexibleShiftData?.flexible_shift_list?.find(
          (item) =>
            item?.shift?.shift_name === Object.keys(machineShiftData?.shift_data)?.[tabValue - 1]
        )?.shift?.id
    };
    if (tabValue === 0) {
      delete payload["shift_id"];
    }
    updateFlexibleShift(payload);
  };

  const startFlexibleTimeLineShift = () => {
    const payload = {
      machine_id: parseInt(machineId),
      start: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      state: 3
    };
    updateFlexibleTimeLineShift(payload);
  };

  const endFlexibleTimeLineShift = () => {
    const payload = {
      machine_id: parseInt(machineId),
      end: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      state: 3
    };
    updateFlexibleTimeLineShift(payload);
  };

  return (
    <DashboardLayout isSideMenu={false}>
      <DashboardNavbar
        isSideMenu={false}
        tabValue={tabValue}
        machineShiftData={machineShiftData}
        tabsOrientation={"horizontal"}
        colors={colors}
        flexibleShiftData={flexibleShiftData?.flexible_shift_list}
        setRefreshToggler={setRefreshToggler}
        handleSetTabValue={handleSetTabValue}
      />
      <Grid width="100%" display="flex" justifyContent="center" alignItems="center">
        <MDButton
          variant="gradient"
          color="success"
          onClick={() => startFlexibleShift()}
          sx={{}}
          disabled={
            tabValue === 0
              ? flexibleShiftData?.flexible_shift_list?.find(
                  (item) => item?.shift && Object.keys(item?.shift)?.length === 0
                )
                ? flexibleShiftData?.flexible_shift_list?.find(
                    (item) => item?.shift && Object.keys(item?.shift)?.length === 0
                  )?.start !== null
                : false
              : machineShiftData?.shift_data &&
                flexibleShiftData?.flexible_shift_list?.find(
                  (item) =>
                    item?.shift?.shift_name ===
                    Object.keys(machineShiftData?.shift_data)?.[tabValue - 1]
                )?.start !== null
          }
        >
          Start shift
        </MDButton>
        <MDButton
          variant="gradient"
          color="error"
          onClick={() => endFlexibleShift()}
          disabled={
            tabValue === 0
              ? flexibleShiftData?.flexible_shift_list?.find(
                  (item) => item?.shift && Object.keys(item?.shift)?.length === 0
                )?.start !== null &&
                flexibleShiftData?.flexible_shift_list?.find(
                  (item) => item?.shift && Object.keys(item?.shift)?.length === 0
                )?.end !== null
              : machineShiftData?.shift_data &&
                (flexibleShiftData?.flexible_shift_list?.find(
                  (item) =>
                    item?.shift?.shift_name ===
                    Object.keys(machineShiftData?.shift_data)?.[tabValue - 1]
                )?.start === null ||
                  flexibleShiftData?.flexible_shift_list?.find(
                    (item) =>
                      item?.shift?.shift_name ===
                      Object.keys(machineShiftData?.shift_data)?.[tabValue - 1]
                  )?.end !== null)
          }
          sx={{
            mx: 3
          }}
        >
          end shift
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          onClick={() => startFlexibleTimeLineShift()}
          sx={{ ml: 4 }}
          disabled={
            !flexibleTimeLineShiftData?.flexible_timeline_data_list?.length > 0 ||
            flexibleTimeLineShiftData?.flexible_timeline_data_list?.[
              flexibleTimeLineShiftData?.flexible_timeline_data_list?.length - 1
            ]?.start === null ||
            flexibleTimeLineShiftData?.flexible_timeline_data_list?.[
              flexibleTimeLineShiftData?.flexible_timeline_data_list?.length - 1
            ]?.end === null
          }
        >
          Start preparation
        </MDButton>
        <MDButton
          variant="gradient"
          color="error"
          disabled={
            flexibleTimeLineShiftData?.flexible_timeline_data_list?.[
              flexibleTimeLineShiftData?.flexible_timeline_data_list?.length - 1
            ]?.end !== null
          }
          onClick={() => endFlexibleTimeLineShift()}
          sx={{
            mx: 3
          }}
        >
          end preparation
        </MDButton>
        {flexibleTimeLineShiftData?.flexible_timeline_data_list?.length > 0 &&
          flexibleTimeLineShiftData?.flexible_timeline_data_list?.[
            flexibleTimeLineShiftData?.flexible_timeline_data_list?.length - 1
          ]?.end === null && (
            <OrderTimer
              start={
                flexibleTimeLineShiftData?.flexible_timeline_data_list?.[
                  flexibleTimeLineShiftData?.flexible_timeline_data_list?.length - 1
                ]?.start
              }
            />
          )}
        <MDSnackbar
          color="success"
          icon="check"
          title="Success"
          content={successSB?.message}
          open={!!successSB?.message}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      </Grid>
      <MDBox sx={{ overflowX: "auto", paddingBottom: "40px 0px", paddingTop: "10px" }}>
        {isLoading ? (
          <Grid item xs={4} sm={12} md={12} lg={10} xl={12}>
            <MDBox lineHeight={1}>
              <MDCard
                sx={classes.graphCard}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "120px"
                }}
              >
                <my-precious color="white" />
              </MDCard>
            </MDBox>
          </Grid>
        ) : machineData && machineShiftData ? (
          <MDCard style={{ width: "100%", margin: "10px 0 5px 0" }} key={machineShiftData?.id}>
            {machineShiftData?.timeline ? (
              <PlotlyChart
                value={machineShiftData?.timeline}
                showTimeline={machineShiftData?.timeline}
                showPulseMovement={false}
                floorplan
                date={moment(startDate).format("YYYY-MM-DD")}
                showAxisLabel={false}
                setTimeDetailPopup={setTimeDetailPopup}
                addTimelineReason
                machineId={machineShiftData?.id}
                timelineReasonList={timelineReasonList}
                sx={{
                  marginBottom: "10px !important"
                }}
              />
            ) : (
              <MDTypography
                variant="button"
                fontWeight="bold"
                color="text"
                textTransform="capitalize"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  paddingTop: "8px",
                  paddingLeft: "8px",
                  paddingBottom: "10px"
                }}
              >
                No data found in the selected day.
              </MDTypography>
            )}
          </MDCard>
        ) : (
          <Skeleton height={120} width="100%" sx={classes.skeleton} />
        )}
      </MDBox>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={4} md={5} lg={4} xl={4} mt={2}>
          <MDCard
            sx={{
              boxShadow: glowTimelineReasons ? "0px 0px 10px 10px rgba(255, 255, 0, 0.1)" : null,
              animation: glowTimelineReasons ? "glow 1.5s ease-in-out infinite" : null,
              "&:hover": {
                boxShadow: glowTimelineReasons ? "0px 0px 10px 10px rgba(255, 255, 0, 0.5)" : null
              },
              "& .MuiButton-root:hover": {
                boxShadow: glowTimelineReasons ? "0px 0px 10px 10px rgba(255, 255, 0, 0.1)" : null
              },
              "@keyframes glow": {
                "0%, 100%": {
                  boxShadow: glowTimelineReasons ? "0px 0px 10px 10px rgba(255, 255, 0, 0.1)" : null
                },
                "50%": {
                  boxShadow: glowTimelineReasons
                    ? "0px 0px 10px 10px rgba(255, 255, 0, 0.75)"
                    : null
                }
              }
            }}
          >
            <MDBox pt={2} px={2} lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium">
                {translate("Timeline Reasons")}
              </MDTypography>
              {!isDraggableView ? (
                <Tooltip title="Enable arrange layout.">
                  <LayersClearIcon
                    onClick={() => {
                      setDisplayReasons(true);
                      setDraggableView(true);
                      setSuccessSB({
                        message: "Enable arrange layout!"
                      });
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Saved arrangement layout.">
                  <LayersIcon
                    onClick={() => {
                      setDisplayReasons(false);
                      setDraggableView(false);
                      updateArrangementReasonHandler();
                      setSuccessSB({
                        message: "Saved arrangement layout!"
                      });
                    }}
                  />
                </Tooltip>
              )}
            </MDBox>
            <MDBox sx={{ py: 3, width: "100%", paddingTop: "0", px: 1, margin: "5px 0 0 0" }}>
              <List
                component="nav"
                aria-label="secondary mailbox folder"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                  maxHeight: "390px",
                  minHeight: "342px",
                  paddingTop: "10px"
                }}
                className="grid"
              >
                {isLoadingReason ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                    <my-precious color="white" />
                  </MDBox>
                ) : timelineReasonList?.length === 0 ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                    <MDTypography variant="h6" fontWeight="medium">
                      {translate("No Reason found!")}
                    </MDTypography>
                  </MDBox>
                ) : (
                  timelineReasonList
                    ?.slice(0, isDisplayReasons ? timelineReasonList?.length : 5)
                    ?.map((list, i) => (
                      <MDButton
                        disabled={
                          !isDraggableView &&
                          (isButtonDisable || pdaLiveResponse?.[0]?.reason?.id === list?.id)
                        }
                        data-index={i}
                        draggable={isDraggableView ? "true" : false}
                        onDragStart={(e) => setFrom(Number(e.currentTarget.dataset.index))}
                        onDragOver={(e) => {
                          e.preventDefault();
                          setTo(Number(e.currentTarget.dataset.index));
                        }}
                        onDragEnd={() => {
                          timelineReasonList.splice(to, 0, timelineReasonList?.splice(from, 1)[0]);
                          setTimelineReasonList(timelineReasonList);
                          setFrom(null);
                          setTo(null);
                        }}
                        key={list.id}
                        variant="gradient"
                        color="info"
                        selected={selectedIndex === list.id}
                        onClick={(event) =>
                          !isButtonDisable && assignTimelineReasonHandler(event, list.id)
                        }
                        sx={{
                          width: "calc(50% - 10px)", // Adjust width to ensure two buttons per row with margin
                          height: "100px", // Fixed height to make buttons square
                          mx: "5px", // Adjust margin between buttons
                          my: "10px",
                          marginBottom: "1px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background:
                            list.meta_frontend.color === "none"
                              ? "rgb(20, 169, 147)"
                              : list.meta_frontend.color,
                          textAlign: "center",
                          wordBreak: "break-word", // Break long words
                          whiteSpace: "normal", // Allow text to wrap
                          padding: "5px" // Add padding for text inside the button
                        }}
                      >
                        {list.name}
                      </MDButton>
                    ))
                )}
                {timelineReasonList?.length > 5 && (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={(event) => setDisplayReasons(!isDisplayReasons)}
                    sx={{
                      width: "calc(50% - 10px)", // Adjust width to ensure two buttons per row with margin
                      height: "100px", // Fixed height to make buttons square
                      mx: "5px", // Adjust margin between buttons
                      my: "10px",
                      marginBottom: "1px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgb(20, 169, 147)",
                      textAlign: "center",
                      wordBreak: "break-word", // Break long words
                      whiteSpace: "normal", // Allow text to wrap
                      padding: "5px" // Add padding for text inside the button
                    }}
                  >
                    + Other
                  </MDButton>
                )}
              </List>
            </MDBox>
          </MDCard>
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={8} xl={8} mt={2}>
          <MDCard sx={{ width: "100%", overflow: "auto" }}>
            <MDBox pt={2} px={2} lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h6" fontWeight="medium">
                {translate("All Orders")}
              </MDTypography>
              <Grid item display="flex" justifyContent="center" alignItems="center">
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={() => selectedOrder && assignOrderApiHandler(null, selectedOrder, 1)}
                  sx={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    // margin-bottom for spacing between buttons
                  }}
                  disabled={
                    !selectedOrder ||
                    (selectedOrder &&
                      hasOngoingInterval(
                        orderAllList?.find((order) => order?.id === selectedOrder)
                      ))
                  }
                >
                  <PlayArrowIcon sx={{ fontSize: "4rem" }} />
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => selectedOrder && assignOrderApiHandler(null, selectedOrder, 0)}
                  sx={{
                    ml: 4,
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  disabled={
                    !selectedOrder ||
                    (selectedOrder &&
                      !hasOngoingInterval(
                        orderAllList?.find((order) => order?.id === selectedOrder)
                      ))
                  }
                >
                  <StopIcon sx={{ fontSize: "4rem" }} />
                </MDButton>
              </Grid>
            </MDBox>
            <MDBox
              sx={{
                py: 3,
                display: "flex",
                width: "100%",
                paddingTop: "0",
                margin: "5px 0",
                pl: 2,
                pr: 2,
                maxHeight: "400px",
                minHeight: "349px"
              }}
            >
              {orderAllList?.length === 0 ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
                  <MDTypography variant="h6" fontWeight="medium">
                    {translate("No Order found!")}
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  {orderAllList?.map((order) => (
                    <MDBox key={order?.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Checkbox
                        checked={selectedOrder === order?.id}
                        onChange={() => setSelectedOrder(order?.id)}
                        color="primary"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} // Adjust the size of the checkbox
                      />
                      <StyledAccordion key={order?.id}>
                        <StyledAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${order.id}-content`}
                          id={`panel${order?.id}-header`}
                        >
                          <MDBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "space-between"
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontWeight="regular"
                              sx={{ color: "white !important" }}
                            >
                              {`${order?.name} (${order?.ext_order_id})`}
                            </Typography>
                            {order?.intervals?.some((interval) => interval?.end === null) && (
                              <OrderTimer
                                start={
                                  order?.intervals?.find((interval) => interval?.end === null)
                                    ?.start
                                }
                              />
                            )}
                          </MDBox>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                          {order?.intervals?.length === 0 ? (
                            <Typography variant="body2" color="white">
                              No intervals available
                            </Typography>
                          ) : (
                            <MDBox component="div" sx={{ width: "100%" }}>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  border: "none"
                                }}
                              >
                                <tbody>
                                  {order?.intervals.map((interval, index) => {
                                    const start = new Date(interval?.start);
                                    const end = interval?.end ? new Date(interval?.end) : null;
                                    const duration = end
                                      ? new Date(end - start).toISOString().substr(11, 8)
                                      : "";

                                    return (
                                      <tr key={interval?.id} style={{ border: "none" }}>
                                        <td
                                          style={{
                                            padding: "8px",
                                            width: "15%",
                                            color: "white",
                                            fontSize: "14px",
                                            border: "none"
                                          }}
                                        >
                                          {`Interval ${index + 1}`}
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px",
                                            width: "30%",
                                            color: "white",
                                            fontSize: "14px",
                                            border: "none"
                                          }}
                                        >
                                          {interval?.start}
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px",
                                            width: "30%",
                                            color: "white",
                                            fontSize: "14px",
                                            border: "none"
                                          }}
                                        >
                                          {interval?.end ? interval?.end : "Ongoing"}
                                        </td>
                                        <td
                                          style={{
                                            padding: "8px",
                                            width: "25%",
                                            color: "white",
                                            fontSize: "14px",
                                            border: "none"
                                          }}
                                        >
                                          {duration}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </MDBox>
                          )}
                        </AccordionDetails>
                      </StyledAccordion>
                    </MDBox>
                  ))}
                </MDBox>
              )}
            </MDBox>
          </MDCard>
        </Grid>
      </Grid>
      <AddTimelinereasonTwoClicks
        timeDetailPopup={timeDetailPopup}
        setTimeDetailPopup={setTimeDetailPopup}
        refetch={refetch}
      />
    </DashboardLayout>
  );
}

export default DataAcquisition;
