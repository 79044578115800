import { ADD_SEARCH_MACHINE, DELETE_SEARCH_MACHINE } from "../constants";

export const addMachinePageSearch = (payload) => ({
  type: ADD_SEARCH_MACHINE,
  payload: payload
});
export const deleteMachinePageSearch = (payload) => ({
  type: DELETE_SEARCH_MACHINE,
  payload: payload
});
