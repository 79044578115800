import { LOCALES } from "../locales";

export default {
  [LOCALES.GERMAN]: {
    running: "Aktiv",
    idle: "Leerlauf",
    stopped: "Gestoppt",
    preparation: "In Vorbereitung",
    alerts: "Warnungen",
    "No New Notifications": "Keine neuen Benachrichtigungen",
    user: "Benutzer",
    "floor plan": "Hallenplan",
    admin: "Administrator",
    Machines: "Maschinen",
    machines: "Maschinen",
    dashboard: "Dashboard",
    Dashboards: "Dashboards",
    floorplan: "Hallenplan",
    Floorplan: "Hallenplan",
    machinehistory: "Maschinen Historie",
    profile: "Profil",
    "basic info": "Basisinformation",
    username: "Benutzername",
    "firstName": "Vorname",
    "lastName": "Nachname",
    role: "Rolle",
    "change password": "Passwort ändern",
    "current password": "Aktuelles Passwort",
    "new password": "Neues Kennwort",
    "confirm new password": "bestätige neues Passwort",
    "Password requirements": "Passwortanforderungen",
    "Please follow this guide for a strong password":
      "Bitte folgen Sie dieser Anleitung für ein sicheres Passwort",
    "One special characters": "Ein Sonderzeichen",
    "Min 6 characters": "Mindestens 6 Zeichen",
    "One number (2 are recommended)": "Eine Nummer (2 werden empfohlen)",
    "One lowercase letter": "Eine Kleinbuchstaben",
    "One uppercase letter": "Eine Großbuchstaben",
    "Change it often": "Ändern Sie es oft",
    "Download a supported authentication app to your device (such as Google Authenticator, Microsoft Authenticator etc).":
      "Laden Sie ein von einem Authentifizierungsapp in Ihr Anwendung herunter (z. B. Google Authenticator, Microsoft Authenticator etc).",
    "Generate a QR code.": "Generieren Sie eine QR-Code.",
    "Generating QR code": "Generiere QR-Code",
    "Scan the QR code with your authentication app.":
      "Scanne den QR-Code mit deinem Authentifizierungsapp. ",
    "Use the authentication code provided by your authentication app from your next login.":
      "Verwende den Authentifizierungscod aus deinem Authentifizierungsapp von deinem nätigem Login. ",
    "updating password": "Passwort aktualisieren",
    "update password": "Kennwort aktualisieren",
    users: "Benutzer",
    name: "Name",
    active: "aktiv",
    super_admin: "Super Admin",
    company: "Unternehmen",
    commander: "Kommandant",
    "Add Company": "Unternehmen hinzufügen",
    "Add Ava": "Ava Hinzufügen",
    "Add Tess": "Tess Hinzufügen",
    "Add User": "Benutzer Hinzufügen",
    "MAC address": "MAC-Adresse",
    "machine name": "Maschinenname",
    "machine id": "Maschinen ID",
    identifier: "Kennung",
    Availability: "Verfügbarkeit",
    Performance: "Leistung",
    Quality: "Qualität",
    "Machine Runtime": "Laufzeit",
    anomalies: "Anomalien",
    cycles: "Produktionszyklen",
    Temperature: "Temperatur",
    Overview: "Überblick",
    Filter: "Filter",
    "Machine Health": "Maschinenzustand",
    "lastHeartbeat": "Letzter Pulsschlag",
    Runtime: "Laufzeit",
    "Machine History": "Maschinen Historie",
    "Live Data": "Live-Daten",
    "Stop Live Data": "Live-Daten Stoppen",
    "Product Count": "Produktanzahl",
    "Good Count": "Gute Produkte",
    "Bad Count": "Ausschuss",
    FeatureResponse: "Feature Antwort",
    "Total Processed": "Insgesamt verarbeitet",
    Measure: "Messen",
    Feature: "Besonderheit",
    Image: "Bild",
    Configurator: "Konfigurator",
    "See our dashboard options.": "Sehen Sie sich unsere Dashboard-Optionen an.",
    "Sidenav Colors": "Sidenav-Farben",
    "Sidenav Type": "Sidenav-Typ",
    "Choose between different sidenav types.": "Wählen Sie zwischen verschiedenen Sidenav-Typen.",
    Dark: "Dunkel",
    White: "Weiß",
    "Navbar Fixed": "Navigationsleiste fixiert",
    "Sidenav Mini": "Sidenav Mini",
    "Light / Dark": "Hell / dunkel",
    "sign in": "Anmelden",
    signInWith: "Anmelden mit",
    signUpWith: "Registrieren mit",
    or: "ODER",
    "Remember me": "Gerät merken",
    "signing in": "Einloggen",
    "sign up": "Registrieren",
    logout: "Abmelden",
    "Don't have an account?": `Sie haben kein Konto ?`,
    "Enter your email and password to register":
      "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich zu registrieren",
    "I agree the": "Ich stimme dem zu",
    "Terms and Conditions": "Geschäftsbedingungen",
    acceptTermsConditions: "Bitte akzeptieren Sie die Geschäftsbedingungen",
    "signing up": "Registrierung",
    "Already have an account?": "Sie haben bereits ein Konto ?",
    "Number of machines currently MACHINING on the production floor":
      "Anzahl der Maschinen, die derzeit in der Produktionshalle ARBEITEN",
    "Number of machines currently IDLE on the production floor":
      "Anzahl der derzeit im Leerlauf befindlichen Maschinen in der Produktionshalle",
    "Number of machines currently OFF on the production floor":
      "Anzahl der derzeit in VORBEREITUNG befindlichen Maschinen in der Produktionshalle",
    "Number of machines currently in PREPARATION on the production floor":
      "Anzahl der derzeit in VORBEREITUNG befindlichen Maschinen in der Produktionshalle",
    "Overall Equipment Effectiveness of the machine. Calculated as an equal factor of Availability, Productivity and Quality of the machine production":
      "Gesamtausstattung Effektivität der Maschine. Berechnet als gleichberechtigter Faktor aus Verfügbarkeit, Produktivität und Qualität der Maschinenfertigung",
    "Availability is the production performance of the machine over a set period of time":
      "Verfügbarkeit ist die Produktionsleistung der Maschine über einen bestimmten Zeitraum hinweg",
    "Performance is a cumulative of production and preparation performance of the machine":
      "Die Leistung ist eine Summe aus Produktions- und Vorbereitungsleistung der Maschine",
    "Set at 95% - contact Admin to edit":
      "Auf 95 % festgelegt - wenden Sie sich an den Administrator, um diesen Wert zu bearbeiten",
    "Number of anomalous behaviors observed in the machine (Coming Soon)":
      "Anzahl der in der Maschine beobachteten anomalen Verhaltensweisen (demnächst verfügbar)",
    "Number of cycles performed by the machine (Coming Soon)":
      "Anzahl der von der Maschine durchgeführten Zyklen (demnächst verfügbar)",
    "Current temperature within the machine": "Aktuelle Temperatur innerhalb der Maschine",
    "Machine Idletime": "Leerlaufzeit",
    Idletime: "Leerlaufzeit",
    "Total ON time of the machine": "Gesamte Laufzeit der Maschine (ON)",
    "Total IDLE time of the machine": "Gesamte IDLE-Zeit der Maschine",
    Timeline: "Zeitplan",
    "Shift group": "Schichtgruppe",
    "shift group ": "Schichtgruppe",
    Shifts: "Schichten",
    shifts: "Schichten",
    start: "start",
    end: "Ende",
    "addShiftGroup": "Schichtgruppe hinzufügen",
    "addShift": "Schicht hinzufügen",
    Languages: "Sprachen",
    fullname: "Vollständiger Name",
    mobile: "Mobiltelefon",
    email: "E-mail",
    Timezone: "Zeitzone",
    Logo: "Logo",
    "Real time overview": "Übersicht in Echtzeit",
    "Energy consumption": "Energieverbrauch",
    "last day": "Letzter Tag",
    "last week": "Letzte Woche",
    "last month": "Im vergangenen Monat",
    "last 3 month": "Letzte 3 Monate",
    "Company information": "Firmeninformation",
    "Sort by": "Sortiere nach",
    "Remove Filter": "Filter entfernen",
    Save: "Speichern",
    off: "aus",
    "Power consumption": "Energieverbrauch",
    production: "Produktion",
    Zipcode: "PLZ",
    Country: "Land",
    State: "Zustand",
    City: "Stadt",
    "Address line 2": "Adresszeile 2",
    "Address line 1": "Anschrift Zeile 1",
    store: "speichern",
    compare: "vergleichen",
    addchart: "Graph hinzufügen",
    ballot: "Abstimmung",
    "Authors Table": "Autorentabelle",
    "Projects Table": "Projekttabelle",
    Id: "Ausweis",
    Edit: "Bearbeiten",
    Developer: "Entwicklerin",
    Programator: "Programmierer",
    Manager: "Manager",
    Executive: "Exekutive",
    Projects: "Projekte",
    Organization: "Organisation",
    lightbulb: "Glühbirne",
    "New York City": "New York City",
    Task: "Aufgabe",
    "Belong Interactive": "Belong Interactive",
    "Freelance Payment": "Freiberufliche Bezahlung",
    Apr: "Apr",
    May: "Mai",
    Jun: "Jun",
    Jul: "Juli",
    Aug: "Aug",
    Sep: "Sept",
    Oct: "Okt",
    Nov: "Nov",
    Dec: "Dez",
    Devices: "Geräte",
    "in stock": "auf Lager",
    sold: "verkauft",
    Tickets: "Tickets",
    closed: "geschlossen",
    open: "offen",
    "is active": "ist aktiv",
    "Happy users": "Glückliche Benutzer",
    Calories: "Kalorien",
    "All day conference": "Ganztägige Konferenz",
    "Meeting with Mary": "Treffen mit Maria",
    "Cyber Week": "Cyber-Woche",
    "Winter Hackaton": "Winter-Hackathon",
    "Digital event": "Digitale Veranstaltung",
    "Marketing event": "Marketingveranstaltung",
    "Dinner with Family": "Abendessen mit Familie",
    "Black Friday": "Schwarzer Freitag",
    "Upcoming events": "Kommende Veranstaltungen",
    Joined: "Beigetreten",
    "Tasks planification": "Aufgabenplanung",
    Steps: "Schritte",
    Step: "Schritt",
    "Orders overview": "Bestellübersicht",
    "this month": "diesen Monat",
    "Design changes": "Design-Änderungen",
    "New order": "Neue Bestellung",
    "Server payments for April": "Serverzahlungen für April",
    "New card added for order": "Neue Karte zur Bestellung hinzugefügt",
    "Some Kind Of Blues": "Eine Art Blues",
    Deftones: "Deftones",
    skip_previous: "überspringen_vorheriger",
    play_arrow: "Das Nächste überspringen",
    "Full Body": "Ganzkörper",
    "What matters is the people who are sparked by it. And the people who are liked.":
      "Was zählt, sind die Menschen, die davon begeistert sind. Und die Menschen, die gemocht werden.",
    back: "zurück",
    next: "nächste",
    send: "schicken",
    "Email address": "E-Mail-Adresse",
    Password: "Passwort",
    "Repeat Password": "Passwort wiederholen",
    Address: "Adresse",
    Zip: "PLZ",
    "Twitter Handle": "Twitter",
    "Facebook Account": "Facebook-Konto",
    "Instagram Account": "Instagram-Konto",
    "Public Email": "Öffentliche E-Mail",
    Bio: "Bio",
    "Twitter profile is required.": "Twitter-Profil ist erforderlich.",
    "Zipcode is not valie (e.g. 70000).": "Postleitzahl ist nicht gültig (z. B. 70000).",
    "City is required.": "Stadt ist erforderlich.",
    "Address is required.": "Adresse ist erforderlich.",
    "Your password doesn't match.": "Ihr Passwort stimmt nicht überein.",
    "Password is required.": "Passwort wird benötigt.",
    "Your password should be more than 6 characters.":
      "Ihr Passwort sollte mehr als 6 Zeichen lang sein.",
    "Email address is required.": "E-Mailadresse wird benötigt.",
    "Your email address is invalid": "Ihre E-Mail Adresse ist ungültig.",
    "Last name is required": "Nachname ist erforderlich.",
    "First name is required": "Vorname ist erforderlich.",
    "Zip is required": "Postleitzahl ist erforderlich.",
    "Mandatory information": "Verpflichtende Angaben",
    "About me": "Über mich",
    skip_next: "Das Nächste überspringen",
    "Unlock packages for development": "Pakete für die Entwicklung freischalten",
    "New message unread": "Neue ungelese Nachricht",
    "Notifications unread": "Benachrichtigungen ungelesen",
    "New request": "Neue Anfrage",
    "Controller issues": "Controller-Probleme",
    "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of":
      "Den Menschen ist es wichtig, wie Sie die Welt sehen, wie Sie denken, was Sie motiviert, womit Sie zu kämpfen haben oder wovor Sie Angst haben",
    "Timeline with dotted line": "Timeline mit gepunkteter Linie",
    "Architects design houses": "Architekten entwerfen Häuser",
    "Music is something that everyone has their own specific opinion about":
      "Musik ist etwas, worüber jeder seine eigene spezifische Meinung hat.",
    "Different people have different taste, and various types of music":
      "Verschiedene Menschen haben unterschiedliche Geschmäcker und unterschiedliche Arten von Musik.",
    "Why would anyone pick blue over pink? Pink is obviously a better color":
      "Warum sollte jemand Blau statt Rosa wählen? Pink ist offensichtlich eine bessere Farbe",
    "Track order": "Bestellung verfolgen",
    "Order received": "Bestellung erhalten",
    "Generate order id": "Bestell-ID generieren",
    "Order transmited to courier": "Bestellung an Kurier übermittelt",
    "Order delivered": "Bestellung an Kurier übermittelt",
    "Payment details": "Zahlungsdetails",
    "We do not store card details": "Wir speichern keine Kartendaten",
    priority_high: "priorität hoch",
    "Order Summary": "Bestellübersicht",
    "Product Price": "Produktpreis",
    Delivery: "Lieferung",
    Taxes: "Steuern",
    "Gold Glasses": "Goldbrille",
    "Order was delivered 2 days ago.": "Die Bestellung wurde vor 2 Tagen geliefert.",
    "contact us": "kontaktiere uns",
    "Do you like the product? Leave us a review":
      "Gefällt Ihnen das Produkt? Hinterlassen Sie uns eine Bewertung",
    here: "Hier",
    "Order Details": "Bestelldetails",
    "Order no": "Best.-Nr",
    Code: "Code",
    invoice: "Rechnung",
    "Billing Information": "Abrechnungsdaten",
    "Oliver Liam": "Oliver Liam",
    "Company Name": "Name der Firma",
    "Viking Burrito": "Wikinger-Burrito",
    "VAT Number": "Umsatzsteuer-Identifikationsnummer",
    "Please fill all fields": "Bitte alle Felder ausfüllen",
    "Confirm password does not match": "Bestätigen Sie, dass das Passwort nicht übereinstimmt",
    "Last 7 days": "Letzten 7 Tage",
    "Last 30 days": "Letzte 30 Tage",
    "Top Selling Products": "Die meistverkauften Produkte",
    "Sales by age": "Verkäufe nach Alter",
    "Facebook Ads": "Facebook-Anzeigen",
    "Google Ads": "Google-Anzeigen",
    Channels: "Kanäle",
    "See traffic channels": "Siehe Verkehrskanäle",
    "More than": "Mehr als",
    "sales are made using referral marketing, and":
      "Der Verkauf erfolgt über Empfehlungsmarketing und",
    "are from social media": "stammen aus den sozialen Medien",
    "read more": "mehr lesen",
    Facebook: "Facebook",
    Direct: "Direkte",
    Organic: "Bio",
    Referral: "Verweisung",
    Clear: "Klar",
    Add: "Hinzufügen",
    "Machine name": "Maschinenname",
    "Run time": "Laufzeit",
    "Production time": "Produktionszeit",
    "Off time": "Auszeit",
    OEE: "OEE",
    Success: "Erfolg",
    "Select date": "Datum auswählen",
    "energy wastage": "Energieverschwendung",
    "energy consumption costs": "Kosten für den Energieverbrauch",
    "energy waste costs": "Kosten für Energieverschwendung",
    Vibration: "Vibration",
    "Last Campaign Performance": "Letzte Kampagnenleistung",
    "increase in today sales": "augmentation des ventes aujourdhui",
    'The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona':
      "Der Ort liegt in der Nähe des Barceloneta-Strands und der Bushaltestelle, nur 2 Gehminuten entfernt, und in der Nähe von „Naviglio“, wo Sie das wichtigste Nachtleben Barcelonas genießen können",
    'he place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK':
      "Der Ort liegt in der Nähe der U-Bahn- und Bushaltestelle, nur 2 Gehminuten entfernt, und in der Nähe von „Naviglio“, wo Sie das Nachtleben von London, Großbritannien, genießen können",
    'The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan':
      "Der Ort liegt in der Nähe der U-Bahn-Station und der Bushaltestelle, nur 2 Gehminuten entfernt, und in der Nähe von „Naviglio“, wo Sie das wichtigste Nachtleben Mailands genießen können",
    "OEE, Energy consumption and energy wastage vs days":
      "OEE, Energieverbrauch und Energieverschwendung im Vergleich zu Tagen",
    "energy consumption": "Energieverbrauch",
    Total: "Gesamt",
    "Invalid value for the prop number, the value for the number prop shouldn't be greater than or less than 16 digits":
      "Ungültiger Wert für die Stütznummer. Der Wert für die Stütznummer sollte nicht größer oder kleiner als 16 Ziffern sein",
    "Card Holder": "Kartenhalter",
    Expires: "Abgelaufen",
    "Expired": "Abgelaufen",
    schedule: "Zeitplan",
    "All Rights Reserved": "Alle Rechte vorbehalten",
    "WatchMen AI Platform v1.1 by Novo AI": "WatchMen AI Platform v1.1 von Novo AI",
    circle: "Kreis",
    close: "schließen",
    expand_less: "expand_less",
    arrow_drop_up: "Arrow_drop_up",
    arrow_drop_down: "Pfeil_Drop_Down",
    "entries per page": "Einträge pro Seite",
    Showing: "Zeigt",
    entries: "Einträge",
    "Select company": "Unternehmen auswählen",
    "Select Device": "Wählen Sie Gerät aus",
    "Select Command": "Wählen Sie „Befehl“.",
    "Select Directory": "Wählen Sie Verzeichnis aus",
    "No response from server": "Keine Antwort vom Server",
    "missing machine name or email": "Maschinenname oder E-Mail fehlen",
    Unauthorized: "Nicht autorisiert",
    "Unable to create a Company. Please try again in sometime":
      "Es kann kein Unternehmen erstellt werden. Bitte versuchen Sie es einigen Minuten noch einmal",
    "Company Name cannot be empty": "Der Firmenname darf nicht leer sein",
    "Creating Company": "Unternehmen anlegen",
    "Create Company": "Unternehmen erstellen",
    "Admin Email": "Admin-E-Mail",
    License: "Lizenz",
    "Fields cannot be empty": "Felder dürfen nicht leer sein",
    enterLicenseKeyError: "Bitte geben Sie einen gültigen License Key ein",
    "missing Tess name or email": "Name oder E-Mail von Tess fehlen",
    "Creating Tess": "Tess anlegen",
    "Create Tess": "Erstelle Tess",
    "Updating Tess": "Updating Ava",
    "Update Tess": "Tess aktualisieren",
    "Creating Ava": "Ava anlegen",
    "Create Ava": "Ava erstellen",
    "Update Ava": "Ava aktualisieren",
    "Updating Ava": "Ava wird aktualisiert",
    "Select role": "Rolle auswählen",
    "Updating User": "Benutzer wird aktualisiert",
    "Update User": "Benutzer aktualisieren",
    "Create User": "Benutzer erstellen",
    "Creating User": "Benutzer erstellen",
    "Are you sure you want to delete this": "Sind Sie sicher, dass Sie dies löschen möchten",
    "Are you sure you want to delete this token":
      "Sind Sie sicher, dass Sie dieses Token löschen möchten?",
    "Are you sure you want to delete this tag":
      "Sind Sie sicher, dass Sie dieses Tag löschen möchten?",
    "Are you sure you want to delete this hall":
      "Sind Sie sicher, dass Sie diese Halle löschen möchten?",
    "Are you sure you want to delete this shift":
      "Sind Sie sicher, dass Sie diese Schicht löschen möchten?",
    "Are you sure you want to delete this shift group":
      "Sind Sie sicher, dass Sie diese Schichtgruppe löschen möchten?",
    "Team members": "Teammitglieder",
    "Next events": "Nächste Veranstaltungen",
    "Meeting with Marry": "Treffen mit Marry",
    "Book Deposit Hall": "Buchdepothalle",
    "Shipment Deal UK": "Versandangebot UK",
    "Verify Dashboard Color Palette": "Überprüfen Sie die Farbpalette des Dashboards",
    Visitors: "Besucher",
    Action: "Aktion",
    "Anoter action": "Noch eine Aktion",
    "Something else here": "Hier noch etwas anderes",
    favorite: "Favoritin",
    "Creative Tim": "Kreativer Tim",
    "for a better web": "für ein besseres Web.",
    "About Us": "Über uns",
    Blog: "Blog",
    "Logging out": "Ausloggen",
    "Reset Password": "Passwort zurücksetzen",
    "You will receive an e-mail in maximum 60 seconds":
      "Sie erhalten innerhalb von maximal 60 Sekunden eine E-Mail",
    reset: "zurücksetzen",
    "name and password cannot be empty": "Benutzername oder Passwort dürfen nicht leer sein",
    "Enter your email and password to Sign In":
      "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden",
    "Select range": "Bereich auswählen",
    Energy: "Energie",
    "Idle time": "Stillstandszeit",
    "Stopped time": "Stopp der Maschinenzeit",
    "Production time, idle time and stopped time vs days":
      "Produktionszeit, Leerlaufzeit und Stillstandszeit im Vergleich zu Tagen",
    "Avg. OEE per machine": "Durchschnittliche OEE pro Maschine",
    "Average OEE": "Durchschnittlicher OEE",
    "Total energy consumption and wastage of machines":
      "Gesamtenergieverbrauch und Verschwendung von Maschinen",
    "Generate report": "Bericht generieren",
    "Sales by Country": "Verkäufe nach Ländern",
    refresh: "Aktualisierung",
    Followers: "Anhänger",
    Revenue: "Einnahmen",
    "Today's Users": "Heutige Benutzer",
    Bookings: "Buchungen",
    upload: "hochladen",
    "Do you want to carry forward this choice for entire week?":
      "Möchten Sie diese Wahl für die ganze Woche beibehalten?",
    "Week picker": "Wochenauswahl",
    Reports: "Berichte",
    "Plant Energy": "Pflanzenenergie",
    "AVA Details": "AVA Details",
    Status: "Status",
    Hostname: "Hostname",
    "Edge id": "Edge-ID",
    Pulse: "Impuls",
    "Mobile apps": "Mobile apps",
    "Desktop apps": "Desktop-Apps",
    "1 V 1": "1 V 1",
    machineShifts: "machineShifts",
    "All Shifts": "Alle Schichten",
    "Regular Shift": "Regelmäßige Schicht",
    "Early Shift": "Frühschicht",
    "Evening Shift": "Abendschicht",
    "Load More": "Mehr laden",
    "live machine": "Live-Maschine",
    "Regular Shift Group ": "Groupe à double équipe ",
    "Dual Shift Group": "Dual-Shift-Gruppe",
    "Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).":
      "Hallo, ich bin Alec Thompson, Entscheidungen: Wenn Sie sich nicht entscheiden können, lautet die Antwort Nein. Wenn es zwei gleichermaßen schwierige Wege gibt, wählen Sie den, der kurzfristig schmerzhafter ist (Schmerzvermeidung erzeugt die Illusion von Gleichheit).",
    Camera: "Kamera",
    settings: "Einstellungen",
    "loadRating": "Rating Berechnen",
    "Update rating": "Rating aktualisieren",
    "Select monday shifts": "Wählen Sie Montagschichten aus",
    "Select tuesday shifts": "Wählen Sie Dienstagsschichten aus",
    "Select wednesday shifts": "Wählen Sie Mittwochsschichten aus",
    "Select thursday shifts": "Wählen Sie Donnerstagsschichten aus",
    "Select saturday shifts": "Wählen Sie Samstagsschichten aus",
    "Select sunday shifts": "Wählen Sie Sonntagsschichten aus",
    "Select friday shifts": "Wählen Sie Freitagsschichten aus",
    "machineRating": "Maschinen-Rating",
    "Updating shift group": "Schichtgruppe aktualisieren",
    "updateShiftGroup": "Schichtgruppe aktualisieren",
    "Creating shift group": "Schichtgruppe erstellen",
    "Create shift group": "Schichtgruppe erstellen",
    "Updating shift": "Schicht wird aktualisiert",
    "Update shift": "Schicht aktualisieren",
    "Creating shift": "Schicht schaffen",
    "Create shift": "Schicht erstellen",
    "Break End time": "Pause Endzeit",
    "Break Start time": "Pause Startzeit",
    "Shift End time": "Endzeit der Schicht",
    "Shift Start time": "Startzeit der Schicht",
    description: "Beschreibung",
    "copyAccessKeyMessage":
      "Bitte kopieren Sie dieses Access-Key jetzt. Es später nicht mehr möglich sein.",
    "Creating Access Key": "Access-Key erstellen",
    "Create Access Key": "Access-Key erstellen",
    "Select Senser": "Sensor wählen",
    Sensors: "Sensoren",
    "Machine Page": "Maschinenseite",
    Details: "Einzelheiten",
    "Complete Step": "Schritt abschließen",
    Finish: "Beenden",
    "Select Tess": "Tess auswählen",
    "All steps completed - you're finished": "Alle Schritte abgeschlossen – Sie sind fertig",
    "selectMachine": "Wählen Sie Maschine",
    "selectAccessibleMachine": "Zugängliche Maschinen auswählen",
    "selectNotificationTypes": "Benachrichtigungen auswählen",
    Cancel: "Stornieren",
    "Select Mode": "Auswahlmodus",
    Hallplan: "Hallenplan",
    "Leader Board": "Bestenliste",
    Scheduler: "Planer",
    "Org Stats": "Org Stats",
    Dashboard: "Dashboard",
    Versus: "Versus",
    "Peak Optimizer": "Peak Optimizer",
    "create": "Erstellen",
    "Selected Pulse": "Ausgewählter Puls",
    Yes: "Ja",
    No: "Nein",
    "Energy wastage": "Energieverschwendung",
    "consumption costs": "Verbrauchskosten",
    energyPrice: "Energiepreis",
    "wastage costs": "Verschwendungskosten",
    "The OTP you entered is incorrect. Please try again.":
      "Der eingegebene OTP ist falsch. Bitte versuchen Sie es erneut.",
    "Trust this device for next 30 days": "Vertrauen Sie diesem Gerät für die nächsten 30 Tage",
    "Authenticator Verification": "Authenticator Verifizierung",
    "Enter the 6-Digit Verification Code": "Geben Sie den 6-stelligen Verifizierungscode ein",
    "Is Your Authenticator Not Configured?": "Ist Ihr Authentifikator nicht konfiguriert?",
    "Send Email": "E-Mail senden",
    "Resend Email": "E-Mail erneut senden",
    "Back to Authenticator Verification": "Zurück zu Authenticator Verifizierung",
    "Verify Account": "Konto überprüfen",
    "addMachine": "Maschine hinzufügen",
    editMachine: "Maschine bearbeiten",
    "addHall": "Halle hinzufügen",
    "updateHall": "Halle aktualisieren",
    "addTags": "Tags hinzufügen",
    "updateTags": "Tags aktualisieren",
    "addOperator": "Operator hinzufügen",
    "updateOperator": "Operator aktualisieren",
    "addEnergyPrice": "Energiepreis hinzufügen",
    "updateEnergyPrice": "Energiepreis aktualisieren",
    "addTimelineReason": "Timeline Gründe hinzufügen",
    "updateTimelineReason": "Timeline Gründe aktualisieren",
    "addAccessKeys": "API-Zugangsschlüssel hinzufügen",
    "updateAccessKeys": "API-Zugangsschlüssel aktualisieren",
    "apiAccessKeys": "API-Zugangsschlüssel",
    "accessibleMachines": "Zugängliche Maschinen",
    "allMachineAccess": "Alle Maschinenzugriffe",
    selectedTabs:
      "Wählen Sie einen Tab aus, um detaillierte Daten anzuzeigen. Jeder Tab zeigt spezifische Informationen zu Maschinenschichten oder Tagesanalysen.",
    SelectResolution:
      "Wählen Sie die Auflösung, um die Detailgenauigkeit der angezeigten Pulspunkte anzupassen. Optionen umfassen Intervalle von 1 Minute, 30 Sekunden, 5 Sekunden und 1 Sekunde.",
    SelectTabsOrg:
      "Wählen Sie einen Tab aus, um Organisationsstatistiken über verschiedene Zeiträume hinweg anzusehen. 1D zeigt Daten des letzten Tages, 1W der letzten Woche und 1M des letzten Monats.",
    chooseDay:
      "Wählen Sie 'Tag', 'Woche' oder 'Monat', um Daten für den ausgewählten Zeitraum kumulativ in Diagrammen zu visualisieren.",
    "Avg. OEE": "Durchschn. OEE",
    "Avg. Performance": "Durchschn. Leistung",
    "Production duration": "Produktionsdauer",
    "Idle Duration": "Leerlaufzeit",
    Monday: "Montag",
    Tuesday: "Dienstag",
    Wednesday: "Mittwoch",
    Thursday: "Donnerstag",
    Friday: "Freitag",
    Saturday: "Samstag",
    Sunday: "Sonntag",
    "Total Production Time and Total Idle Time for the Week":
      "Gesamtproduktionszeit und Gesamtleerlaufzeit für die Woche",
    "Average OEE and Performance for the Week": "Durchschnittlicher OEE und Leistung für die Woche",
    "username / email": "Benutzername oder E-Mail",
    "Top OEE Machines": "Top-OEE-Maschinen",
    "Top Performance Machines": "Top-Leistungsmaschinen",
    "Top Energy Consumption": "Top-Energieverbrauch",
    "Top Energy Wastage": "Top-Energieverschwendung",
    "Top OEE Operators": "Top-OEE-Operatoren",
    "Top Performance Operators": "Top-Leistungsoperatoren",
    "OEE Rank": "OEE-Rang",
    "Performance Rank": "Leistungsrang",
    "Load Less": "Weniger laden",
    "Compared to last month": "Im Vergleich zum letzten Monat {{dynamicValue}}",
    "All Day": "Ganzer Tag",
    Planned: "Geplant",
    "Toggle between 'All Day' for 24-hour data and 'Planned' for data during scheduled shifts":
      "Umschalten zwischen 'Ganzer Tag' für 24-Stunden-Daten und 'Geplant' für Daten während der geplanten Schichten",
    "Total energy consumption and wastage": "Gesamtenergieverbrauch und -verschwendung“",
    "Total OEE, Availability and performance Analysis":
      "Gesamt-OEE, Verfügbarkeits- und Leistungsanalyse",
    "Total production, total idle and total stopped per machine":
      "Gesamtproduktion, Gesamtleerlauf und Gesamtstopp pro Maschine",
    "Production, total idle and total stopped": "Produktion, Gesamtleerlauf und Gesamtstopp",
    "OEE, Availability and performance Analysis": "OEE, Verfügbarkeits- und Leistungsanalyse",
    "Energy Consumption and energy wastage Analysis":
      "Analyse von Energieverbrauch und Energieverschwendung",
    "OEE, Availability and performance Analysis vs days":
      "OEE, Verfügbarkeits- und Leistungsanalyse im Vergleich zu Tagen",
    "Energy consumption and energy wastage vs days":
      "Energieverbrauch und Energieverschwendung im Vergleich zu Tagen",
    Week: "Woche",
    Day: "Tag",
    "Daily OEE, Performance & Availability": "Tägliche OEE, Leistung und Verfügbarkeit",
    "Time Analysis (Prod/Idle/Stop)": "Zeitanalyse (Prod/Leerlauf/Stopp)",
    "Daily Energy Consumption & Waste": "Täglicher Energieverbrauch & -verschwendung",
    "Avg. OEE, Performance & Availability by Machine": "Durchschn. OEE & Leistung je Maschine",
    "Production Times by Machine": "Produktionszeiten je Maschine",
    "Energy Consumption & Waste by Machine": "Energieverbrauch & -verschwendung je Maschine",
    "Factory Area": "Fabrikbereich",
    "Co2 emissions": "CO2-Emissionen",
    "Co2_emissions In Tons Overtime": "CO2-Emissionen in Tonnen Überstunden",
    "Output Waste Percentage": "Output Waste Percentage",
    "Coolant Output (In Lts.)": "Kühlmittelausstoß (in Lts.)",
    "Material Efficiency": "Materialeffizienz",
    "Transport Efficiency": "Transporteffizienz",
    "Renewable Energy Percentage": "Prozentsatz erneuerbarer Energien",
    "Sustainability Overview": "Nachhaltigkeitsübersicht",
    "Energy Overview": "Energieübersicht",
    "Time Overview": "Zeitübersicht",
    "Sustainability Check": "Nachhaltigkeitscheck",
    "Total Idle time": "Gesamte Leerlaufzeit",
    "Total Production time": "Gesamte Produktionszeit",
    "Total Off time": "Gesamte Auszeit",
    "OEE, Availability, and Performance Analysis by Day/Shift": 'OEE, Verfügbarkeit und Leistungsanalyse je Tag/Schicht',
    "Total Production, Idle, and Stop Time by Day/Shift": "Gesamtproduktions-, Leerlauf- und Stillstandszeit je Tag/Schicht",
    "Energy Consumption and Waste Analysis by Day/Shift": 'Energieverbrauchs- und -verschwendungsanalyse je Tag/Schicht',
    "No Entries Found":"No Entries Found",
    "timelineRules":"Timeline-Regeln",
    "globalTimelineRules":"Globale Timeline-Regeln",
    "between":"zwischen",
    "and":"und",
    "Add new rule":"Neue Regel hinzufügen",
    "offStateTimelineRules":"Timeline-Regeln für den Off-Status",
    "idleStateTimelineRules":"Zeitleistenregeln für den Leerlaufzustand",
    "preparationStateTimelineRules":"Timeline-Regeln für den Vorbereitungsstatus",
    "Update rules":"Regeln aktualisieren",
    "Please select timeline rule.":"Bitte Regel auswählen.",
    "Start time is required":"Startzeit ist erforderlich",
    "Start time should be greater then or equal 0":"Startzeit sollte groesser oder gleich 0 sein",
    "End time is required":"Endzeit ist erforderlich",
    "End time should be greater then or equal 0":"Endzeit sollte groesser oder gleich 0 sein",
    "Start time can not overlap with other start time and end time":"Startzeit kann nicht mit anderen Startzeit und Endzeit überschneiden",
    "noDataMessage": "Keine Daten für die ausgewählte Woche gefunden. Wählen Sie eine andere Woche, um die Analysen zu betrachten.",
    "Previous Week": "Vorherige Woche",
    "Next Week": "Nächste Woche",
    cantChooseFutureWeek: "Kann keine zukünftige Woche wählen",
    cantChooseFutureDate: "Kann kein zukünftiges Datum wählen",
    greenIcon: "Wendet Regeln auf die Produktionsstatus-Timeline an.",
    yellowIcon: "Wendet Regeln auf die Leerlaufstatus-Timeline an.",
    redIcon: "Wendet Regeln auf die Ausstatus-Timeline an.",
    blueIcon: "Wendet Regeln auf die Vorbereitungsstatus-Timeline an.",
    greyIcon: "Wendet Regeln auf unbekannt Timeline an.",
    timelineRulesInfo:
      "Regelhierarchie: Zuerst werden spezifische interne Regeln für jeden Timeline-grund geprüft. Wenn diese nicht zutreffen, wird die STANDARDregel für diesen Grund verwendet. Wenn kein Standard verfügbar ist, gelten globale Regeln. Es ist nicht notwendig, Standards und globale Regeln zu setzen, es sei denn, sie sind erforderlich; stellen Sie nur die Regeln ein, die benötigt werden.",
    defaultReason: "Standardgrund",
    selectReason: "Grund wählen",
    Halls: "Hallen",
    reason: "Grund",
    fromDate: "Von-Datum",
    toDate: "Bis-Datum",
    timelineReasons: "Timeline Gründe",
    machineOperators: "Maschinenbediener",
    validTill: "Gültig bis",
    previousDay: "Vorheriger Tag",
    nextDay: "Nächster Tag",
    selectOperator: "Operator auswählen",
    machineModel: "Maschinenmodell",
    Notifications: "Benachrichtigungen",
    "All Notification": "Alle Benachrichtigungen",
    "Save Subscription": "Abonnement speichern",
    Subscribing: "Abonnieren...",
    Authenticator: "Authentifikator",
    "Please follow this guide for Two-factor authentication":
      "Bitte folgen Sie diesem Anleitung für die Zwei-Faktor-Authentifizierung",
    "Subscription List": "Abonnementliste",
    "Subscribe to all": "Alle abonnieren",
    "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character":
      "Das Passwort muss aus mindestens 8 Zeichen bestehen, darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen",
    "save user": "Benutzer speichern",
    "Edit Profile": "Profil bearbeiten",
    "Upload Logo": "Logo hochladen",
    "Delete Logo": "Logo löschen",
    "co2 emissions": "CO2-Emissionen",
    SustainabilityCheckInfo:
      "Untersuchen Sie, wie verschiedene Nachhaltigkeitsfaktoren Ihre Umweltauswirkungen beeinflussen. Jeder Faktor, von der Größe der Anlage bis zur Energiequelle, spielt eine entscheidende Rolle bei der Bestimmung Ihres Gesamtnachhaltigkeitsprofils. Geben Sie genaue Daten in den angegebenen Einheiten ein, um potenzielle Verbesserungen zu bewerten.",
    PlantAreaInfo:
      "Gibt die Gesamtfläche der Anlage an. Größere Flächen können den Energieverbrauch und die CO2-Emissionen aufgrund höherer Wartungs- und Betriebsanforderungen erhöhen. Beispiel: Eine Anlagenfläche von 5.000 Quadratmetern könnte mehr Energie für Heizung und Kühlung verbrauchen als ein kompakter Standort von 1.000 Quadratmetern.",
    WastePercentageInfo:
      "Stellt den Anteil der Rohstoffe dar, die im Vergleich zum gesamten in der Produktion verwendeten Rohstoff als Abfall enden. Ein höherer Prozentsatz zeigt eine weniger effiziente Materialnutzung an, die zur Umweltverschmutzung beiträgt. Beispiel: Wenn Sie 1000 kg Rohstoff verwenden und 100 kg zu Abfall werden, beträgt Ihr Abfallprozentsatz 10%",
    RenewablePercentageInfo:
      "Der Prozentsatz der gesamten Energie, der aus erneuerbaren Quellen stammt. Eine Erhöhung dieses Prozentsatzes kann Ihre Kohlenstoffemissionen erheblich reduzieren und die Nachhaltigkeit verbessern. Beispiel: Wenn 50% Ihrer Energie von Solarmodulen stammen, beträgt Ihre Nutzung erneuerbarer Energien 50%.",
    CoolantOutputInfo:
      "Misst die gesamte von Systemen verwendete Kühlmittelmenge. Höhere Ausgaben können auf Ineffizienzen und einen größeren ökologischen Fußabdruck hinweisen. Beispiel: Die Verwendung von 5.000 Litern Kühlmittel pro Monat deutet auf Potenzial zur Optimierung von Kühlsystemen zur Reduzierung der Umweltauswirkungen hin.",
    TransportEfficiencyInfo:
      "Misst, wie effizient Transportressourcen innerhalb des Unternehmens genutzt werden. Größere Effizienz bedeutet reduzierten Kraftstoffverbrauch und niedrigere Emissionen. Beispiel: Wenn Ihre Transportlogistik es Ihnen ermöglicht, mit demselben Kraftstoffverbrauch doppelt so viele Waren zu liefern, ist Ihre Transporteffizienz hoch.",
    MaterialEfficiencyInfo:
      "Spiegelt wider, wie effizient verschiedene Materialien in Ihren Produktionsprozessen verwendet werden, unter Berücksichtigung der Materialarten wie Baustahl, Edelstahl, Aluminium oder Kunststoff. Die Effizienz jedes Materials beeinflusst die gesamte Ressourcennutzung und Nachhaltigkeit. Beispiel: Wenn die Verwendung von Edelstahl im Vergleich zu Kunststoff zu weniger Abfall und langlebigeren Produkten führt, sind Ihre Praktiken mit Edelstahl materialeffizienter.",
    "Plant OEE": "Produktionsstätte OEE",
    "Plant energy": "Produktionsstätte Energie",
    "co2_emissions in tons": "CO2-Emissionen in tonnen",
    "Compared to machine rating": "Im Vergleich zum Maschinen-Rating",
    "Compared to machine rating value": "Im Vergleich zum Maschinen-Rating {value} kWh",
    "OEE compared to machine rating": "OEE im Vergleich zum Maschinen-Rating",
    "OEE compared to machine rating value": "OEE im Vergleich zum Maschinen-Rating {value} %",
    "Performance compared to machine rating": "Leistung im Vergleich zum Maschinen-Rating",
    "Performance compared to machine rating value":
      "Leistung im Vergleich zum Maschinen-Rating {value} %",
    "Configure Factory": "Fabrik konfigurieren",
    "Configure Shifts and Scheduling": "Schichten und Zeitplanung konfigurieren",
    "Configure Analyzers": "Analysatoren konfigurieren",
    "Configure Products & Orders": "Produkte und Aufträge konfigurieren",
    "Configure Security and API": "Sicherheit und API konfigurieren",
    "forgotPasswordPrompt": "Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.",
    "Products": "Produkte",
    "Orders": "Aufträge",
    "Product": "Produkt",
    "Order": "Auftrag",
    "addProduct": "Produkt hinzufügen",
    "addOrder": "Auftrag hinzufügen",
    "startTime": "Geplanter Start",
    "endTime": "Geplantes Ende",
    "External Product ID": "Externe Produkt-ID",
    "External Order ID": "Externe Auftrags-ID",
    cyclesPerProduct: "Zyklen pro Produkt",
    "Updating Product": "Produkt bearbeiten",
    "updateProduct": "Produkt bearbeiten",
    "Creating Product": "Produkt erstellen",
    "Create Product": "Produkt erstellen",
    "Updating Order": "Auftrag bearbeiten",
    "updateOrder": "Auftrag bearbeiten",
    "Creating Order": "Auftrag erstellen",
    "Create Order": "Auftrag erstellen",
    SelectCyclesTooltip: "Überprüfung der Zyklentrends vs. der ermittelten Zyklen",
    timeline: "Zeitleisten-Analyse",
    trend: "Trend-Analyse",
    trendAnalysisGraph: "Trend-Analyse",
    "Downtime Analysis": "Analyse der Stillstandszeiten",
    "Toggle Focus Mode": "Fokusmodus umschalten",
    criticalLevels: "Kritische Werte",
    critical: "Kritisch",
    high: "Hoch",
    medium: "Mittel",
    low: "Niedrig",
    "reduced downtime": "Reduzierte Stillstandszeiten",
    "cost saving": "Kosteneinsparung",
  }
};
