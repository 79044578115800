/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import brandWhite from "assets/images/newlogo.png";

// Authentication layout components

// Images
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import axios from "api/axios";
import bgImage from "assets/images/login_bg.webp";
import BasicLayout from "components/BasicLayout";
import translate from "i18n/translate";
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import ReactLoginMS from "react-ms-login";
import { Stack } from "@mui/system";
import Cookies from "js-cookie";
import useAuth from "hooks/useAuth";
import GitHubOAuth from "../sign-in/GitHubOAuth";

import GoogleIcon from "@mui/icons-material/Google";
import GitHubIcon from "@mui/icons-material/GitHub";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import MicrosoftOAuth from "./../sign-in/MicrosoftOAuth";
import MDSnackbar from "components/MDSnackbar";

function Cover() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [license, setLicense] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMsg("");
  };

  const [loginType, setLoginType] = useState();
  const { setAuth } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (terms) {
      setLoading(true);

      if (name === "" || email === "" || password === "" || license === "") {
        setErrMsg(translate("Fields cannot be empty"));
        setLoading(false);
      } else {
        try {
          const userData = {
            name,
            password,
            license,
            email
          };
          const response = await axios.post("v2/user/", userData);
          setName("");
          setEmail("");
          setLicense("");
          setPassword("");
          setLoading(false);
          setSuccessMsg(response?.data?.message || "");
        } catch (err) {
          if (!err?.response) {
            setErrMsg(translate("No response from server"));
            setLoading(false);
          } else if (err?.response?.status === 400) {
            setErrMsg("Please enter username and password");
            setLoading(false);
          } else if (err?.response?.status === 401) {
            setErrMsg("Unauthorized");
            setLoading(false);
          } else {
            setErrMsg("Unable to create a User. Please try again in sometime");
            setLoading(false);
          }
        }
      }
    } else {
      setErrMsg(translate("acceptTermsConditions"));
    }
  };

  const socialMediaLogin = async (authUrl, codeResponse) => {
    const data = await axios.post(authUrl, { code: codeResponse.code, license });
    if (data?.redirect === "2fa") {
      setAuth({ user: data?.user?.email, password });
      Cookies.set("username", data?.user?.email);
      navigate(`/verifyOTP`, { replace: true });
      return;
    }
    const refreshToken = data?.refresh_token;
    const Token = data?.token;
    const roles = data?.user?.role;
    const UserCompanyId = data?.user?.company?.id;
    const Id = data?.user?.id;
    const role = data?.user?.role?.name;
    const setting = data?.user?.setting;
    const name = data?.user?.name;
    setAuth({ user: data?.user?.email, password, refreshToken, Token, roles });
    Cookies.set("tok", refreshToken, { expires: 15 });
    Cookies.set("id", Id);
    Cookies.set("companyid", UserCompanyId);
    Cookies.set("role", role);
    Cookies.set("setting", setting);
    Cookies.set("name", name);
    navigate(`dashboard/machines`, { replace: true });
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      if (codeResponse?.code) {
        // setLoginType("google");
        socialMediaLogin("v2/oauth/google_register", codeResponse);
      }
    }
  });
  const handleMsLogin = async (codeResponse) => {
    if (codeResponse?.code) {
      // setLoginType("microsoft");
      socialMediaLogin("v2/oauth/microsoft/register", codeResponse);
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <MDBox display="flex" alignItems="center" justifyContent="center">
              {brandWhite && (
                <MDBox
                  component="img"
                  src={brandWhite}
                  alt="Brand"
                  margin="auto"
                  width={"50%"}
                  height={"50%"}
                />
              )}
            </MDBox>
            {translate("sign up")}
          </MDTypography>
          {/* <MDTypography display="block" variant="button" color="white" my={1}>
            {translate("Enter your email and password to register")}
          </MDTypography> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={2}>
            <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
              {errMsg}
            </MDTypography>
          </MDBox>
          <MDBox component="form" role="form">
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label={translate("username")}
                  variant="outlined"
                  value={name}
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label={translate("email")}
                  variant="outlined"
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showPassword ? "text" : "password"}
                  label={translate("Password")}
                  variant="outlined"
                  value={password}
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          onMouseUp={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {!showPassword ? (
                            <VisibilityOff sx={{ size: "small", fill: "white" }} />
                          ) : (
                            <Visibility sx={{ size: "small", fill: "white" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label={
                    <span>
                      {translate("License")} <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  variant="outlined"
                  value={license}
                  fullWidth
                  onChange={(e) => {
                    setLicense(e.target.value);
                    localStorage.setItem("license", e.target.value);
                  }}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox onChange={(e) => setTerms(e.target.checked)} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;{translate("I agree the")}&nbsp;
                </MDTypography>
                <MDTypography
                  component="a"
                  href="https://novoai.de/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  // textGradient
                >
                  {translate("Terms and Conditions")}
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={loading}
                  fullWidth
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? translate("signing up") : translate("sign up")}
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox textAlign="center" mb={1}>
              <MDTypography variant="button" color="text">
                - {translate("or")} -
              </MDTypography>
            </MDBox>
            <Stack>
              <Stack>
                <MDBox mt={1} mb={1}>
                  <MDButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (license === "" || !terms) {
                        if (license === "") {
                          setErrMsg(translate("enterLicenseKeyError"));
                        } else {
                          setErrMsg(translate("acceptTermsConditions"));
                        }
                      } else {
                        googleLogin();
                      }
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                    fullWidth
                    startIcon={<GoogleIcon />}
                  >
                    {translate("signUpWith")} GOOGLE
                  </MDButton>
                </MDBox>
                <MDBox mt={1} mb={1}>
                  {license === "" || !terms ? (
                    <MDButton
                      onClick={(e) => {
                        e.preventDefault();
                        if (license === "" || !terms) {
                          if (license === "") {
                            setErrMsg("Please enter license key");
                          } else {
                            setErrMsg("Please check term and conditions.");
                          }
                        }
                      }}
                      variant="gradient"
                      color="info"
                      type="submit"
                      fullWidth
                      startIcon={<GitHubIcon />}
                    >
                      {translate("signUpWith")} GITHUB
                    </MDButton>
                  ) : (
                    <GitHubOAuth startIcon={<GitHubIcon />} />
                  )}
                </MDBox>
                <MDBox mt={1} mb={1}>
                  {license !== "" && terms ? (
                    <MicrosoftOAuth />
                  ) : (
                    <MDButton
                      onClick={(e) => {
                        e.preventDefault();
                        if (license === "" || !terms) {
                          if (license === "") {
                            setErrMsg(translate("enterLicenseKeyError"));
                          } else {
                            setErrMsg(translate("acceptTermsConditions"));
                          }
                        } else {
                          // Clear any previous errors
                          setErrMsg("");
                        }
                      }}
                      variant="gradient"
                      color="info"
                      type="submit"
                      fullWidth
                      startIcon={<MicrosoftIcon />}
                    >
                      {translate("signUpWith")} MICROSOFT
                    </MDButton>
                  )}
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    {translate("Already have an account?")}{" "}
                    <MDTypography
                      component={Link}
                      to="/signin"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      // textGradient
                    >
                      {translate("sign in")}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </Stack>
            </Stack>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
