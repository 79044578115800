/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import Co2Icon from "@mui/icons-material/Co2";
import { Grid, Skeleton } from "@mui/material";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import translate from "i18n/translate";
import { convertHMS } from "utils/constants";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

function SustainabilityOverview({
  filter,
  consumptionTotal,
  companyDetails,
  isLoading,
  tabShiftValue
}) {
  return (
    <MDBox mt={3}>
      <MDTypography variant="h5" fontWeight="medium">
        {translate("Sustainability Overview")}
      </MDTypography>
      {!isLoading ? (
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ComplexStatisticsCard
              title="Co2 emissions"
              count={`${consumptionTotal?.[filter]?.co2_emissions_tons?.toFixed(2)}`}
              iconStyle={{ width: "35px", height: "35px" }}
              unit="Tons"
              icon={<Co2Icon style={{ width: "35px", height: "35px" }} />}
              color="#748EDD"
              tooltip="Co2 emissions"
              // overallRating={
              //   filter === "today"
              //     ? dayCummulative?.factory_ratings?.energy_consumption
              //     : filter === "week"
              //     ? weekCummulative?.factory_ratings?.energy_consumption
              //     : monthCummulative?.factory_ratings?.energy_consumption
              // }
              // tooltipTitle={`Compared to machine rating ${consumptionTotal?.[
              //   filter
              // ]?.rating_energy_consumption?.toFixed(2)} kWh`}
              // currentRating={consumptionTotal?.[filter]?.energy_consumption}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ComplexStatisticsCard
              title="Reduced Downtime"
              count={`${convertHMS(
                new Date(
                  Math.floor(consumptionTotal?.[filter]?.idle_reduce?.toFixed(2))
                ).getTime() || "00:00:00"
              )}`}
              iconStyle={{ width: "35px", height: "35px" }}
              // unit="Tons"
              icon={<TrendingUpIcon style={{ width: "35px", height: "35px" }} />}
              color="#459548"
              tooltip="Reduced Downtime"
              // overallRating={
              //   filter === "today"
              //     ? dayCummulative?.factory_ratings?.energy_consumption
              //     : filter === "week"
              //     ? weekCummulative?.factory_ratings?.energy_consumption
              //     : monthCummulative?.factory_ratings?.energy_consumption
              // }
              // tooltipTitle={`Compared to machine rating ${consumptionTotal?.[
              //   filter
              // ]?.rating_energy_consumption?.toFixed(2)} kWh`}
              // currentRating={consumptionTotal?.[filter]?.energy_consumption}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ComplexStatisticsCard
              title="Cost Saving"
              count={`${consumptionTotal?.[filter]?.cost_saving?.toFixed(2)}`}
              iconStyle={{ width: "35px", height: "35px" }}
              unit={companyDetails?.currency?.symbol || "$"}
              icon={<MonetizationOnIcon style={{ width: "35px", height: "35px" }} />}
              color="#459548"
              tooltip="Cost Saving"
              // overallRating={
              //   filter === "today"
              //     ? dayCummulative?.factory_ratings?.energy_consumption
              //     : filter === "week"
              //     ? weekCummulative?.factory_ratings?.energy_consumption
              //     : monthCummulative?.factory_ratings?.energy_consumption
              // }
              // tooltipTitle={`Compared to machine rating ${consumptionTotal?.[
              //   filter
              // ]?.rating_energy_consumption?.toFixed(2)} kWh`}
              // currentRating={consumptionTotal?.[filter]?.energy_consumption}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} height={200}>
            <Skeleton height={200} />
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
}

export default SustainabilityOverview;
