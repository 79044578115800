export const ADD_SEARCH = "ADD_SEARCH";
export const GET_SEARCH_MACHINE = "GET_SEARCH_MACHINE";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const ADD_SEARCH_MACHINE = "ADD_SEARCH_MACHINE";
export const DELETE_SEARCH_MACHINE = "DELETE_SEARCH_MACHINE";
export const ADD_SEARCH_VERSUS = "ADD_SEARCH_VERSUS";
export const DELETE_SEARCH_VERSUS = "DELETE_SEARCH_VERSUS";
export const GET_SEARCH_VERSUS = "GET_SEARCH_VERSUS";
export const GET_SEARCH_REPORTS = "GET_SEARCH_REPORTS";
export const ADD_SEARCH_REPORTS = "ADD_SEARCH_REPORTS";
export const DELETE_SEARCH_REPORTS = "DELETE_SEARCH_REPORTS";
